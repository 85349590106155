/* eslint-disable import/no-cycle */
import React, { createContext, useEffect, useState } from 'react';
import BuildingAttributes from '../containers/BuildingAttributes/BuildingAttributes';
import ApiClient from '../helpers/ApiClient';
import apiEndPoints from '../helpers/apiEndPoints';

export const BuildingAttributesContext = createContext({});

export const BuildingAttributesContextProvider = () => {
  const [buildingAttributesList, setBuildingAttributesList] = useState([]);
  const [totalBACount, setTotalBACount] = useState(0);
  const [buildingSearch, setBuildingSearch] = useState('');
  const [buildings, setBuildings] = useState([]);
  const [villages, setVillages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [offset, setOffset] = useState(0);
  const [sortOrder, setSortOrder] = useState('asc');
  const [sortBy, setSortBy] = useState('name');
  const [statuses, setStatuses] = useState([
    { label: 'Yes', key: true, selected: false },
    { label: 'No', key: false, selected: false },
  ]);
  const [selectedStatus, setSelectedStatus] = useState([]);

  const buildingSearchResult = buildingSearch.length > 0 ? buildingSearch : '';

  const getBuildingAttributes = payload => {
    ApiClient.apiPost(apiEndPoints.getBuildingAttributesList, payload)
      .then(data => {
        setBuildingAttributesList(data && data.data && data.data.data);
        setTotalBACount(data && data.data.count);
        setLoading(false);
      })
      .catch(err => {
        alert(
          err && err.response && err.response.data && err.response.data.message,
        );
      });
  };

  useEffect(() => {
    const updatedStatus = statuses
      .filter(status => status.selected)
      .map(status => status.key);
    setSelectedStatus(updatedStatus);
    const queryParams = {
      searchText: buildingSearchResult ? buildingSearch : '',
      tenantId: undefined,
      buildings: buildings && buildings.length > 0 ? buildings : undefined,
      sortOrder,
      sortBy,
      status: undefined,
      role: undefined,
      payToAccount: undefined,
      offset,
      limit: 40,
    };
    if (updatedStatus.length > 0) {
      queryParams.buildingMobileAccess = updatedStatus;
    }

    getBuildingAttributes(queryParams);
  }, [buildingSearchResult, offset, sortOrder, sortBy, buildings, statuses]);

  return (
    <BuildingAttributesContext.Provider
      value={{
        loading,
        buildingAttributesList,
        buildingSearch,
        setBuildingSearch,
        totalBACount,
        setSortOrder,
        sortOrder,
        setSortBy,
        sortBy,
        page,
        setPage,
        setOffset,
        buildings,
        setBuildings,
        villages,
        setVillages,
        statuses,
        setStatuses,
        selectedStatus,
        setSelectedStatus,
      }}
    >
      <BuildingAttributes />
    </BuildingAttributesContext.Provider>
  );
};
