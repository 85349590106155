/* eslint-disable import/no-cycle */
import React, { useContext, useState } from 'react';
import {
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  List,
  ListItem,
  Popover,
} from '@material-ui/core';
import plus from '../../images/plus.svg';
import close from '../../images/close.svg';
import minus from '../../images/minus.svg';
import { ManageTenentContext } from '../../context/ManageTenentUsersContext';
import SearchFilter from '../../components/SearchFilter/SearchFilter';
import Pagination from '../../components/Pagination/Pagination';
import useFilterStyles from '../../components/Styles/useFilterStyles';
import { GlobalContext } from '../../context/GlobalContext';
import LocationFilter from '../../components/Filters/LocationFilter';

const ManageTenentFilter = React.forwardRef((props, ref) => {
  const {
    page,
    setPage,
    setOffset,
    payAccountOptions,
    totalManageTenantsCount,
    setPayAccountOptions,
    customerSearch,
    setCustomerSearch,
    selectedStatus,
    buildings,
    setBuildings,
    selectedBuildingId,
    setSelectedBuildingId,
    villages,
    setVillages,
  } = useContext(ManageTenentContext);
  const { buildingGroupsList } = useContext(GlobalContext);
  const classes = useFilterStyles();
  const [moreSearch, setMoreSearch] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [onCheckMoreButton, setOnCheckMoreButton] = useState('');
  const placeholder = 'Type a tenant name';
  const [locationSearch, setLocationSearch] = useState(false);
  const [OnCheckedLocationButton, setOnCheckedLocationButton] = useState('');

  const handleClose = () => {
    setAnchorEl(null);
    setMoreSearch(null);
    setOnCheckMoreButton(false);
    setLocationSearch(false);
    setOnCheckedLocationButton(false);
  };

  const statusOptions = (selected, key) => {
    const statusnew = payAccountOptions.map(status => {
      if (status && status.key === key) {
        status.selected = selected;
      }
      return status;
    });
    setPayAccountOptions(statusnew);
  };

  const ClearFilter = () => {
    setPayAccountOptions([
      { label: 'Yes', key: true, selected: false },
      { label: 'No', key: false, selected: false },
    ]);
    setAnchorEl('');
    setBuildings([]);
    setSelectedBuildingId([]);
    setVillages([]);
  };

  const clearMoreIcon = () => {
    setPayAccountOptions([
      { label: 'Yes', key: true, selected: false },
      { label: 'No', key: false, selected: false },
    ]);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  const doThis = () => {};

  return (
    <div
      className={classes.filerSection}
      onClick={ref.current.focusHandler}
      onKeyDown={doThis}
      role="button"
      tabIndex={0}
    >
      <div className="pageContainer filterContainer">
        <div className={classes.filterMain}>
          <div className={classes.leftFilter}>
            <ul className={`${classes.leftFilterIn} leftFilterIn`}>
              <li
                className={
                  onCheckMoreButton === true ||
                  (selectedStatus && selectedStatus.length > 0)
                    ? `${classes.active} ${
                        selectedStatus && selectedStatus.length > 0
                          ? classes.valueSelected
                          : ''
                      }`
                    : ''
                }
              >
                <Button
                  aria-describedby={id}
                  variant="contained"
                  onClick={event => {
                    setMoreSearch(true);
                    setAnchorEl(event.currentTarget);
                    setOnCheckMoreButton(true);
                  }}
                >
                  Pay To Account
                  <small>
                    <img src={plus} alt="plus" />
                    <img src={minus} alt="minus" />
                  </small>
                </Button>
                <i
                  className="closeIconBtn"
                  role="presentation"
                  onClick={clearMoreIcon}
                >
                  <img src={close} alt="close" />
                </i>
                <Popover
                  id="pay-to-account"
                  open={moreSearch}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                >
                  <div className="filterDropdown">
                    <div className="filterDropdownin">
                      <List className={classes.moreList}>
                        <h4>Select A Pay To Account Type(s)</h4>
                        {payAccountOptions &&
                          payAccountOptions.map((status, index) => (
                            <>
                              <ListItem key={status.key}>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      name={status.label}
                                      checked={status.selected}
                                      onChange={e => {
                                        statusOptions(
                                          e.target.checked,
                                          status.key,
                                        );
                                      }}
                                    />
                                  }
                                  label={status.label}
                                  value={status.label}
                                />
                              </ListItem>
                              {payAccountOptions &&
                                payAccountOptions.length - 1 > index && (
                                  <Divider light />
                                )}
                            </>
                          ))}
                      </List>
                    </div>
                  </div>
                </Popover>
              </li>
              <LocationFilter
                locationSearch={locationSearch}
                anchorEl={anchorEl}
                setAnchorEl={setAnchorEl}
                setLocationSearch={setLocationSearch}
                handleClose={handleClose}
                setOnCheckedLocationButton={setOnCheckedLocationButton}
                OnCheckedLocationButton={OnCheckedLocationButton}
                id={id}
                buildingGroupsList={buildingGroupsList}
                buildings={buildings}
                villages={villages}
                setVillages={setVillages}
                setBuildings={setBuildings}
                selectedBuildingId={selectedBuildingId}
                setSelectedBuildingId={setSelectedBuildingId}
              />
              <li role="presentation" onClick={ClearFilter}>
                Clear Filter
              </li>
            </ul>
          </div>
          <div className={`${classes.rightFilter} rightFilterPagination`}>
            <Pagination
              page={page}
              setPage={setPage}
              setOffset={setOffset}
              totalTenants={totalManageTenantsCount}
            />
            <div className={`${classes.exportSerch} exportSerch`}>
              <ul>
                <SearchFilter
                  customerSearch={customerSearch}
                  setCustomerSearch={setCustomerSearch}
                  placeholder={placeholder}
                />
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default ManageTenentFilter;
