/* eslint-disable array-callback-return */
import React, { useContext, useEffect, useState } from 'react';
import {
  Divider,
  FormControl,
  makeStyles,
  FormLabel,
  Input,
  InputLabel,
  MenuItem,
  Select,
  CircularProgress,
  FormHelperText,
  Switch,
  Grid,
  Checkbox,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Table,
  Button,
  DialogActions,
  Dialog,
} from '@material-ui/core';
import { useHistory, useParams } from 'react-router-dom';
import moment from 'moment';
import dowmArw from '../../images/down-arw.svg';
import './BuildingAttributes.css';
import { GlobalContext } from '../../context/GlobalContext';
import SpaceFiles from '../../components/ImageUpload/SpaceFiles';
import apiEndPoints from '../../helpers/apiEndPoints';
import ApiClient from '../../helpers/ApiClient';
import WarningPopup from '../../components/WarningPopup/WarningPopup';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import { buildingAttributesSchema } from '../../utils/formValidate';
import { changeToBstHourOnlyFormat } from '../../utils/dateUtils';
import SaveAndCancelButtons from '../../components/Buttons/SaveAndCancelButtons';
import { timeZone, warningMsg } from '../../utils/constants';

const columns = [
  {
    id: 'tenantName',
    SortOrder: 'desc',
    SortBy: 'tenant',
    label: 'TENANT',
  },
  {
    id: 'mobileAccess',
    SortOrder: 'desc',
    SortBy: 'meetingRoom',
    label: 'MOBILE ACCESS',
  },
  {
    id: 'clearanceGroup',
    SortOrder: 'desc',
    SortBy: '',
    label: 'CLEARANCE CONTROL GROUP(S)*',
  },
];

const useStyles = makeStyles(() => ({
  pageContainer: {
    height: '100%',
    width: '100%',
    marginTop: '88px',
    padding: '0 30px',
    boxSizing: 'border-box',
  },
  addUserForm: {
    width: '70%',
    margin: '0 auto',
    padding: '40px 0',
    '& ul': {
      display: 'flex',
      justifyContent: 'space-between',
      flexWrap: 'wrap',
      alignItems: 'flex-start',
      '& li': {
        listStyle: 'none',
        width: '32%',
        marginBottom: '22px',
        position: 'relative',
      },
    },
  },
  addUserFormInp: {
    width: '80%',
    margin: '0 auto',
    padding: '0 0',
    marginLeft: '0px',
  },
  InputSectionInp: {
    '& li': {
      '& span': {
        display: 'block',
        fontSize: '16px',
        fontWeight: 'normal',
        color: '#838384',
        fontFamily: ['Varta', 'sans-serif'].join(','),
      },
      '& input': {
        height: '45px',
        lineHeight: '45px',
        padding: '0 15px !important',
        fontSize: '16px',
        color: '#444444',
        borderRadius: '3px',
        border: 'solid 0.5px #979797',
        marginTop: '10px',
        width: '100%',
        background: 'transparent',
        fontFamily: ['Varta', 'sans-serif'].join(','),
      },
      '& textarea': {
        height: 'auto',
        minHeight: '92px',
        lineHeight: '22px',
        padding: '10px 15px !important',
        fontSize: '16px',
        color: '#444444',
        borderRadius: '3px',
        border: 'solid 0.5px #979797',
        marginTop: '10px',
        width: '100%',
        background: 'transparent',
        resize: 'none',
        fontFamily: ['Varta', 'sans-serif'].join(','),
        outline: 'none',
      },
      '& > div': {
        '& > div': {
          '&:before': {
            display: 'none',
          },
          '&:after': {
            display: 'none',
          },
        },
        width: '100%',
        '&:before': {
          display: 'none',
        },
        '&:after': {
          display: 'none',
        },
      },
    },
  },
  formControl: {
    width: '100%',
    '& InputLabel': {
      position: 'relative',
    },
  },
  formControlTenant: {
    width: '70%',
    '& InputLabel': {
      position: 'relative',
    },
  },
  formLabel: {
    fontSize: '16px',
    fontWeight: 'normal',
    color: '#838384',
    transform: 'none',
    fontFamily: ['Varta', 'sans-serif'].join(','),
  },
  formSelectBox: {
    '& svg': {
      display: 'none',
    },
    '&:before': {
      display: 'none',
    },
    '&:after': {
      display: 'none',
    },
    '& > div': {
      height: '45px',
      lineHeight: '45px',
      padding: '0 30px 0 15px !important',
      fontSize: '16px',
      color: '#444444',
      borderRadius: '3px !important',
      border: 'solid 0.5px #979797',

      background: `transparent url(${dowmArw}) no-repeat 95% center !important`,
      backgroundSize: '10px',
      appearance: 'none',
      fontFamily: ['Varta', 'sans-serif'].join(','),
    },
  },
  formSelectBoxNodown: {
    '& > div': {
      background: ' transparent !important',
    },
  },
  InputSection: {
    '& li': {
      '& span': {
        display: 'block',
        fontSize: '16px',
        fontWeight: 'normal',
        color: '#838384',
        fontFamily: ['Varta', 'sans-serif'].join(','),
      },
      '& input': {
        height: '45px',
        lineHeight: '45px',
        padding: '0 15px !important',
        fontSize: '16px',
        color: '#444444',
        borderRadius: '3px',
        border: 'solid 0.5px #979797',
        marginTop: '10px',
        width: '100%',
        background: 'transparent',
        fontFamily: ['Varta', 'sans-serif'].join(','),
      },
      '& textarea': {
        height: 'auto',
        minHeight: '92px',
        lineHeight: '22px',
        padding: '10px 15px !important',
        fontSize: '16px',
        color: '#444444',
        borderRadius: '3px',
        border: 'solid 0.5px #979797',
        marginTop: '10px',
        width: '100%',
        background: 'transparent',
        resize: 'none',
        fontFamily: ['Varta', 'sans-serif'].join(','),
        outline: 'none',
      },
      '& > div': {
        '& > div': {
          '&:before': {
            display: 'none',
          },
          '&:after': {
            display: 'none',
          },
        },
        width: '100%',
        '&:before': {
          display: 'none',
        },
        '&:after': {
          display: 'none',
        },
      },
    },
  },
  phoneSelectNo: {
    '& > div': {
      '&:before': {
        display: 'none',
      },
      '&:after': {
        display: 'none',
      },
    },
  },
  InputSectionReadonly: {
    '& > div': {
      '& > div': {
        '&:before': {
          display: 'none',
        },
        '&:after': {
          display: 'none',
        },
        '& input': {
          height: '45px',
          lineHeight: '45px',
          padding: '0 15px !important',
          fontSize: '16px',
          color: '#444444',
          borderRadius: '3px',
          border: 'solid 0.5px #979797',
          marginTop: '10px',
          appearance: 'none',
          fontFamily: ['Varta', 'sans-serif'].join(','),
        },
      },
    },
  },
  radioSection: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '0',
    '& span': {
      fontFamily: ['Varta', 'sans-serif'].join(','),
      fontSize: '16px',
      lineHeight: '20px',
      color: '#838384',
      '&:hover': {
        backgroundColor: 'transparent !important',
      },
      '& svg': {
        fontSize: '16px',
        fill: '#979797',
        '&:last-child': {
          fill: '#4e4e4e',
        },
      },
    },
  },
  errorMsg: {
    fontSize: '13px',
    color: '#f90000 !important',
    marginTop: 4,
    fontFamily: ['Varta', 'sans-serif'].join(','),
  },
  visitorTable: {
    fontFamily: ['Varta', 'sans-serif'].join(','),
    fontSize: '16px',
    fontWeight: 'bold',
    color: ' #838384',
  },
  pb_20: {
    paddingBottom: '20px',
  },
  pb_10: {
    paddingBottom: '10px',
  },
  pt_10: {
    paddingTop: '10px',
  },
  pt_20: {
    paddingTop: '20px',
  },
  pt_50: {
    paddingTop: '50px',
  },
  wd_150: {
    width: '150px',
  },
  mt_20: {
    marginTop: '20px',
  },
  md_12: {
    padding: '12px',
  },
  flex: {
    display: 'flex',
  },
  maxGuest: {
    padding: '15px',
    height: '45px',
    border: 'solid 0.5px #979797',
    borderRadius: '3px',
    outline: 0,
    marginTop: '10px',
  },
  advisoryNote: {
    padding: '15px',
    marginTop: '10px',
    height: '100px',
    width: '100%',
    border: 'solid 0.5px #979797',
    fontFamily: 'Varta,sans-serif',
    fontSize: '16px',
    borderRadius: '3px',
    outline: 0,
  },
  openHoursBG: {
    padding: '20px',
    border: 'solid 0.5px #979797',
    color: '#838384',
  },
  wd_120: {
    width: '110px',
  },
  Clearance: {
    width: '100% !important',
    marginBottom: '0px !important',
  },
  clearanceInpBody: {
    paddingBottom: '10px',
  },
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 'calc(100vh - 240px)',
  },
  tableHeaderCell: {
    background: '#f8f8f8',
    border: 'none',
    padding: '10px 15px',
    color: '#444444',
    fontSize: '13px',
    fontWeight: 700,
    fontFamily: ['Varta', 'sans-serif'].join(','),
    cursor: 'pointer',
    textTransform: 'uppercase',
    '&:before': {
      position: 'absolute',
      content: '""',
      background: '#dfdfdf',
      right: '0',
      top: '50%',
      transform: 'translateY(-50%)',
      width: '1px',
      height: '40%',
    },
    '&:last-child': {
      '&:before': {
        display: 'none',
      },
      '&:after': {
        display: 'none',
      },
    },
  },
  tableBody: {
    verticalAlign: 'middle',
    '& th': {
      fontSize: '14px',
      lineHeight: '23px',
      color: '#838384',
      fontWeight: 'bold',
      fontFamily: ['Varta', 'sans-serif'].join(','),
      textTransform: 'capitalize',
      padding: '0px 12px',
    },
    '& td': {
      fontSize: '14px',
      lineHeight: '23px',
      color: '#838384',
      fontWeight: 'bold',
      fontFamily: ['Varta', 'sans-serif'].join(','),
      textTransform: 'capitalize',
      padding: '0px 12px',
    },
  },

  cancelDialog: {
    textAlign: 'center',
    '& h2': {
      fontSize: '19px',
      fontWeight: 'bold',
      color: '#444444',
      marginBottom: '15px',
      fontFamily: ['Lato', 'sans-serif'].join(','),
    },
    '& p': {
      fontSize: '16px',
      fontWeight: 'normal',
      color: '#444444',
      marginBottom: '15px',
      fontFamily: ['Lato', 'sans-serif'].join(','),
    },
  },
  formButton: {
    marginTop: '0',
    width: '100% !important',
    display: 'flex',
    justifyContent: 'flex-end',
    '& button': {
      height: '47px',
      lineHeight: '47px',
      border: '1px solid #000',
      outline: 'none',
      background: '#000000',
      fontSize: '16px',
      fontWeight: 'bold',
      width: 'auto',
      padding: '0 45px',
      color: '#fff',
      marginLeft: '30px',
      cursor: 'pointer',
      textTransform: 'capitalize',
      fontFamily: ['Varta', 'sans-serif'].join(','),
      '&:hover': {
        background: '#000000',
      },
      '&:first-child': {
        background: 'transparent',
        color: '#444444',
        border: 'none',
      },
    },
  },
}));

const EditBuildingAttributes = () => {
  const classes = useStyles();
  const history = useHistory();
  const { id } = useParams();
  const { setSuccessMessage } = useContext(GlobalContext);
  const [buildingAttributes, setBuildingAttributes] = useState([]);
  const [buildingFeatures, setBuildingFeatures] = useState([]);
  const [description, setDescription] = useState('');
  const [postCodeAlias, setPostCodeAlias] = useState('');
  const [selectedFeatures, setSelectedFeatures] = useState([]);
  const [phoneNumberValidation, setPhoneNumberValidation] = useState('');
  const [spaceFile, setSpaceFile] = useState([]);
  const [createSpace, setCreateSpace] = useState(false);
  const [previewImage, setPreviewImage] = useState([]);
  const [spaceVideo, setSpaceVideo] = useState('');
  const [floorPlan, setFloorPlan] = useState('');
  const [attributesToggle, setAttributesToggle] = useState(false);
  const [errorMsgs, setErrorMsgs] = useState('');
  const [loading, setLoading] = useState(true);
  const [buildingLogo, setBuildingLogo] = useState([]);
  const [thumnail, setThumnail] = useState([]);
  const [visitor, setVisitor] = useState(false);
  const [days, setDays] = useState([]);
  const [maxGuest] = useState(1);
  const [advisoryNote, setAdvisoryNote] = useState('');
  const [mobileAccess, setMobileAccess] = useState(false);
  const [mobileWarningPopup, setWarningPopUpOpen] = useState(false);
  const [oldBuildingTenantsDetail, setOldBuildingTenantsDetail] = useState([]);
  const [buildingTenantsDetail, setBuildingTenantsDetail] = useState([
    {
      tenantId: '',
      tenantName: '',
      tenantBuildingMobileAccess: false,
      tenantBuildingClearanceControl: '',
    },
  ]);

  const initialStartTime = moment()
    .tz(timeZone.london)
    .set({ hour: 8, minute: 0, second: 0, millisecond: 0 });
  const initialEndTime = moment()
    .tz(timeZone.london)
    .set({ hour: 18, minute: 0, second: 0, millisecond: 0 });
  const allDays = [
    {
      day: 'Monday',
      start: initialStartTime,
      end: initialEndTime,
      closed: false,
    },
    {
      day: 'Tuesday',
      start: initialStartTime,
      end: initialEndTime,
      closed: false,
    },
    {
      day: 'Wednesday',
      start: initialStartTime,
      end: initialEndTime,
      closed: false,
    },
    {
      day: 'Thursday',
      start: initialStartTime,
      end: initialEndTime,
      closed: false,
    },
    {
      day: 'Friday',
      start: initialStartTime,
      end: initialEndTime,
      closed: false,
    },
    {
      day: 'Saturday',
      start: initialStartTime,
      end: initialEndTime,
      closed: false,
    },
    {
      day: 'Sunday',
      start: initialStartTime,
      end: initialEndTime,
      closed: false,
    },
  ];

  const manageMobileAccess = () => {
    if (mobileAccess) {
      setWarningPopUpOpen(true);
    } else setMobileAccess(!mobileAccess);
  };
  const handleCloseMobileAccessWarning = () => {
    setWarningPopUpOpen(false);
  };
  const handleYesMobileAccessWarning = () => {
    setWarningPopUpOpen(false);
    setBuildingTenantsDetail(oldBuildingTenantsDetail);
    setMobileAccess(!mobileAccess);
  };

  const tenantDetailSetter = (e, tenantId) => {
    setBuildingTenantsDetail(prevState => {
      return prevState.map(tenant => {
        if (tenant.tenantId === tenantId && e.target.name === 'mobAccess') {
          return {
            ...tenant,
            tenantBuildingMobileAccess: e.target.value,
            tenantBuildingClearanceControl: '',
          };
        }
        if (
          tenant.tenantId === tenantId &&
          e.target.name === 'clearanceInput'
        ) {
          const re = /^[A-Za-z0-9 ]+$/;
          if (e.target.value === '' || re.test(e.target.value)) {
            return {
              ...tenant,
              tenantBuildingClearanceControl: e.target.value,
            };
          }
        }
        return tenant;
      });
    });
  };
  useEffect(() => {
    if (id) {
      ApiClient.apiGet(`${apiEndPoints.getBuildingAttributes}/${id}`)
        .then(data => {
          setLoading(false);
          const buildingDetails = data && data.data && data.data.data;
          setBuildingAttributes(buildingDetails);
          setDescription(buildingDetails.description);
          setPostCodeAlias(buildingDetails.postCodeAlias || '');
          setSelectedFeatures(
            (buildingDetails &&
              buildingDetails.features &&
              buildingDetails.features.map(item => {
                return item.id;
              })) ||
              [],
          );
          setPhoneNumberValidation(buildingDetails.agentPhone || '');
          setSpaceFile((buildingDetails && buildingDetails.images) || []);
          setPreviewImage(buildingDetails.previewImage || []);
          setSpaceVideo(buildingDetails.video || '');
          setFloorPlan(buildingDetails.buildingBrochure || '');
          setBuildingLogo(
            (buildingDetails && buildingDetails.buildingLogo) || [],
          );
          setAdvisoryNote(buildingDetails.advisoryNotes || '');
          setDays(buildingDetails.OpeningClosingHours || allDays);
          setThumnail(buildingDetails.visitorBookingBuildingThumbnail || []);
          setVisitor(buildingDetails.enableVisitorBooking || false);
          setBuildingTenantsDetail(buildingDetails.tenants);
          setOldBuildingTenantsDetail(buildingDetails.tenants);
          setMobileAccess(buildingDetails.buildingMobileAccess);
        })
        .catch(error => {
          console.log(error, 'error');
        });
      ApiClient.apiGet(apiEndPoints.getBuildingFeatures)
        .then(data => {
          setBuildingFeatures(data && data.data && data.data.data);
        })
        .catch(err => {
          setLoading(false);
          alert(
            err &&
              err.response &&
              err.response.data &&
              err.response.data.message,
          );
        });
    }
  }, [id]);

  const filterdFeature = [];
  const featureName = [];
  selectedFeatures.map(selectedFeature => {
    buildingFeatures.map(featureId => {
      if (selectedFeature === featureId.id) {
        const existsFeature = featureName.find(
          feature => feature.id === selectedFeature,
        );
        if (existsFeature) {
          filterdFeature.push(
            featureName.filter(
              object => selectedFeatures.indexOf(object.id) > -1,
            ),
          );
        } else {
          featureName.push({
            id: featureId.id,
            name: featureId.name,
            imageIcon: featureId.imageIcon,
          });
        }
      }
    });
  });

  const selectionRenderer = values => {
    // change the default comma separated rendering
    return <span style={{ color: '#ff4081' }}>{values.join('; ')}</span>;
  };

  const floatingLabelText = `Names${
    selectedFeatures.length > 1 ? ` (${selectedFeatures.length})` : ''
  }`;

  useEffect(() => {
    buildingAttributesSchema
      .validate(
        {
          description,
          postCodeAlias,
          features:
            filterdFeature.length === selectedFeatures.length
              ? filterdFeature
              : featureName,
          ImageValidation: spaceFile,
          LogoValidation: buildingLogo,
          ThumbnailValidation: thumnail,
          enableVisitorBooking: visitor,
          advisoryNotes: advisoryNote,
          buildingTenantsDetail: buildingTenantsDetail,
        },
        { abortEarly: false },
      )
      .then(() => {
        const payload = {
          description,
          postCodeAlias,
          features:
            filterdFeature.length === selectedFeatures.length
              ? filterdFeature
              : featureName,
          video: spaceVideo || undefined,
          previewImage: spaceVideo ? previewImage : undefined,
          images: spaceFile || undefined,
          buildingBrochure: floorPlan || undefined,
          buildingLogo: buildingLogo || undefined,
          enableVisitorBooking: visitor,
          buildingMobileAccess: mobileAccess,
          tenants: buildingTenantsDetail,
          ...(visitor
            ? {
                visitorBookingBuildingThumbnail: thumnail || [],
                OpeningClosingHours: days || [],
                maxVisitorsAllowed: Number(maxGuest),
                advisoryNotes: advisoryNote || '',
              }
            : {}),
        };
        if (
          attributesToggle &&
          (spaceVideo && spaceVideo.length > 0
            ? previewImage && previewImage.length > 0
            : true)
        ) {
          setLoading(true);
          ApiClient.apiPut(
            `${apiEndPoints.getBuildingAttributes}/${id}`,
            payload,
          )
            .then(data => {
              setLoading(false);
              setSuccessMessage(data && data.data.message);
              history.push('/building-attributes');
            })
            .catch(err => {
              setLoading(false);
              setAttributesToggle(false);
              alert(
                err &&
                  err.response &&
                  err.response.data &&
                  err.response.data.message,
              );
            });
        }
      })
      .catch(error => {
        if (attributesToggle) {
          setErrorMsgs(error);
          setAttributesToggle(false);
          setLoading(false);
        }
      });
  }, [
    createSpace,
    description,
    postCodeAlias,
    selectedFeatures,
    spaceFile,
    floorPlan,
    previewImage,
    spaceVideo,
    attributesToggle,
    phoneNumberValidation,
    errorMsgs,
    buildingLogo,
    thumnail,
  ]);

  const errorType = errorMsgs && errorMsgs.type;
  const errorFieldName = errorMsgs && errorMsgs.path;
  const errorFieldValue = errorMsgs && errorMsgs.value;
  const errorTypes = errorMsgs && errorMsgs.name;
  const errorFieldNames = [];
  if (errorMsgs && typeof errorMsgs === 'object') {
    if (errorMsgs.inner && Array.isArray(errorMsgs.inner)) {
      errorMsgs.inner.forEach(innerObj => {
        errorFieldNames.push(innerObj.path);
      });
    }
  }

  const breadCrumbs = {
    listBreadCrumbPath: '/building-attributes',
    listBreadCrumbTitle: 'Building Attributes',
    viewBreadCrumbPath: '/view-building-attributes/',
    viewBreadCrumbTitle: 'View Building Attributes',
    editBreadCrumbPath: '/edit-building-attributes/',
    editBreadCrumbTitle: 'Edit Building Attributes',
  };

  const startTime = (hour, dayIndex) => {
    const date = moment()
      .tz(timeZone.london)
      .set({ hour: hour, minute: 0, second: 0, millisecond: 0 });

    if (
      moment.tz(date, timeZone.london).format('HH') <
      moment.tz(days[dayIndex].end, timeZone.london).format('HH')
    ) {
      days[dayIndex].start = date;
      days[dayIndex].end = moment
        .tz(timeZone.london)
        .set({ hour: changeToBstHourOnlyFormat(days[dayIndex].end) });
      setDays([...days]);
    } else {
      alert('Please Select Start Time Less Than End Time');
    }
  };
  const endTime = (hour, dayIndex) => {
    const date = moment()
      .tz(timeZone.london)
      .set({ hour: hour, minute: 0, second: 0, millisecond: 0 });
    if (
      moment.tz(days[dayIndex].start, timeZone.london).format('HH') <
      moment.tz(date, timeZone.london).format('HH')
    ) {
      days[dayIndex].end = date;
      days[dayIndex].start = moment
        .tz(timeZone.london)
        .set({ hour: changeToBstHourOnlyFormat(days[dayIndex].start) });
      setDays([...days]);
    } else {
      alert('Please Select End Time Greater Than Start Time');
    }
  };

  return (
    <div className={classes.addUserFormBody}>
      <Breadcrumb breadCrumbs={breadCrumbs} />
      <Divider light />
      {loading ? (
        <CircularProgress color="inherit" />
      ) : (
        <div className={classes.addUserFormMain}>
          <div className="pageContainer addUserContainer">
            <div className={`${classes.addUserForm}`}>
              <ul className="offerFormList">
                <li>
                  <FormLabel component="legend">
                    Building Attribute Details
                  </FormLabel>
                </li>
              </ul>
              <ul className={`${classes.InputSection} BuildingDetails`}>
                <li>
                  <span>Building</span>
                  <Input type="text" readOnly value={buildingAttributes.name} />
                </li>
                <li>
                  <span>Building Address Line 1*</span>
                  <Input
                    type="text"
                    readOnly
                    value={buildingAttributes.addressLine1}
                  />
                </li>
                <li>
                  <span>Building Address Line 2</span>
                  <Input
                    type="text"
                    readOnly
                    value={buildingAttributes.addressLine2}
                  />
                </li>
              </ul>
              <ul className={`${classes.InputSection} BuildingLocations`}>
                <li>
                  <span>City*</span>
                  <Input type="text" readOnly value={buildingAttributes.city} />
                </li>
              </ul>
              <ul className={`${classes.InputSection} BuildingLocationCode`}>
                <li>
                  <span>Postcode*</span>
                  <Input
                    type="text"
                    readOnly
                    value={buildingAttributes.postcode}
                  />
                </li>
                <li>
                  <span>Postcode Alias*</span>
                  <Input
                    type="text"
                    value={postCodeAlias.toUpperCase()}
                    inputProps={{
                      maxLength: '4',
                    }}
                    onChange={e => {
                      const re = /^[A-Za-z0-9 ]+$/;
                      if (e.target.value === '' || re.test(e.target.value)) {
                        setPostCodeAlias(e.target.value.toUpperCase());
                      }
                    }}
                  />
                  {errorFieldValue && errorFieldValue.postCodeAlias === '' && (
                    <FormHelperText
                      className={classes.errorMsg}
                      error={
                        postCodeAlias === '' && 'PostCode Alias is required.'
                      }
                    >
                      {postCodeAlias === '' && 'PostCode Alias is required.'}
                    </FormHelperText>
                  )}
                </li>
                <li>&nbsp;</li>
              </ul>
              <ul className={`${classes.InputSection} BuildingVilgLocations`}>
                <li>
                  <span>Latitude Co-ordinate*</span>
                  <Input
                    type="text"
                    readOnly
                    value={buildingAttributes.latitude}
                  />
                </li>
                <li>
                  <span>Longitude Co-ordinate*</span>
                  <Input
                    type="text"
                    readOnly
                    value={buildingAttributes.longitude}
                  />
                </li>
                <li>
                  <span>Village*</span>
                  <Input
                    readOnly
                    type="text"
                    value={buildingAttributes.village}
                  />
                </li>
              </ul>
              <Divider className="formDivider" light />
              <ul className={`${classes.InputSection} BuildingDesc`}>
                <li>
                  <InputLabel shrink className={classes.formLabel}>
                    Description*
                  </InputLabel>
                  <textarea
                    type="text"
                    maxLength="1000"
                    value={description}
                    onChange={e => setDescription(e.target.value)}
                  />
                  <div className="descriptionBox">{`${description.length}/1000`}</div>
                  {((errorType === 'required' &&
                    errorFieldName === 'description') ||
                    (errorFieldValue &&
                      errorFieldValue.description === '')) && (
                    <FormHelperText
                      className={classes.errorMsg}
                      error={description === '' && 'Description is required.'}
                    >
                      {description === '' && 'Description is required.'}
                    </FormHelperText>
                  )}
                </li>
                <li>
                  <FormControl className={classes.formControl}>
                    <div className={classes.md_12}>
                      <InputLabel shrink className={classes.formLabel}>
                        Features*
                      </InputLabel>
                    </div>
                    <Select
                      className={classes.formSelectBox}
                      multiple
                      MenuProps={{
                        anchorOrigin: {
                          vertical: 'bottom',
                          horizontal: 'left',
                        },
                        getContentAnchorEl: null,
                      }}
                      floatingLabelText={floatingLabelText}
                      value={selectedFeatures}
                      onChange={e => {
                        setSelectedFeatures(e.target.value);
                      }}
                      selectionRenderer={selectionRenderer}
                    >
                      {buildingFeatures &&
                        buildingFeatures.map(feature => (
                          <MenuItem
                            insetChildren
                            checked={feature && feature.name}
                            key={feature.id}
                            value={feature.id}
                            primaryText={feature.name}
                          >
                            {feature.name}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                  {((errorType === 'required' &&
                    errorFieldName === 'features') ||
                    (errorFieldValue &&
                      errorFieldValue.features.length === 0)) && (
                    <FormHelperText
                      className={classes.errorMsg}
                      error={
                        selectedFeatures.length === 0 && 'Features is required.'
                      }
                    >
                      {selectedFeatures.length === 0 && 'Features is required.'}
                    </FormHelperText>
                  )}
                </li>
              </ul>

              <div>
                <Divider className="formDivider" light />
                <ul>
                  <li>
                    <InputLabel shrink className={`${classes.formLabel} `}>
                      Enable Visitor Management
                    </InputLabel>
                  </li>
                  <li className="top_n11">
                    <FormLabel shrink className={classes.visitorTable}>
                      No
                    </FormLabel>
                    <Switch
                      onChange={() => setVisitor(!visitor)}
                      checked={visitor}
                      color="primary"
                    />
                    <FormLabel shrink className={classes.visitorTable}>
                      Yes
                    </FormLabel>
                  </li>
                </ul>
                {visitor ? (
                  <div>
                    <FormLabel shrink className={classes.visitorTable}>
                      Visitor Management information
                    </FormLabel>
                    <div className={`${classes.openHoursBG} ${classes.mt_20}`}>
                      <FormLabel shrink className={classes.visitorTable}>
                        Building Opening Hours
                      </FormLabel>

                      <Grid
                        container
                        spacing={2}
                        className={`${classes.pb_10} ${classes.pt_10} ${classes.mt_20}`}
                      >
                        <Grid item md={3}>
                          <FormLabel shrink className={classes.visitorTable}>
                            Weekday
                          </FormLabel>
                        </Grid>
                        <Grid item md={3}>
                          <FormLabel shrink className={classes.visitorTable}>
                            Closed
                          </FormLabel>
                        </Grid>
                        <Grid item md={4} className="textAlign_center">
                          <FormLabel shrink className={classes.visitorTable}>
                            Opening Times
                          </FormLabel>
                        </Grid>
                      </Grid>

                      {days.map((day, index) => {
                        return (
                          <Grid
                            container
                            id={day.day}
                            spacing={2}
                            className="mt_10"
                          >
                            <Grid
                              item
                              md={3}
                              className="d_inherit alignItems_center"
                            >
                              <InputLabel
                                shrink
                                className={`${classes.formLabel}`}
                              >
                                {day.day}
                              </InputLabel>
                            </Grid>
                            <Grid item md={3} className="d_inherit">
                              <Checkbox
                                id={`${day.day}Check`}
                                checked={day.closed}
                                onChange={e => {
                                  days[index].closed = e.target.checked;
                                  setDays([...days]);
                                }}
                                color="primary"
                              />
                            </Grid>
                            {!day.closed ? (
                              <Grid item md={6}>
                                <Grid container id={day.day} spacing={2}>
                                  <Grid item md={6}>
                                    <Select
                                      MenuProps={{
                                        anchorOrigin: {
                                          vertical: 'bottom',
                                          horizontal: 'left',
                                        },
                                        getContentAnchorEl: null,
                                      }}
                                      className={`${classes.formSelectBox} ${classes.wd_120}`}
                                      name="startTime"
                                      disabled={day.closed}
                                      value={
                                        day.start
                                          ? moment
                                              .tz(day.start, timeZone.london)
                                              .format('H')
                                          : ''
                                      }
                                      onChange={e =>
                                        startTime(e.target.value, index)
                                      }
                                    >
                                      <MenuItem value={8}>8:00 AM</MenuItem>
                                      <MenuItem value={9}>9:00 AM</MenuItem>
                                      <MenuItem value={10}>10:00 AM</MenuItem>
                                      <MenuItem value={11}>11:00 AM</MenuItem>
                                      <MenuItem value={12}>12:00 PM</MenuItem>
                                      <MenuItem value={13}>1:00 PM</MenuItem>
                                      <MenuItem value={14}>2:00 PM</MenuItem>
                                      <MenuItem value={15}>3:00 PM</MenuItem>
                                      <MenuItem value={16}>4:00 PM</MenuItem>
                                      <MenuItem value={17}>5:00 PM</MenuItem>
                                    </Select>
                                  </Grid>
                                  <Grid item md={6}>
                                    <Select
                                      MenuProps={{
                                        anchorOrigin: {
                                          vertical: 'bottom',
                                          horizontal: 'left',
                                        },
                                        getContentAnchorEl: null,
                                      }}
                                      className={`${classes.formSelectBox} ${classes.wd_120}`}
                                      disabled={day.closed}
                                      name="endTime"
                                      value={
                                        day.end
                                          ? moment
                                              .tz(day.end, timeZone.london)
                                              .format('H')
                                          : ''
                                      }
                                      onChange={e =>
                                        endTime(e.target.value, index)
                                      }
                                    >
                                      <MenuItem value={9}>9:00 AM</MenuItem>
                                      <MenuItem value={10}>10:00 AM</MenuItem>
                                      <MenuItem value={11}>11:00 AM</MenuItem>
                                      <MenuItem value={12}>12:00 PM</MenuItem>
                                      <MenuItem value={13}>1:00 PM</MenuItem>
                                      <MenuItem value={14}>2:00 PM</MenuItem>
                                      <MenuItem value={15}>3:00 PM</MenuItem>
                                      <MenuItem value={16}>4:00 PM</MenuItem>
                                      <MenuItem value={17}>5:00 PM</MenuItem>
                                      <MenuItem value={18}>6:00 PM</MenuItem>
                                    </Select>
                                  </Grid>
                                </Grid>
                              </Grid>
                            ) : (
                              ''
                            )}
                          </Grid>
                        );
                      })}

                      <div className={`  ${classes.pt_50}   alignItems_center`}>
                        <InputLabel
                          shrink
                          className={`${classes.formLabel} ${classes.wd_150}`}
                        >
                          Max Guests
                        </InputLabel>

                        <input
                          type="text"
                          className={`${classes.maxGuest}`}
                          value={maxGuest}
                          readOnly
                        />
                      </div>
                      <div
                        className={` ${classes.pt_50} alignItems_center position_relative`}
                      >
                        <li>
                          <InputLabel
                            shrink
                            className={`${classes.formLabel} ${classes.wd_150}`}
                          >
                            Advisory note*
                          </InputLabel>
                          <small className="advisoryNoteCaption">
                            (Shown on mobile app)
                          </small>
                        </li>
                        <textarea
                          type="text"
                          maxLength="70"
                          className={`${classes.advisoryNote}`}
                          value={advisoryNote}
                          onChange={e => setAdvisoryNote(e.target.value)}
                        />
                        <div className="advisory_count_check">{`${advisoryNote.length}/70`}</div>
                        {((errorType === 'required' &&
                          errorFieldName === 'advisoryNotes') ||
                          (errorFieldValue &&
                            errorFieldValue.advisoryNotes === '')) && (
                          <FormHelperText
                            className={classes.errorMsg}
                            error={
                              advisoryNote === '' &&
                              'Advisory note is required.'
                            }
                          >
                            {advisoryNote === '' &&
                              'Advisory note is required.'}
                          </FormHelperText>
                        )}
                      </div>
                    </div>
                  </div>
                ) : (
                  ''
                )}
              </div>
              <div>
                <Divider className="formDivider" light />
                <ul>
                  <li>
                    <InputLabel shrink className={`${classes.formLabel} `}>
                      Mobile Access Management
                    </InputLabel>
                  </li>
                  <li className="top_n11">
                    <FormLabel shrink className={classes.visitorTable}>
                      No
                    </FormLabel>
                    <Switch
                      onChange={manageMobileAccess}
                      checked={mobileAccess}
                      color="primary"
                    />
                    <FormLabel shrink className={classes.visitorTable}>
                      Yes
                    </FormLabel>
                  </li>
                </ul>
              </div>

              {mobileAccess ? (
                <Paper className={classes.root}>
                  {buildingTenantsDetail.length !== 0 ? (
                    <TableContainer className={classes.container}>
                      <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                          <TableRow>
                            {columns.map(column => (
                              <TableCell
                                className={`${classes.tableHeaderCell}`}
                                style={{ minWidth: column.minWidth }}
                              >
                                {column.label}
                              </TableCell>
                            ))}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {buildingTenantsDetail.map((tenant, index) => (
                            <TableRow className={classes.tableBody}>
                              <TableCell
                                component="th"
                                scope="row"
                                align="left"
                                valign="middle"
                                style={{
                                  maxWidth: 300,
                                  minWidth: 280,
                                  width: 120,
                                  verticalAlign: 'middle',
                                }}
                              >
                                <div>{tenant.tenantName}</div>
                              </TableCell>
                              <TableCell>
                                <FormControl
                                  className={classes.formControlTenant}
                                >
                                  <Select
                                    className={classes.formSelectBox}
                                    id={
                                      tenant.tenantBuildingMobileAccess ===
                                        false ||
                                      typeof tenant.tenantBuildingMobileAccess ===
                                        'undefined'
                                        ? 'mobileAccessBlocked'
                                        : null
                                    }
                                    name="mobAccess"
                                    MenuProps={{
                                      anchorOrigin: {
                                        vertical: 'bottom',
                                        horizontal: 'left',
                                      },
                                      getContentAnchorEl: null,
                                    }}
                                    value={
                                      tenant.tenantBuildingMobileAccess ===
                                      undefined
                                        ? false
                                        : tenant.tenantBuildingMobileAccess
                                    }
                                    onChange={e =>
                                      tenantDetailSetter(e, tenant.tenantId)
                                    }
                                  >
                                    <MenuItem value>Allowed</MenuItem>
                                    <MenuItem value={false}>Blocked</MenuItem>
                                  </Select>
                                </FormControl>
                              </TableCell>
                              <TableCell>
                                <div className={`${classes.addUserFormInp}`}>
                                  <div className={classes.clearanceInpBody}>
                                    <ul
                                      className={`${classes.InputSectionInp}`}
                                    >
                                      <li className={`${classes.Clearance}`}>
                                        <Input
                                          className="InputField"
                                          name="clearanceInput"
                                          disabled={
                                            tenant.tenantBuildingMobileAccess ===
                                              false ||
                                            tenant.tenantBuildingMobileAccess ===
                                              undefined
                                          }
                                          placeholder={
                                            tenant.tenantBuildingMobileAccess ===
                                              false ||
                                            tenant.tenantBuildingMobileAccess ===
                                              undefined
                                              ? 'No Group Selected'
                                              : ''
                                          }
                                          type="text"
                                          value={
                                            tenant.tenantBuildingClearanceControl
                                          }
                                          onChange={e =>
                                            tenantDetailSetter(
                                              e,
                                              tenant.tenantId,
                                            )
                                          }
                                        />
                                        {(errorTypes === 'ValidationError' &&
                                          errorFieldNames &&
                                          errorFieldNames.includes(
                                            `buildingTenantsDetail[${index}].tenantBuildingClearanceControl`,
                                          )) ||
                                        (errorFieldValue &&
                                          errorFieldValue.buildingTenantDetail &&
                                          errorFieldValue?.buildingTenantDetail[
                                            index
                                          ]?.tenantBuildingClearanceControl ===
                                            '') ? (
                                          <FormHelperText
                                            className={classes.errorMsg}
                                            error={
                                              tenant?.tenantBuildingClearanceControl.trim() ===
                                                '' &&
                                              tenant?.tenantBuildingMobileAccess ===
                                                true &&
                                              'clearance control group is required.'
                                            }
                                          >
                                            {tenant?.tenantBuildingClearanceControl.trim() ===
                                              '' &&
                                              tenant?.tenantBuildingMobileAccess ===
                                                true &&
                                              'clearance control group is required.'}
                                          </FormHelperText>
                                        ) : null}
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  ) : (
                    <div className='tenantNotFound'>
                      <div className="noTenantResult">
                        <h2>NO TENANT FOUND</h2>
                        <p>No Tenants in this building.</p>
                      </div>
                    </div>
                  )}
                </Paper>
              ) : (
                ''
              )}

              <Divider className="formDivider" light />
              <ul className="offerFormList">
                <li>
                  <FormLabel component="legend">Media</FormLabel>
                </li>
              </ul>

              <li>
                <SpaceFiles
                  values={{
                    errorType,
                    errorFieldName,
                    errorFieldValue,
                    setSpaceFile,
                    spaceFile,
                    setCreateSpace,
                    attributesToggle,
                    setAttributesToggle,
                    setPreviewImage,
                    previewImage,
                    setSpaceVideo,
                    spaceVideo,
                    setFloorPlan,
                    floorPlan,
                    buildingLogo,
                    setBuildingLogo,
                    visitor,
                    setVisitor,
                    thumnail,
                    setThumnail,
                  }}
                  buildingAttributes
                />
              </li>
              <Dialog
                open={mobileWarningPopup}
                onClose={handleCloseMobileAccessWarning}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                className="cancelDialogOpend"
              >
                <div className={classes.cancelDialog}>
                  <h2>{warningMsg.mobileAccessWarning}</h2>
                  <p>{warningMsg.proceed}</p>
                </div>
                <Divider className="formDivider popupDivider" light />
                <DialogActions className={`${classes.formButton}`}>
                  <Button
                    onClick={handleCloseMobileAccessWarning}
                    color="primary"
                  >
                    No
                  </Button>
                  <Button onClick={handleYesMobileAccessWarning} autoFocus>
                    Yes
                  </Button>
                </DialogActions>
              </Dialog>
              <SaveAndCancelButtons
                setToggle={setAttributesToggle}
                route="/building-attributes"
              />
              <WarningPopup />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default EditBuildingAttributes;
