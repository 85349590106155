/* eslint-disable import/no-cycle */
import React, { createContext, useState, useEffect } from 'react';
import ApiClient from '../helpers/ApiClient';
import apiEndPoints from '../helpers/apiEndPoints';
import ManageTenants from '../containers/ManageTenants/ManageTenants';

export const ManageTenentContext = createContext({});

export const ManageTenentContextProvider = () => {
  const [customerSearch, setCustomerSearch] = useState('');
  const [tenantsUsersList, setTenantsUsersList] = useState([]);
  const [manageTenantsUsersList, setManageTenantsUsersList] = useState([]);
  const [totalManageTenants, setTotalManageTenants] = useState(0);
  const [totalTenantsCount, setTotalTenantsCount] = useState(0);
  const [totalManageTenantsCount, setTotalManageTenantsCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [tenantSearchText, setTenantSearchText] = useState('');
  const [tenantsSearch, setTenantsSearch] = useState(false);
  const [tenantUserSearch, setTenantUserSearch] = useState(false);
  const [sortOrder, setSortOrder] = useState('asc');
  const [sortBy, setSortBy] = useState('name');
  const [selectedStatus, setSelectedStatus] = useState([]);
  const [payAccountTrue, setPayAccountTrue] = useState(undefined);
  const [payAccountFalse, setPayAccountFalse] = useState('');
  const [payAccountSelected, setPayAccountSelectede] = useState('');
  const [offset, setOffset] = useState(0);
  const [buildings, setBuildings] = useState([]);
  const [selectedBuildingId, setSelectedBuildingId] = useState([]);
  const [villages, setVillages] = useState([]);

  const [payAccountOptions, setPayAccountOptions] = useState([
    { label: 'Yes', key: true, selected: false },
    { label: 'No', key: false, selected: false },
  ]);

  // combination for Filters API for Tenant Users passing payload as parameter
  const getManageTenantusersListAction = payload => {
    ApiClient.apiPost(apiEndPoints.getTenants, payload)
      .then(data => {
        setTotalManageTenantsCount(data && data.data.count);
        setManageTenantsUsersList(data && data.data && data.data.data);
        setLoading(false);
      })
      .catch(error => {
        console.log(error, 'error');
      });
  };

  useEffect(() => {
    const payToAccountUpdate = [];

    payAccountOptions.filter(account => {
      if (account.selected) {
        payToAccountUpdate.push(account.key);
      }
      return account.selected !== false;
    });
    setSelectedStatus(payToAccountUpdate);
    setPayAccountSelectede(payToAccountUpdate);

    getManageTenantusersListAction({
      searchText: customerSearch.length > 2 ? customerSearch : undefined,
      sortOrder,
      payToAccount:
        payToAccountUpdate && payToAccountUpdate.length === 2
          ? undefined
          : payToAccountUpdate && payToAccountUpdate[0],
      buildings:
        selectedBuildingId && selectedBuildingId.length > 0
          ? selectedBuildingId
          : undefined,
      sortBy,
      offset,
      limit: 40,
    });
  }, [
    page,
    tenantSearchText,
    sortBy,
    sortOrder,
    payAccountOptions,
    customerSearch,
    buildings,
  ]);

  return (
    <ManageTenentContext.Provider
      value={{
        manageTenantsUsersList,
        setManageTenantsUsersList,
        totalManageTenants,
        setTotalManageTenants,
        totalManageTenantsCount,
        setTotalManageTenantsCount,
        totalTenantsCount,
        setTotalTenantsCount,
        tenantsUsersList,
        loading,
        payAccountOptions,
        setPayAccountOptions,
        payAccountTrue,
        setPayAccountTrue,
        payAccountFalse,
        setPayAccountFalse,
        setSelectedStatus,
        selectedStatus,
        page,
        setPage,
        setOffset,
        customerSearch,
        setCustomerSearch,
        setTenantsUsersList,
        setLoading,
        tenantsSearch,
        setTenantsSearch,
        tenantSearchText,
        setTenantSearchText,
        setSortOrder,
        tenantUserSearch,
        setTenantUserSearch,
        sortOrder,
        setSortBy,
        sortBy,
        payAccountSelected,
        setPayAccountSelectede,
        buildings,
        setBuildings,
        selectedBuildingId,
        setSelectedBuildingId,
        villages,
        setVillages,
      }}
    >
      <ManageTenants />
    </ManageTenentContext.Provider>
  );
};
