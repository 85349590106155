import React, { useEffect, useState } from 'react';
import {
  Divider,
  makeStyles,
  FormLabel,
  Input,
  InputLabel,
  CircularProgress,
  Tooltip,
  Switch,
  Grid,
  Checkbox,
  FormControl,
  TableCell,
  TableBody,
  TableRow,
  TableHead,
  Table,
  Paper,
  TableContainer,
} from '@material-ui/core';
import { Link, useParams } from 'react-router-dom';
import moment from 'moment';
import dowmArw from '../../images/down-arw.svg';
import './BuildingAttributes.css';
import SpaceFiles from '../../components/ImageUpload/SpaceFiles';
import apiEndPoints from '../../helpers/apiEndPoints';
import ApiClient from '../../helpers/ApiClient';
import buttonsEditPencil from '../../images/buttons-edit-w-pencil.svg';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import CloseButton from '../../components/Buttons/CloseButton';

const useStyles = makeStyles(() => ({
  pageContainer: {
    height: '100%',
    width: '100%',
    marginTop: '88px',
    padding: '0 30px',
    boxSizing: 'border-box',
  },
  addUserForm: {
    width: '70%',
    margin: '0 auto',
    padding: '40px 0',
    '& ul': {
      display: 'flex',
      justifyContent: 'space-between',
      flexWrap: 'wrap',
      alignItems: 'flex-end',
      '& li': {
        listStyle: 'none',
        width: '32%',
        marginBottom: '22px',
        position: 'relative',
      },
    },
  },
  addUserFormInp: {
    width: '80%',
    margin: '0 auto',
    padding: '0 0',
    marginLeft: '0px',
  },
  InputSectionInp: {
    '& li': {
      '& span': {
        display: 'block',
        fontSize: '16px',
        fontWeight: 'normal',
        color: '#838384',
        fontFamily: ['Varta', 'sans-serif'].join(','),
      },
      '& input': {
        height: '45px',
        lineHeight: '45px',
        padding: '0 15px !important',
        fontSize: '16px',
        color: '#444444',
        borderRadius: '3px',
        border: 'solid 0.5px #979797',
        marginTop: '10px',
        width: '100%',
        background: 'transparent',
        fontFamily: ['Varta', 'sans-serif'].join(','),
      },
      '& textarea': {
        height: 'auto',
        minHeight: '92px',
        lineHeight: '22px',
        padding: '10px 15px !important',
        fontSize: '16px',
        color: '#444444',
        borderRadius: '3px',
        border: 'solid 0.5px #979797',
        marginTop: '10px',
        width: '100%',
        background: 'transparent',
        resize: 'none',
        fontFamily: ['Varta', 'sans-serif'].join(','),
        outline: 'none',
      },
      '& > div': {
        '& > div': {
          '&:before': {
            display: 'none',
          },
          '&:after': {
            display: 'none',
          },
        },
        width: '100%',
        '&:before': {
          display: 'none',
        },
        '&:after': {
          display: 'none',
        },
      },
    },
  },
  formControl: {
    width: '100%',
    '& InputLabel': {
      position: 'relative',
    },
  },
  formControlTenant: {
    width: '70%',
    '& InputLabel': {
      position: 'relative',
    },
  },
  formLabel: {
    fontSize: '16px',
    fontWeight: 'normal',
    color: '#838384',
    transform: 'none',
    fontFamily: ['Varta', 'sans-serif'].join(','),
  },
  formSelectBox: {
    '& svg': {
      display: 'none',
    },
    '&:before': {
      display: 'none',
    },
    '&:after': {
      display: 'none',
    },
    '& > div': {
      height: '45px',
      lineHeight: '45px',
      padding: '0 15px !important',
      fontSize: '16px',
      color: '#444444',
      borderRadius: '3px !important',
      border: 'solid 0.5px #979797',
      marginTop: '10px',
      background: `transparent url(${dowmArw}) no-repeat 95% center !important`,
      backgroundSize: '10px',
      appearance: 'none',
      fontFamily: ['Varta', 'sans-serif'].join(','),
    },
  },
  formSelectBoxNodown: {
    '& > div': {
      background: ' transparent !important',
    },
  },
  InputSection: {
    '& li': {
      '& span': {
        display: 'block',
        fontSize: '16px',
        fontWeight: 'normal',
        color: '#838384',
        fontFamily: ['Varta', 'sans-serif'].join(','),
      },
      '& input': {
        height: '45px',
        lineHeight: '45px',
        padding: '0 15px !important',
        fontSize: '16px',
        color: '#444444',
        borderRadius: '3px',
        border: 'solid 0.5px #979797',
        marginTop: '10px',
        width: '100%',
        background: 'transparent',
        fontFamily: ['Varta', 'sans-serif'].join(','),
      },
      '& textarea': {
        height: 'auto',
        minHeight: '92px',
        lineHeight: '22px',
        padding: '10px 15px !important',
        fontSize: '16px',
        color: '#444444',
        borderRadius: '3px',
        border: 'solid 0.5px #979797',
        marginTop: '10px',
        width: '100%',
        background: 'transparent',
        resize: 'none',
        fontFamily: ['Varta', 'sans-serif'].join(','),
        outline: 'none',
      },
      '& > div': {
        '& > div': {
          '&:before': {
            display: 'none',
          },
          '&:after': {
            display: 'none',
          },
        },
        width: '100%',
        '&:before': {
          display: 'none',
        },
        '&:after': {
          display: 'none',
        },
      },
    },
  },
  InputSectionReadonly: {
    '& > div': {
      '& > div': {
        '&:before': {
          display: 'none',
        },
        '&:after': {
          display: 'none',
        },
        '& input': {
          height: '45px',
          lineHeight: '45px',
          padding: '0 15px !important',
          fontSize: '16px',
          color: '#444444',
          borderRadius: '3px',
          border: 'solid 0.5px #979797',
          marginTop: '10px',
          appearance: 'none',
          fontFamily: ['Varta', 'sans-serif'].join(','),
        },
      },
    },
  },
  radioSection: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '0',
    '& span': {
      fontFamily: ['Varta', 'sans-serif'].join(','),
      fontSize: '16px',
      lineHeight: '20px',
      color: '#838384',
      '&:hover': {
        backgroundColor: 'transparent !important',
      },
      '& svg': {
        fontSize: '16px',
        fill: '#979797',
        '&:last-child': {
          fill: '#4e4e4e',
        },
      },
    },
  },
  errorMsg: {
    fontSize: '13px',
    color: '#f90000 !important',
    marginTop: 4,
    fontFamily: ['Varta', 'sans-serif'].join(','),
  },
  visitorTable: {
    fontFamily: ['Varta', 'sans-serif'].join(','),
    fontSize: '16px',
    fontWeight: 'bold',
    color: '#838384',
  },
  pb_20: {
    paddingBottom: '20px',
  },
  pb_10: {
    paddingBottom: '10px',
  },
  pt_10: {
    paddingTop: '10px',
  },
  pt_20: {
    paddingTop: '20px',
  },
  pt_50: {
    paddingTop: '50px',
  },
  wd_150: {
    width: '150px',
  },
  mt_20: {
    marginTop: '20px',
  },
  flex: {
    display: 'flex',
  },
  maxGuest: {
    padding: '15px',
    height: '45px',
    border: 'solid 0.5px #979797',
    borderRadius: '3px',
    outline: 0,
    marginTop: '10px',
  },
  advisoryNote: {
    marginTop: '10px',
    padding: '15px',
    height: '100px',
    width: '100%',
    border: 'solid 0.5px #979797',
    fontFamily: 'Varta,sans-serif',
    fontSize: '16px',
    borderRadius: '3px',
    outline: 0,
  },
  openHoursBG: {
    padding: '20px',
    border: 'solid 0.5px #bdbdbd',
    color: '#838384',
  },
  Clearance: {
    width: '100% !important',
    marginBottom: '0px !important',
  },
  clearanceHead: {
    marginLeft: '14%',
  },
  accessBody: {
    height: '100px',
  },
  clearanceInpBody: {
    paddingBottom: '10px',
  },
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 'calc(100vh - 240px)',
  },
  tableHeaderCell: {
    background: '#f8f8f8',
    border: 'none',
    padding: '10px 15px',
    color: '#444444',
    fontSize: '13px',
    fontWeight: 700,
    fontFamily: ['Varta', 'sans-serif'].join(','),
    cursor: 'pointer',
    textTransform: 'uppercase',
    '&:before': {
      position: 'absolute',
      content: '""',
      background: '#dfdfdf',
      right: '0',
      top: '50%',
      transform: 'translateY(-50%)',
      width: '1px',
      height: '40%',
    },
    '&:last-child': {
      '&:before': {
        display: 'none',
      },
      '&:after': {
        display: 'none',
      },
    },
  },
  tableBody: {
    verticalAlign: 'middle',
    '& th': {
      fontSize: '14px',
      lineHeight: '23px',
      color: '#838384',
      fontWeight: 'bold',
      fontFamily: ['Varta', 'sans-serif'].join(','),
      textTransform: 'capitalize',
      padding: '0px 12px',
    },
    '& td': {
      fontSize: '14px',
      lineHeight: '23px',
      color: '#838384',
      fontWeight: 'bold',
      fontFamily: ['Varta', 'sans-serif'].join(','),
      textTransform: 'capitalize',
      padding: '0px 12px',
    },
  },
}));

const ViewBuildingAttributes = () => {
  const classes = useStyles();
  const { id } = useParams();
  const [buildingAttributes, setBuildingAttributes] = useState([]);
  const [buildingFeatures, setBuildingFeatures] = useState([]);
  const [spaceFile, setSpaceFile] = useState([]);
  const [createSpace, setCreateSpace] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [spaceVideo, setSpaceVideo] = useState('');
  const [floorPlan, setFloorPlan] = useState('');
  const [loading, setLoading] = useState(true);
  const [buildingLogo, setBuildingLogo] = useState([]);
  const [maxGuest, setMaxGuest] = useState();
  const [advisoryNote, setAdvisoryNote] = useState('');
  const [days, setDays] = useState([]);
  const [thumnail, setThumnail] = useState([]);
  const [visitor, setVisitor] = useState(false);
  const [mobileAccess, setMobileAccess] = useState(false);
  const [buildingTenantsDetail, setBuildingTenantsDetail] = useState([
    {
      tenantId: '',
      tenantName: '',
      tenantBuildingMobileAccess: false,
      tenantBuildingClearanceControl: '',
    },
  ]);
  const columns = [
    {
      id: 'tenantName',
      SortOrder: 'desc',
      SortBy: 'tenant',
      label: 'TENANT',
    },
    {
      id: 'mobileAccess',
      SortOrder: 'desc',
      SortBy: 'meetingRoom',
      label: 'MOBILE ACCESS',
    },
    {
      id: 'clearanceGroup',
      SortOrder: 'desc',
      SortBy: '',
      label: 'CLEARANCE CONTROL GROUP(S)',
    },
  ];

  useEffect(() => {
    ApiClient.apiGet(`${apiEndPoints.getBuildingAttributes}/${id}`)
      .then(data => {
        setLoading(false);
        const buildingDetails = data && data.data && data.data.data;
        setBuildingAttributes(buildingDetails);
        setBuildingFeatures(
          (buildingDetails &&
            buildingDetails.features &&
            buildingDetails.features.map(item => {
              return item.name;
            })) ||
            [],
        );
        setSpaceFile(buildingDetails.images || []);
        setPreviewImage(buildingDetails.previewImage || []);
        setSpaceVideo(buildingDetails.video);
        setFloorPlan(buildingDetails.buildingBrochure || '');
        setBuildingLogo(buildingDetails.buildingLogo || []);
        setMaxGuest(buildingDetails.maxVisitorsAllowed || 1);
        setAdvisoryNote(buildingDetails.advisoryNotes || '');
        setDays(buildingDetails.OpeningClosingHours || []);
        setThumnail(buildingDetails.visitorBookingBuildingThumbnail || []);
        setVisitor(buildingDetails.enableVisitorBooking || false);
        setBuildingTenantsDetail(buildingDetails.tenants);
        setMobileAccess(buildingDetails.buildingMobileAccess);
      })
      .catch(err => {
        setLoading(false);
        alert(
          err && err.response && err.response.data && err.response.data.message,
        );
      });
  }, []);

  const breadCrumbs = {
    listBreadCrumbPath: '/building-attributes',
    listBreadCrumbTitle: 'Building Attributes',
    viewBreadCrumbPath: '/view-building-attributes/',
    viewBreadCrumbTitle: 'View Building Attributes',
  };

  return (
    <div className={classes.addUserFormBody}>
      <Breadcrumb breadCrumbs={breadCrumbs} />
      <Divider light />
      {loading ? (
        <CircularProgress color="inherit" />
      ) : (
        <div className={classes.addUserFormMain}>
          <div className="pageContainer addUserContainer">
            <Link to={`/edit-building-attributes/${id}`}>
              <div className="ditIcon">
                <img src={buttonsEditPencil} alt="buttonsEditPencil" />
              </div>
            </Link>
            <div className={`${classes.addUserForm}`}>
              <ul className="offerFormList">
                <li>
                  <FormLabel component="legend">
                    Building Attribute Details
                  </FormLabel>
                </li>
              </ul>
              <ul className={`${classes.InputSection} BuildingDetails`}>
                <li>
                  <span>Building</span>
                  <Input type="text" readOnly value={buildingAttributes.name} />
                </li>
                <li>
                  <span>Building Address Line 1</span>
                  <Input
                    type="text"
                    readOnly
                    value={buildingAttributes.addressLine1}
                  />
                </li>
                <li>
                  <span>Building Address Line 2</span>
                  <Input
                    type="text"
                    readOnly
                    value={buildingAttributes.addressLine2}
                  />
                </li>
              </ul>
              <ul className={`${classes.InputSection} BuildingLocations`}>
                <li>
                  <span>City</span>
                  <Input type="text" readOnly value={buildingAttributes.city} />
                </li>
              </ul>
              <ul className={`${classes.InputSection} BuildingLocationCode`}>
                <li>
                  <span>Postcode</span>
                  <Input
                    type="text"
                    readOnly
                    value={buildingAttributes.postcode}
                  />
                </li>
                <li>
                  <span>Postcode Alias</span>
                  <Input
                    type="text"
                    readOnly
                    value={buildingAttributes.postCodeAlias}
                  />
                </li>
                <li>&nbsp;</li>
              </ul>
              <ul className={`${classes.InputSection} BuildingVilgLocations`}>
                <li>
                  <span>Latitude Co-ordinate</span>
                  <Input
                    type="text"
                    readOnly
                    value={buildingAttributes.latitude}
                  />
                </li>
                <li>
                  <span>Longitude Co-ordinate</span>
                  <Input
                    type="text"
                    readOnly
                    value={buildingAttributes.longitude}
                  />
                </li>
                <li>
                  <span>Village</span>
                  <Input
                    readOnly
                    type="text"
                    value={buildingAttributes.village}
                  />
                </li>
              </ul>
              <Divider className="formDivider" light />
              <ul className={`${classes.InputSection} BuildingDesc`}>
                <li>
                  <InputLabel shrink className={classes.formLabel}>
                    Description
                  </InputLabel>
                  <textarea
                    readOnly
                    type="text"
                    maxLength="500"
                    value={buildingAttributes.description}
                  />
                </li>
                <li>
                  <span>Features</span>
                  <Tooltip
                    title={buildingFeatures.toString().split(',').join(', ')}
                    arrow
                    placement="top"
                  >
                    <Input
                      readOnly
                      type="text"
                      value={`${
                        buildingFeatures.length > 1
                          ? `${buildingFeatures.length} Features selected`
                          : `${buildingFeatures.length}  Feature selected`
                      }`}
                    />
                  </Tooltip>
                </li>
              </ul>
              <div>
                <Divider className="formDivider" light />
                <ul>
                  <li>
                    <InputLabel shrink className={`${classes.formLabel} `}>
                      Enable Visitor Management
                    </InputLabel>
                  </li>
                  <li className="top_11">
                    <FormLabel shrink className={classes.visitorTable}>
                      No
                    </FormLabel>
                    <Switch disabled="true" checked={visitor} color="primary" />
                    <FormLabel shrink className={classes.visitorTable}>
                      Yes
                    </FormLabel>
                  </li>
                </ul>
                {visitor ? (
                  <div>
                    <FormLabel shrink className={classes.visitorTable}>
                      Visitor Management information
                    </FormLabel>
                    <div className={`${classes.openHoursBG} ${classes.mt_20}`}>
                      <FormLabel shrink className={classes.visitorTable}>
                        Building Opening Hours
                      </FormLabel>
                      <div className={`${classes.mt_20}`}>
                        <Grid
                          container
                          spacing={2}
                          className={`${classes.pb_10} ${classes.pt_10}`}
                        >
                          <Grid item md={3}>
                            <FormLabel shrink className={classes.visitorTable}>
                              Weekday
                            </FormLabel>
                          </Grid>
                          <Grid item md={3}>
                            <FormLabel shrink className={classes.visitorTable}>
                              Closed
                            </FormLabel>
                          </Grid>
                          <Grid item md={4} className="textAlign_center">
                            <FormLabel shrink className={classes.visitorTable}>
                              Opening Times
                            </FormLabel>
                          </Grid>
                        </Grid>

                        {days
                          ? days.map(day => {
                              return (
                                <Grid
                                  container
                                  id={day.day}
                                  spacing={2}
                                  className="alignItems_center"
                                >
                                  <Grid item md={3}>
                                    <InputLabel
                                      shrink
                                      className={`${classes.formLabel}`}
                                    >
                                      {day.day}
                                    </InputLabel>
                                  </Grid>
                                  <Grid item md={3}>
                                    <Checkbox
                                      id={`${day.day}Check`}
                                      checked={day.closed}
                                      color="primary"
                                      disabled="true"
                                    />
                                  </Grid>
                                  {!day.closed ? (
                                    <Grid item md={6}>
                                      <Grid container id={day.day} spacing={2}>
                                        <Grid item md={6}>
                                          {day.start
                                            ? moment
                                                .tz(day.start, 'Europe/London')
                                                .format('hh:mm a')
                                            : ''}
                                        </Grid>

                                        <Grid item md={6}>
                                          {day.end
                                            ? moment
                                                .tz(day.end, 'Europe/London')
                                                .format('hh:mm a')
                                            : ''}
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                  ) : (
                                    ''
                                  )}
                                </Grid>
                              );
                            })
                          : ''}
                      </div>
                      <div className={`  ${classes.pt_50} alignItems_center`}>
                        <InputLabel
                          shrink
                          className={`${classes.formLabel} ${classes.wd_150}`}
                        >
                          Max Guests
                        </InputLabel>

                        <input
                          type="text"
                          className={`${classes.maxGuest}`}
                          value={maxGuest}
                          readOnly
                        />
                      </div>
                      <div className={` ${classes.pt_50} alignItems_center`}>
                        <li>
                          <InputLabel
                            shrink
                            className={`${classes.formLabel} ${classes.wd_150}`}
                          >
                            Advisory note
                          </InputLabel>

                          <small className="advisoryNoteCaption">
                            (Shown on mobile app)
                          </small>
                        </li>
                        <textarea
                          type="text"
                          className={`${classes.advisoryNote}`}
                          value={advisoryNote}
                          readOnly
                        />
                      </div>
                    </div>
                  </div>
                ) : (
                  ''
                )}
              </div>
              <div>
                <Divider className="formDivider" light />
                <ul>
                  <li>
                    <InputLabel shrink className={`${classes.formLabel} `}>
                      Mobile Access Management
                    </InputLabel>
                  </li>
                  <li className="top_11">
                    <FormLabel shrink className={classes.visitorTable}>
                      No
                    </FormLabel>
                    <Switch
                      disabled="true"
                      checked={mobileAccess}
                      color="primary"
                    />
                    <FormLabel shrink className={classes.visitorTable}>
                      Yes
                    </FormLabel>
                  </li>
                </ul>
                {mobileAccess ? (
                  <Paper className={classes.root}>
                    {buildingTenantsDetail.length !== 0 ? (
                      <TableContainer className={classes.container}>
                        <Table stickyHeader aria-label="sticky table">
                          <TableHead>
                            <TableRow>
                              {columns.map(column => (
                                <TableCell
                                  className={`${classes.tableHeaderCell}`}
                                  style={{ minWidth: column.minWidth }}
                                >
                                  {column.label}
                                </TableCell>
                              ))}
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {buildingTenantsDetail.map(tenant => (
                              <TableRow className={classes.tableBody}>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  align="left"
                                  valign="middle"
                                  style={{
                                    maxWidth: 300,
                                    minWidth: 280,
                                    width: 120,
                                    verticalAlign: 'middle',
                                  }}
                                >
                                  <div>{tenant.tenantName}</div>
                                </TableCell>
                                <TableCell>
                                  <FormControl className={classes.formControl}>
                                    <Input
                                      className={classes.formSelectBox}
                                      name="mobAccess"
                                      id={
                                        tenant.tenantBuildingMobileAccess ===
                                          false ||
                                        typeof tenant.tenantBuildingMobileAccess ===
                                          'undefined'
                                          ? 'mobileAccessBlocked'
                                          : null
                                      }
                                      value={
                                        tenant.tenantBuildingMobileAccess
                                          ? 'Allowed'
                                          : 'Blocked'
                                      }
                                      readOnly
                                    />
                                  </FormControl>
                                </TableCell>
                                <TableCell>
                                  <div className={`${classes.addUserFormInp}`}>
                                    <div className={classes.clearanceInpBody}>
                                      <ul
                                        className={`${classes.InputSectionInp}`}
                                      >
                                        <li className={`${classes.Clearance}`}>
                                          <Input
                                            className="InputField"
                                            name="clearanceInput"
                                            placeholder={
                                              tenant.tenantBuildingMobileAccess ===
                                                false ||
                                              tenant.tenantBuildingMobileAccess ===
                                                undefined
                                                ? 'No Group Selected'
                                                : ''
                                            }
                                            type="text"
                                            value={
                                              tenant.tenantBuildingClearanceControl
                                            }
                                            readOnly
                                          />
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    ) : (
                      <div className="tenantNotFound">
                        <div className="noTenantResult">
                          <h2>NO TENANT FOUND</h2>
                          <p>No Tenants in this building.</p>
                        </div>
                      </div>
                    )}
                  </Paper>
                ) : (
                  ''
                )}
              </div>
              <Divider className="formDivider" light />
              <ul className="offerFormList">
                <li>
                  <FormLabel component="legend">Media</FormLabel>
                </li>
              </ul>
              <li>
                <SpaceFiles
                  values={{
                    setSpaceFile,
                    spaceFile,
                    createSpace,
                    setCreateSpace,
                    setPreviewImage,
                    previewImage,
                    setSpaceVideo,
                    spaceVideo,
                    setFloorPlan,
                    floorPlan,
                    buildingLogo,
                    setBuildingLogo,
                    visitor,
                    setVisitor,
                    thumnail,
                    setThumnail,
                  }}
                  viewMedia
                  buildingAttributes
                />
              </li>
              <CloseButton route="/building-attributes" />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ViewBuildingAttributes;
