import React, { useContext, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import {
  Button,
  CircularProgress,
  Divider,
  Dialog,
  DialogActions,
  Select,
  MenuItem,
  Input,
  FormHelperText,
  RadioGroup,
  Radio,
  FormControlLabel,
} from '@material-ui/core';
import { useParams, useHistory } from 'react-router-dom';
import dowmArw from '../../images/down-arw.svg';
import ApiClient from '../../helpers/ApiClient';
import apiEndPoints from '../../helpers/apiEndPoints';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import { logOut } from '../../utils/logOut';
import { GlobalContext } from '../../context/GlobalContext';
import { viewBookingSchema } from '../../utils/formValidate';
import { getDayMonthYearFormat } from '../../utils/dateUtils';
import {
  BookingPeriod,
  bookingStatus,
  bookingTimeType,
  discount,
  payment,
  timeZone,
  minTurnAroundTime,
} from '../../utils/constants';

const useStyles = makeStyles({
  table: {
    maxWidth: '100%',
  },
  BookingStepForm: {
    margin: '0 auto',
    padding: '0 0 40px',
    '& ul': {
      display: 'flex',
      justifyContent: 'space-between',
      flexWrap: 'wrap',
      alignItems: 'flex-end',
      '& li': {
        listStyle: 'none',
        width: '46%',
        marginBottom: '22px',
      },
    },
  },
  formButton: {
    margin: '20px 0 32px !important',
    width: '100% !important',
    display: 'flex',
    justifyContent: 'flex-end',
    '& button': {
      height: '47px',
      lineHeight: 'normal',
      border: '1px solid #000',
      outline: 'none',
      background: '#000000',
      fontSize: '16px',
      fontWeight: 'bold',
      width: 'auto',
      padding: '0 45px',
      color: '#fff !important',
      marginLeft: '30px',
      cursor: 'pointer',
      fontFamily: ['Varta', 'sans-serif'].join(','),
      '&:hover': {
        background: '#000000',
      },
      '&:first-child': {
        background: 'transparent',
        color: '#000000 !important',
        border: '2px solid #000',
        padding: '0 32px',
      },
      // '&:nth-child(2)': {
      //   background: 'transparent',
      //   color: '#000000 !important',
      //   border: '2px solid #000',
      //   padding: '0 32px',
      // },
      '& extendBtn': {
        background: 'transparent',
        color: '#000000 !important',
        border: '2px solid #000',
        padding: '0 32px',
      },
    },
  },
  extendPopupFormButton: {
    '& button': {
      '&:first-child': {
        background: '#000',
        color: '#fff !important',
        border: '2px solid #000',
        padding: '0 32px',
        marginTop: 20,
      },
    },
  },
  cancelOrderButton: {
    margin: '20px 0 30px !important',
    '& button': {
      '&:first-child': {
        background: '#000',
        color: '#fff !important',
        border: '2px solid #000',
        padding: '0 32px',
      },
    },
  },
  cancelDialog: {
    textAlign: 'center',
    '& h2': {
      fontSize: '19px',
      fontWeight: 'bold',
      color: '#444444',
      marginBottom: '15px',
      fontFamily: ['Lato', 'sans-serif'].join(','),
    },
    '& p, & textarea': {
      fontSize: '16px',
      fontWeight: 'normal',
      color: '#444444',
      marginBottom: '15px',
      fontFamily: ['Lato', 'sans-serif'].join(','),
    },
  },

  pd_30: {
    padding: '30px',
  },
  pb_0: {
    paddingBottom: '0px',
  },
  wd_100: {
    width: '100%',
  },
  alignLeft: {
    textAlign: 'left',
  },
  cancellPopupButtons: {
    padding: '30px',
    paddingTop: '10px',
  },
  popupFormButton: {
    margin: '0 !important',
  },
  formLabel: {
    fontSize: '16px',
    fontWeight: 'normal',
    color: '#838384',
    transform: 'none',
    fontFamily: ['Varta', 'sans-serif'].join(','),
  },
  formSelectBox: {
    '& svg': {
      display: 'none',
    },
    '&:before': {
      display: 'none',
    },
    '&:after': {
      display: 'none',
    },
    '& > div': {
      height: '45px',
      lineHeight: '45px',
      padding: '0 15px !important',
      fontSize: '16px',
      color: '#444444',
      borderRadius: '3px !important',
      border: 'solid 0.5px #979797',
      marginTop: '10px',
      background: `transparent url(${dowmArw}) no-repeat 95% center !important`,
      backgroundSize: '10px',
      appearance: 'none',
      fontFamily: ['Varta', 'sans-serif'].join(','),
    },
  },
  formSelectBoxNodown: {
    '& > div': {
      background: ' transparent !important',
    },
  },
  InputSection: {
    '& li': {
      '& span': {
        display: 'block',
        fontSize: '16px',
        fontWeight: 'normal',
        color: '#838384',
        fontFamily: ['Varta', 'sans-serif'].join(','),
      },
      '& input': {
        height: '45px',
        lineHeight: '45px',
        padding: '0 15px !important',
        fontSize: '16px',
        color: '#444444',
        borderRadius: '3px',
        border: 'solid 0.5px #979797',
        marginTop: '10px',
        width: '100%',
        background: 'transparent',
        fontFamily: ['Varta', 'sans-serif'].join(','),
      },
      '& > div': {
        '& > div': {
          '&:before': {
            display: 'none',
          },
          '&:after': {
            display: 'none',
          },
        },
        width: '100%',
        '&:before': {
          display: 'none',
        },
        '&:after': {
          display: 'none',
        },
      },
    },
  },
  errorMsgForGuest: {
    color: '#f90000',
    width: '100%',
    fontSize: '13px',
    fontFamily: 'Varta sans-serif',
  },
});

function priceRow(qty, unit) {
  return qty * unit;
}

function createRow(desc, qty, unit, total, extendBookingEventType = '') {
  const price = priceRow(qty, unit);
  return { desc, qty, unit, price, total, extendBookingEventType };
}

const ViewBookedMeeting = () => {
  const classes = useStyles();
  const { id } = useParams();
  const history = useHistory();
  const {
    setExtendData,
    setExtendDiscountLable,
    setCustomerDetails,
    setMeetingRoomName,
    setExtendTime,
    extendTime,
    eventType,
    setEventType,
    isCatering,
  } = useContext(GlobalContext);
  const [bookingData, setBookingData] = useState('');
  const [startTime, setStartTime] = useState('');
  const [endTime, setEndTime] = useState('');
  const [totalAmount, setTotalAmount] = useState('');
  const [roomDiscount, setRoomDiscount] = useState('');
  const [roomDiscountLable, setRoomDiscountLable] = useState(discount);
  const [vat, setVat] = useState('');
  const [roomTotal, setRoomTotal] = useState('');
  const [customerFirstName, setCustomerFirstName] = useState('');
  const [customerLastName, setCustomerLastName] = useState('');
  const [tenantName, setTenantName] = useState('');
  const [customerEmail, setCustomerEmail] = useState('');
  const [customerPhone, setCustomerPhone] = useState('');
  const [notes, setNotes] = useState('');
  const [loading, setLoading] = useState(false);
  const [complimentaryMenu, setComplimentaryMenu] = useState('');
  const [listData, setListData] = useState({});
  const [cateringDataPayment, setCateringDataPayment] = useState('');
  const [totalAmountWithVat, setTotalAmountWithVat] = useState('');
  const [mrBookingStatus, setMRBookingStatus] = useState('');
  const [warningPopUp, setWarningPopUp] = useState(false);
  const [warningPopUpSelected, setWarningPopUpSelected] = useState(false);
  const [staffNotes, setStaffNotes] = useState('');
  const [staffNotes1, setStaffNotes1] = useState([]);
  const [presentDate, setPresentDate] = useState('');
  const [bookingInvoice, setBookingInvoice] = useState('');
  const [cateringInvoice, setCateringInvoice] = useState('');
  const [extendTotalWithVat, setExtendTotalWithVat] = useState('');
  const [extend, setExtend] = useState('');
  const [extend2, setExtend2] = useState('');
  const [bookingDate, setBookingDate] = useState('');
  const [voucherDiscount, setVoucherDiscount] = useState('');
  const [open, setOpen] = React.useState(false);
  const [openPopUp, setOpenPopUp] = React.useState(false);
  const [guestEmail, setGuestEmail] = useState('');
  const [line, setLine] = useState([]);
  const [guestListRequestedOn, setGuestListRequestedOn] = useState();
  const [firstName, setFirstName] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const [capacity, setCapacity] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [hostName, setHostName] = useState('');
  const [guestListName, setGuestListName] = useState([]);
  const [cancelledOn, setCancelledOn] = useState('');
  const [cancellationReason, setCancellationReason] = useState('');
  const [cancellPopup, setCancellPopup] = useState(false);
  const [wordCountError, setWordCountError] = useState('');
  const handleClickOpen = () => {
    setExtendTime('');
    setOpen(true);
  };
  const handleRequestListPopUp = () => {
    setOpenPopUp(true);
  };
  const MakePaymentClick = extendBookingEventType => {
    setEventType(extendBookingEventType);
    history.push('/order-preview/');
  };

  const errorType = errorMsg && errorMsg.type;
  const errorFieldName = errorMsg && errorMsg.path;
  const errorFieldValue = errorMsg && errorMsg.value;

  const returnExtendBookingRow = extendData => {
    const row = extendRows.find(
      item => item.extendBookingEventType === extendData?.eventType,
    );
    return (
      <TableRow key={row?.desc}>
        <TableCell>{row?.desc}</TableCell>
        <TableCell align="left">{row?.qty}</TableCell>
        <TableCell align="right">{row?.unit}</TableCell>
        <TableCell align="right">£{extendData?.roomTotal}</TableCell>
      </TableRow>
    );
  };
  const getMeetingRoomData = () => {
    setLoading(true);
    ApiClient.apiGet(`${apiEndPoints.createMrBooking}/${id}`)
      .then(data => {
        setLoading(false);
        const mrData = data && data.data && data.data.data;
        setBookingData(mrData);
        setHostName(mrData?.hostName || '');
        setGuestListRequestedOn(mrData?.guestListCompletedOn);
        setCapacity(
          mrData?.roomLayout?.maxLayoutCapacity ||
            mrData?.meetingRoom?.capacity ||
            '',
        );
        setGuestEmail(mrData?.customer?.email);
        setFirstName(mrData?.customer?.firstName || '');
        setLine((mrData?.guestList && mrData?.guestList.join('\n')) || '');
        setExtendData(mrData && mrData);
        setEventType(
          mrData?.extendBooking?.eventType === bookingTimeType.futureBooking ||
            mrData?.extendBooking2?.eventType === bookingTimeType.futureBooking
            ? bookingTimeType.pastBooking
            : bookingTimeType.futureBooking,
        );

        setExtend(mrData?.extendBooking);
        setExtend2(mrData?.extendBooking2);
        setBookingDate(mrData?.createdDate);
        setExtendTotalWithVat(mrData?.extendBooking?.totalWithVat);

        setExtendDiscountLable(
          mrData?.extendBooking?.discountLable || discount,
        );
        setVoucherDiscount(mrData && mrData.discount && mrData.discount.amount);
        setMeetingRoomName(
          mrData && mrData.meetingRoom && mrData.meetingRoom.name,
        );
        setCustomerDetails(mrData && mrData.customer);
        setMRBookingStatus(mrData && mrData.status);
        setCateringDataPayment(mrData && mrData.payment);

        setListData(mrData.cateringOrder);

        setStartTime(mrData && mrData.startDate);
        setEndTime(mrData && mrData.endDate);
        setTotalAmount(mrData && mrData.payment && mrData.payment.total);
        setRoomDiscount(
          mrData && mrData.payment && mrData.payment.roomDiscount,
        );
        setRoomDiscountLable(mrData?.payment?.roomDiscountLable || discount);
        setVat(mrData && mrData.payment && mrData.payment.vatRate);
        setRoomTotal(mrData && mrData.payment && mrData.payment.roomTotal);
        setTotalAmountWithVat(
          mrData && mrData.payment && mrData.payment.totalWithVat,
        );
        setCustomerFirstName(
          mrData && mrData.customer && mrData.customer.firstName,
        );
        setCustomerLastName(
          mrData && mrData.customer && mrData.customer.lastName,
        );
        setTenantName(mrData && mrData.customer && mrData.customer.tenantName);
        setCustomerEmail(mrData && mrData.customer && mrData.customer.email);
        setCustomerPhone(
          (mrData && mrData.customer && mrData.customer.phone) || '',
        );
        setNotes(mrData && mrData.notes);
        setStaffNotes1(mrData.staffNotes);
        setPresentDate(mrData.startDate);
        setBookingInvoice(mrData && mrData.bookingInvoice);
        setCateringInvoice((mrData && mrData.cateringInvoice) || '');
        setComplimentaryMenu(mrData && mrData.complimentaryCatering);
        setCancelledOn(mrData?.cancelledOn);
        setCancellationReason(mrData?.cancellationReason || '');
      })
      .catch(error => {
        setErrorMsg(error);
        setLoading(false);
      });
  };

  const handleClose = () => {
    if (!extendTime || extendTime === '') {
      alert('Please select extend time');
    } else {
      setOpen(false);
      history.push('/order-preview/');
    }
  };

  const handleDialogClose = () => {
    setOpen(false);
    setOpenPopUp(false);
    setExtendTime('');
  };
  const handleSendMail = () => {
    viewBookingSchema
      .validate({
        firstName,
        guestEmail,
      })
      .then(() => {
        ApiClient.apiPost(`${apiEndPoints.requestGuestList}/${id}`, {
          email: guestEmail,
          firstName: firstName,
        })
          .then(() => {
            setOpenPopUp(false);
          })
          .catch(error => {
            setErrorMsg(error);
          });
      })
      .catch(error => {
        setErrorMsg(error);
      });
  };

  const cancelHandleSendMail = () => {
    setOpenPopUp(false);
  };

  const handleTextChange = event => {
    const text = event.target.value;
    const lines = text.split('\n').filter(value => value.trim() !== '');
    if (lines.length >= capacity + 1) {
      setErrorMessage(`Maximum Guest limit is ${capacity}.`);
    } else {
      setErrorMessage('');
      setLine(text);
    }
    setGuestListName(lines);
  };
  const handleKeyPress = event => {
    if (line === '' && event.key === 'Enter') {
      event.preventDefault();
    }
  };

  const handleSaveButton = () => {
    setErrorMessage('');
    if (line.length !== 0) {
      ApiClient.apiPost(`${apiEndPoints.saveGuestList}/${id}`, {
        guestList: guestListName,
      })
        .then(() => {
          getMeetingRoomData();
        })
        .catch(error => {
          setErrorMsg(error);
        });
    } else {
      setErrorMessage('Guestlist Is Required');
    }
  };
  const cancelCatering = () => {
    setLoading(true);
    ApiClient.apiPut(`${apiEndPoints.cancelCatering}/${id}/cancelcatering`)
      .then(() => {
        setLoading(false);
        history.push('/');
      })
      .catch(error => {
        const err = error;
        alert(err.response.data.message);
        setLoading(false);
      });
  };
  const onNotesChange = e => setStaffNotes(e.target.value);
  const Discount =
    (roomDiscount === 0 && roomDiscount + voucherDiscount) || roomDiscount;
  const handleSubmit = () => {
    ApiClient.apiPost(`${apiEndPoints.staffNotes}/${id}`, {
      message: staffNotes,
    })
      // eslint-disable-next-line no-unused-vars
      .then(data => {
        setStaffNotes(staffNotes);
        getMeetingRoomData();
        setStaffNotes('');
        setLoading(false);
      })
      .catch(error => {
        const err = error;
        alert(err.response.data.message);
        setLoading(false);
      });
  };

  useEffect(() => {
    getMeetingRoomData();
    setErrorMessage('');
  }, []);

  const stausCall = value => {
    setLoading(true);
    const payload = {
      status: value,
      cancellationReason:
      value === bookingStatus.cancel ? cancellationReason : undefined,
    };
    ApiClient.apiPut(`${apiEndPoints.createMrBooking}/${id}/status`, payload)
      .then(() => {
        setLoading(false);
        cancellPopupClose();
        history.push('/');
      })
      .catch(error => {
        const err = error;
        alert(err.response.data.message);
        setLoading(false);
        cancellPopupClose();
      });
  };
  const statusCallExtend = (value, extendEventType) => {
    setLoading(true);
    const payload = {
      status: value,
      eventType: extendEventType,
    };
    ApiClient.apiPut(`${apiEndPoints.cancelExtend}/${id}`, payload)
      .then(() => {
        setLoading(false);
        history.push('/');
      })
      .catch(error => {
        const err = error;
        alert(err.response.data.message);
        setLoading(false);
      });
  };
  const handleCancellationReasonChange = e => {
    const text = e.target.value;
    const words = text.trim().split(/\s+/); // Splits by spaces, ignoring extra spaces
    const wordLimit = 50; // Set your word limit here
    if (words.length > wordLimit) {
      setWordCountError(
        `Word limit exceeded! You can only enter up to ${wordLimit} words.`,
      );
    } else if (!text.trim().length) {
      setCancellationReason('');
    } else {
      setWordCountError(''); // Clear error if within limit
      setCancellationReason(text);
    }
  };
  const disableButtons = () => {
    return (
      mrBookingStatus === bookingStatus.cancel ||
      mrBookingStatus === bookingStatus.cancelAndRefund ||
      mrBookingStatus === bookingStatus.noShow
    );
  };
  const cancellPopupClose = () => {
    setCancellPopup(false);
    setCancellationReason('');
  };
  useEffect(() => {
    if (warningPopUpSelected) {
      stausCall(bookingStatus.noShow);
    }
  }, [warningPopUpSelected]);

  const popUpOpen = () => setWarningPopUp(true);
  const popUpClose = () => {
    setWarningPopUp(false);
  };

  //* get main bookings start, end times for rows description
  const getMainBookingStartEndTime = () => {
    let startTimeMainBooking = bookingData?.startDate;
    let endTimeMainBooking = bookingData?.endDate;
    if (bookingData?.extendBooking?.eventType === bookingTimeType.pastBooking)
      startTimeMainBooking = bookingData?.extendBooking?.endDate;
    else if (
      bookingData?.extendBooking2?.eventType === bookingTimeType.pastBooking
    )
      startTimeMainBooking = bookingData?.extendBooking2?.endDate;
    if (bookingData?.extendBooking?.eventType === bookingTimeType.futureBooking)
      endTimeMainBooking = bookingData?.extendBooking?.startDate;
    else if (
      bookingData?.extendBooking2?.eventType === bookingTimeType.futureBooking
    )
      endTimeMainBooking = bookingData?.extendBooking2?.startDate;
    return { startTimeMainBooking, endTimeMainBooking };
  };
  const { startTimeMainBooking, endTimeMainBooking } =
    getMainBookingStartEndTime();

  //* create main booking row
  const mainRow = createRow(
    `${bookingData?.meetingRoom?.name}  -  ${
      bookingData?.roomLayout?.name
    }  -   ${bookingData?.building?.name}   -  ${moment
      .utc(bookingData?.startDate)
      .format('DD MMM YYYY')}  -  ${moment
      .tz(startTimeMainBooking, bookingTimeType.zone)
      .format('h:mm a')} - ${moment
      .tz(endTimeMainBooking, bookingTimeType.zone)
      .format('h:mm a')}  -  ${
      bookingData?.roomLayout?.turnAroundTime || minTurnAroundTime
    } Min`,
    '__',
    '__',
    `${Number(bookingData?.payment?.roomTotal).toFixed(2)}`,
  );

  const extendRows = [];
  //* extendbooking1 row
  if (bookingData?.extendBooking)
    extendRows.push(
      createRow(
        `${bookingData?.meetingRoom?.name}  -  ${
          bookingData?.roomLayout?.name
        }  -   ${bookingData?.building?.name}   -  ${moment
          .utc(bookingData?.extendBooking?.startDate)
          .format('DD MMM YYYY')}  -  ${` ${moment
          .tz(bookingData?.extendBooking?.startDate, bookingTimeType.zone)
          .format('h:mm a')} - ${moment
          .tz(bookingData?.extendBooking?.endDate, bookingTimeType.zone)
          .format('h:mm a')} `}  -  ${
          bookingData?.roomLayout?.turnAroundTime || minTurnAroundTime
        } Min`,
        '__',
        '__',
        `${Number(bookingData?.extendBooking?.roomTotal).toFixed(2)}`,
        bookingData?.extendBooking?.eventType,
      ),
    );
  //* extendbooking2 row
  if (bookingData?.extendBooking2)
    extendRows.push(
      createRow(
        `${bookingData?.meetingRoom?.name}  -  ${
          bookingData?.roomLayout?.name
        }  -   ${bookingData?.building?.name}   -  ${moment
          .utc(bookingData?.extendBooking2?.startDate)
          .format('DD MMM YYYY')}  -  ${` ${moment
          .tz(bookingData?.extendBooking2?.startDate, bookingTimeType.zone)
          .format('h:mm a')} - ${moment
          .tz(bookingData?.extendBooking2?.endDate, bookingTimeType.zone)
          .format('h:mm a')} `} -  ${
          bookingData?.roomLayout?.turnAroundTime || minTurnAroundTime
        } Min`,
        '__',
        '__',
        `${Number(bookingData?.extendBooking2?.roomTotal).toFixed(2)}`,
        bookingData?.extendBooking2?.eventType,
      ),
    );
  const timeDiff = Math.abs(new Date(startTime) - new Date()) / 36e5;

  const cateringDetails = [createRow('No catering ordered')];

  const cancellCateringStatus = listData && listData.status;

  const breadCrumbs = {
    listBreadCrumbPath: '/',
    listBreadCrumbTitle: 'Meeting Room Bookings',
    viewBreadCrumbPath: '/view-booking/',
    viewBreadCrumbTitle: 'View booking',
  };

  if (!(localStorage && localStorage.getItem('adal.session.state'))) {
    logOut();
  }

  const allExtends = [extend, extend2];

  return (
    <div className={classes.addUserFormBody}>
      <div className="pageBredcrumbTimer">
        <Breadcrumb breadCrumbs={breadCrumbs} />
      </div>
      <Divider light />
      <div className="pageContainer BookingStepContainer">
        {loading ? (
          <CircularProgress color="inherit" />
        ) : (
          <div className={`${classes.BookingStepForm}`}>
            <div className="cancelShowBtns">
              <ul>
                <li className={classes.formButton}>
                  <Button
                    className={classes.extendBtn}
                    onClick={handleClickOpen}
                    disabled={
                      timeDiff >= BookingPeriod.extendBookingTime ||
                      (extend && extend2) ||
                      new Date(startTime) < new Date().setHours(0, 0, 0, 0) ||
                      disableButtons()
                    }
                  >
                    Extend
                  </Button>
                  <Button
                    disabled={
                      new Date(startTime) < new Date() || disableButtons()
                    }
                    onClick={() => {
                      setCancellPopup(true);
                    }}
                  >
                    {cateringDataPayment?.status === payment.paid
                      ? 'Cancel and Refund'
                      : 'Cancel All'}
                  </Button>
                  <Button
                    disabled={
                      new Date(startTime) < new Date() || disableButtons()
                    }
                    onClick={() => {
                      popUpOpen();
                    }}
                  >
                    No Show
                  </Button>
                  <div className="extendPopup">
                    <Dialog
                      open={open}
                      onClose={handleDialogClose}
                      aria-labelledby="alert-dialog-title"
                      aria-describedby="alert-dialog-description"
                      className="cancelDialogOpend extendPopupDialogOpend pb_30 width_45p width_100p"
                    >
                      <ul className={`${classes.InputSection} offerTimeCat`}>
                        <li className={`${classes.InputSection} mb_22`}>
                          <RadioGroup
                            aria-label="Active"
                            name="Available"
                            defaultValue="Free"
                            value={eventType}
                            onChange={e => {
                              setEventType(e.target.value);
                            }}
                          >
                            <div
                              className={`${classes.radioSection} radioSection`}
                            >
                              <FormControlLabel
                                control={<Radio />}
                                label="Extend After Meeting End Time"
                                checked={
                                  eventType === bookingTimeType.futureBooking
                                }
                                disabled={
                                  bookingData?.extendBooking?.eventType ===
                                    bookingTimeType.futureBooking ||
                                  bookingData?.extendBooking2?.eventType ===
                                    bookingTimeType.futureBooking
                                }
                                value={bookingTimeType.futureBooking}
                              />
                              <FormControlLabel
                                control={<Radio />}
                                label="Extend Before Meeting Start Time"
                                checked={
                                  eventType === bookingTimeType.pastBooking
                                }
                                disabled={
                                  bookingData?.extendBooking?.eventType ===
                                    bookingTimeType.pastBooking ||
                                  bookingData?.extendBooking2?.eventType ===
                                    bookingTimeType.pastBooking
                                }
                                value={bookingTimeType.pastBooking}
                              />
                            </div>
                          </RadioGroup>
                        </li>
                        <li className={`${classes.InputSection} `}>
                          <span>Extended Time*</span>
                          <Select
                            className={classes.formSelectBox}
                            MenuProps={{
                              anchorOrigin: {
                                vertical: 'bottom',
                                horizontal: 'left',
                              },
                              getContentAnchorEl: null,
                            }}
                            onChange={e => {
                              setExtendTime(e.target.value);
                            }}
                          >
                            <MenuItem value="15">15</MenuItem>
                            <MenuItem value="30">30</MenuItem>
                            <MenuItem value="45">45</MenuItem>
                            <MenuItem value="60">60</MenuItem>
                            <MenuItem value="75">75</MenuItem>
                            <MenuItem value="90">90</MenuItem>
                            <MenuItem value="105">105</MenuItem>
                            <MenuItem value="120">120</MenuItem>
                            <MenuItem value="135">135</MenuItem>
                            <MenuItem value="150">150</MenuItem>
                            <MenuItem value="165">165</MenuItem>
                            <MenuItem value="180">180</MenuItem>
                            <MenuItem value="195">195</MenuItem>
                            <MenuItem value="210">210</MenuItem>
                            <MenuItem value="225">225</MenuItem>
                            <MenuItem value="240">240</MenuItem>
                          </Select>
                        </li>
                      </ul>
                      <DialogActions
                        className={`${classes.formButton} ${classes.popupFormButton} ${classes.extendPopupFormButton}`}
                      >
                        <Button onClick={handleClose} autoFocus>
                          Ok
                        </Button>
                      </DialogActions>
                    </Dialog>
                  </div>
                  <div className="extendPopup">
                    <Dialog
                      open={openPopUp}
                      onClose={handleDialogClose}
                      aria-labelledby="alert-dialog-title"
                      aria-describedby="alert-dialog-description"
                      className="cancelDialogOpend extendPopupDialogOpend"
                    >
                      <ul
                        style={{ marginBottom: '20px' }}
                        className={`${classes.InputSection} offerTimeCat`}
                      >
                        <li className={`${classes.InputSection} `}>
                          <span>
                            First Name* (Email will be addressed to this name)
                          </span>
                          <Input
                            className="textareaSmallBox"
                            placeholder="Enter first name"
                            value={firstName}
                            onChange={e => {
                              const re = /^[A-Za-z0-9.@]+$/;
                              if (
                                e.target.value === '' ||
                                re.test(e.target.value)
                              ) {
                                setFirstName(e.target.value);
                              }
                            }}
                          />
                          {errorFieldValue && errorFieldValue.firstName === '' && (
                            <FormHelperText
                              style={{ color: 'red' }}
                              className={classes.errorMsg}
                              id="firstName"
                            >
                              {firstName === '' && 'First Name* is required.'}
                            </FormHelperText>
                          )}
                        </li>
                      </ul>
                      <ul className={`${classes.InputSection} offerTimeCat`}>
                        <li className={`${classes.InputSection} `}>
                          <span>Email*</span>
                          <Input
                            className="textareaSmallBox"
                            placeholder="Enter Email"
                            value={guestEmail}
                            onChange={e => {
                              setGuestEmail(e.target.value);
                            }}
                          />
                          {((errorType === 'required' &&
                            errorFieldName === 'guestEmail') ||
                            (errorType === 'email' &&
                              errorFieldName === 'guestEmail') ||
                            (errorFieldValue &&
                              errorFieldValue.guestEmail === '')) && (
                            <FormHelperText
                              style={{ color: 'red' }}
                              className={classes.errorMsg}
                              id="guestEmail"
                            >
                              {guestEmail === ''
                                ? 'Email* is required.'
                                : errorType === 'email' &&
                                  'Please enter a valid Email.'}
                            </FormHelperText>
                          )}
                        </li>
                      </ul>
                      <DialogActions
                        className={`${classes.formButton} popupFormButton`}
                      >
                        <Button onClick={cancelHandleSendMail}>Cancel</Button>
                        <Button onClick={handleSendMail} autoFocus>
                          Send
                        </Button>
                      </DialogActions>
                    </Dialog>
                  </div>
                  <Dialog
                    open={warningPopUp}
                    onClose={popUpClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    className="cancelDialogOpend"
                  >
                    <div
                      className={`${classes.cancelDialog} meetingRCancelDialog`}
                    >
                      <h2>Are you sure you want to mark this as a no show?</h2>
                      <p>The room and time slot will become available again.</p>
                    </div>
                    <Divider className="formDivider popupDivider" light />
                    <DialogActions
                      className={`${classes.formButton} ${classes.popupFormButton} popupFormButton`}
                    >
                      <Button
                        onClick={() => {
                          setWarningPopUp(false);
                        }}
                        color="primary"
                      >
                        No, go back
                      </Button>
                      <Button
                        onClick={() => {
                          setWarningPopUpSelected(true);
                          popUpClose();
                        }}
                        autoFocus
                      >
                        Yes, cancel
                      </Button>
                    </DialogActions>
                  </Dialog>
                </li>
              </ul>
            </div>
            <div className="orderMainTable">
              <TableContainer component={Paper} className="orderTable">
                <Table className={classes.table} aria-label="spanning table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Meeting Room</TableCell>
                      <TableCell align="left">Qty</TableCell>
                      <TableCell align="right">Price </TableCell>
                      <TableCell align="right">Total</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody className="orderPCont">
                    <TableRow key={mainRow.desc}>
                      <TableCell>{mainRow.desc}</TableCell>
                      <TableCell align="left">{mainRow.qty}</TableCell>
                      <TableCell align="right">{mainRow.unit}</TableCell>
                      <TableCell align="right">
                        £{mainRow && mainRow.total}
                      </TableCell>
                    </TableRow>
                    {complimentaryMenu &&
                      complimentaryMenu.map(complimentaryItems => (
                        <TableRow key={complimentaryItems.categoryId}>
                          <TableCell>{`${
                            complimentaryItems &&
                            complimentaryItems.menu &&
                            complimentaryItems.menu
                              .map(menuItem => menuItem.itemName)
                              .toString()
                              .split(',')
                              .join(', ')
                          }`}</TableCell>
                          <TableCell align="left">
                            {complimentaryItems.capacity}
                          </TableCell>
                          <TableCell align="right">{(0).toFixed(2)}</TableCell>
                          <TableCell align="right">£{(0).toFixed(2)}</TableCell>
                        </TableRow>
                      ))}
                  </TableBody>

                  <TableBody className="orderPBreakdown">
                    <TableRow className="orderDiscount">
                      <TableCell align="left">&nbsp;</TableCell>
                      <TableCell align="left">
                        <span>{roomDiscountLable}</span>
                      </TableCell>
                      <TableCell colSpan="2" align="right">
                        {roomDiscountLable === discount
                          ? `-£${Number(Math.min(roomTotal, Discount)).toFixed(
                              2,
                            )}`
                          : `+£${Number(Discount).toFixed(2)}`}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="left">&nbsp;</TableCell>
                      <TableCell align="left">Subtotal</TableCell>
                      <TableCell colSpan="2" align="right">
                        £{Number(totalAmount).toFixed(2)}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="left">&nbsp;</TableCell>
                      <TableCell align="left">VAT @ {vat}%</TableCell>
                      <TableCell colSpan="2" align="right">
                        £{Number(totalAmountWithVat - totalAmount).toFixed(2)}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="left">&nbsp;</TableCell>
                      <TableCell align="left">Room Total</TableCell>
                      <TableCell colSpan="2" align="right">
                        £{Number(totalAmountWithVat).toFixed(2)}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
            <div className="orderDateInvoice">
              <div>
                Booking made on{' '}
                <span>
                  {moment(bookingDate)
                    .tz(bookingTimeType.zone)
                    .format('DD/MM/YYYY hh:mm a')}
                </span>
              </div>
              {bookingInvoice && bookingInvoice.url ? (
                <div className="roomCoctsUnderline">
                  <a rel="noreferrer" href={bookingInvoice.url} target="_blank">
                    Meeting room invoice
                  </a>
                </div>
              ) : (
                ''
              )}
            </div>
            <div className="orderNotes">
              Notes:
              <span>{notes}</span>
            </div>
            {moment().isAfter({ presentDate }) ? (
              <div className="orderNotes staffNotes">
                Staff Notes:
                <span style={{ whiteSpace: 'pre' }}>
                  {staffNotes1 &&
                    staffNotes1.map(note => (
                      <div key={note.name}>
                        {note.name}[
                        {moment(note.timeStamp)
                          .tz(bookingTimeType.zone)
                          .format('DD MMM YYYY hh:mm a')}
                        ]&nbsp;-&nbsp;{note.notes}
                      </div>
                    ))}
                </span>
                <textarea value={staffNotes} onChange={onNotesChange} />
                <br />
                <Button
                  type="submit"
                  disabled={!staffNotes}
                  onClick={handleSubmit}
                >
                  Submit
                </Button>
              </div>
            ) : (
              ''
            )}
            {isCatering ? (
              <div className="cateringMainTable">
                <div className="cancelShowBtns">
                  <ul>
                    <li
                      className={`${classes.formButton} ${classes.cancelOrderButton}`}
                    >
                      <Button
                        disabled={
                          (listData &&
                            listData.orderMenu &&
                            listData.orderMenu.length === 0) ||
                          new Date(startTime) < new Date() ||
                          cancellCateringStatus === bookingStatus.cancel ||
                          cateringDataPayment.method === 'Pos'
                        }
                        onClick={() => {
                          cancelCatering();
                        }}
                      >
                        Cancel Order
                      </Button>
                    </li>
                  </ul>
                </div>
                <TableContainer component={Paper} className="orderTable">
                  <Table className={classes.table} aria-label="spanning table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Catering</TableCell>
                        {listData &&
                          listData.orderMenu &&
                          listData.orderMenu.length > 0 && (
                            <>
                              <TableCell align="left">Qty</TableCell>
                              <TableCell align="right">Price </TableCell>
                              <TableCell align="right">Total</TableCell>
                            </>
                          )}
                      </TableRow>
                    </TableHead>
                    {(!listData ||
                      (listData &&
                        listData.orderMenu &&
                        listData.orderMenu.length === 0)) &&
                      cateringDetails.map(cateringDetail => (
                        <TableRow key={cateringDetail.desc}>
                          <TableCell>{cateringDetail.desc}</TableCell>
                        </TableRow>
                      ))}
                    {listData &&
                      listData.orderMenu &&
                      listData.orderMenu.map(orderMenuItems => (
                        <TableBody
                          className="orderPCont"
                          key={orderMenuItems.categoryId}
                        >
                          {orderMenuItems &&
                            orderMenuItems.orderItems.map(orderItem => (
                              <TableRow key={orderItem.itemId}>
                                <TableCell>{orderItem.itemName}</TableCell>
                                <TableCell align="left">
                                  {orderItem.quantity}
                                </TableCell>
                                <TableCell align="right">
                                  £{orderItem.price.toFixed(2)}
                                </TableCell>
                                <TableCell align="right">
                                  £
                                  {Number(
                                    orderItem.price * orderItem.quantity,
                                  ).toFixed(2)}
                                </TableCell>
                              </TableRow>
                            ))}
                        </TableBody>
                      ))}
                    {listData &&
                      listData.orderMenu &&
                      listData.orderMenu.length !== 0 && (
                        <TableBody className="orderPBreakdown">
                          <TableRow>
                            <TableCell align="left">&nbsp;</TableCell>
                            <TableCell align="left">Subtotal</TableCell>
                            <TableCell colSpan={2} align="right">
                              £
                              {listData &&
                                listData.totalPrice &&
                                listData.totalPrice.toFixed(2)}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell align="left">&nbsp;</TableCell>
                            <TableCell align="left">
                              VAT @ {listData && listData.vat}%
                            </TableCell>
                            <TableCell colSpan={2} align="right">
                              £
                              {(
                                Number(listData && listData.totalWithVat) -
                                Number(listData && listData.totalPrice)
                              ).toFixed(2)}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell align="left">&nbsp;</TableCell>
                            <TableCell align="left">Catering Total</TableCell>
                            <TableCell colSpan={2} align="right">
                              £
                              {(
                                listData &&
                                listData.totalPrice &&
                                listData.totalWithVat
                              ).toFixed(2)}
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      )}
                  </Table>
                </TableContainer>
                <div className="orderDateInvoice">
                  <div>
                    Booking made on{' '}
                    <span>
                      {moment(bookingDate)
                        .tz(bookingTimeType.zone)
                        .format('DD/MM/YYYY hh:mm a')}
                    </span>
                  </div>
                  <div className="roomCoctsUnderline">
                    {(cateringInvoice.url && (
                      <a
                        rel="noreferrer"
                        href={cateringInvoice.url}
                        target="_blank"
                      >
                        Catering invoice
                      </a>
                    )) ||
                      ''}
                  </div>
                </div>
              </div>
            ) : (
              ''
            )}
            <div className="customerDtls">
              <h2>Customer Details</h2>
              <p>
                {`${customerFirstName} ${customerLastName} - ${tenantName} -
                ${customerEmail} ${
                  customerPhone ? ` - ${customerPhone}` : ''
                } `}
              </p>
            </div>
            <div className="GuestList">
              <div>
                <span>Meeting Room Guestlist</span>
                <Button
                  style={{ backgroundColor: 'black', color: 'black' }}
                  disabled={
                    new Date(endTime) < new Date() ||
                    guestListRequestedOn ||
                    disableButtons()
                  }
                  className="requestguestlistbtn"
                  onClick={handleRequestListPopUp}
                >
                  <span style={{ color: 'white', textTransform: 'none' }}>
                    Request Guestlist
                  </span>
                </Button>
              </div>
              <textarea
                disabled={new Date(endTime) < new Date()}
                value={line}
                placeholder="Please add each name on a new line"
                onChange={handleTextChange}
                onKeyPress={handleKeyPress}
              />
              {errorMessage && (
                <p className={`${classes.errorMsgForGuest}`}>{errorMessage}</p>
              )}
              {hostName ? (
                <div>
                  Submitted By: {hostName} on{' '}
                  {moment(guestListRequestedOn)
                    .tz(bookingTimeType.zone)
                    .format('DD/MM/YYYY hh:mm a')}
                </div>
              ) : (
                ''
              )}
              <Button
                style={{
                  backgroundColor: 'black',
                  color: '#ffffff',
                  textTransform: 'none',
                }}
                className="savebtnforguestlist"
                onClick={handleSaveButton}
                disabled={new Date(endTime) < new Date() || disableButtons()}
              >
                Save
              </Button>
            </div>
            {(allExtends && (
              <div className="orderMainTable extendedOrderTable">
                <TableContainer component={Paper} className="orderTable">
                  <Table className={classes.table} aria-label="spanning table">
                    {allExtends.map((extendData, index) => {
                      if (extendData) {
                        return (
                          <>
                            <div className={`${classes.BookingStepForm}`}>
                              <div className="cancelShowBtns">
                                <ul>
                                  <li className={classes.formButton}>
                                    <Button
                                      className={classes.extendBtn}
                                      onClick={() =>
                                        MakePaymentClick(extendData?.eventType)
                                      }
                                      disabled={
                                        extendData.paymentStatus === 'Paid' ||
                                        disableButtons()
                                      }
                                    >
                                      Make payment
                                    </Button>
                                    <Button
                                      className={classes.extendBtn}
                                      disabled={
                                        (new Date(startTime) < new Date() &&
                                          extendData.eventType ===
                                            bookingTimeType.pastBooking) ||
                                        getDayMonthYearFormat(startTime) <
                                          getDayMonthYearFormat(
                                            new Date().toISOString(),
                                          ) ||
                                        disableButtons()
                                      }
                                      onClick={() => {
                                        statusCallExtend(
                                          bookingStatus.cancel,
                                          extendData.eventType,
                                        );
                                      }}
                                    >
                                      {extendData?.paymentStatus ===
                                      payment.paid
                                        ? 'Cancel And Refund Extend'
                                        : 'Cancel Extend'}
                                    </Button>
                                  </li>
                                </ul>
                              </div>
                            </div>
                            <TableContainer
                              component={Paper}
                              className="orderTable"
                            >
                              <Table
                                className={classes.table}
                                aria-label="spanning table"
                              >
                                <TableHead>
                                  <TableRow>
                                    <TableCell>
                                      Meeting Room ({`Extended ${index + 1}`})
                                    </TableCell>
                                    <TableCell align="left">Qty</TableCell>
                                    <TableCell align="right">Price </TableCell>
                                    <TableCell align="right">Total</TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody className="orderPCont">
                                  {returnExtendBookingRow(extendData)}
                                  {complimentaryMenu &&
                                    complimentaryMenu.map(
                                      complimentaryItems => (
                                        <TableRow
                                          key={complimentaryItems.categoryId}
                                        >
                                          <TableCell>{`${
                                            complimentaryItems &&
                                            complimentaryItems.menu &&
                                            complimentaryItems.menu
                                              .map(
                                                menuItem => menuItem.itemName,
                                              )
                                              .toString()
                                              .split(',')
                                              .join(', ')
                                          }`}</TableCell>
                                          <TableCell align="left">
                                            {complimentaryItems.capacity}
                                          </TableCell>
                                          <TableCell align="right">
                                            {(0).toFixed(2)}
                                          </TableCell>
                                          <TableCell align="right">
                                            £{(0).toFixed(2)}
                                          </TableCell>
                                        </TableRow>
                                      ),
                                    )}
                                </TableBody>

                                <TableBody className="orderPBreakdown">
                                  <TableRow className="orderDiscount">
                                    <TableCell align="left">&nbsp;</TableCell>
                                    <TableCell align="left">
                                      <span>
                                        {extendData?.discountLable || discount}
                                      </span>
                                    </TableCell>
                                    <TableCell colSpan="2" align="right">
                                      {(extendData.discountLable ||
                                        discount) === discount
                                        ? '-'
                                        : '+'}
                                      £{Number(extendData.discount).toFixed(2)}
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell align="left">&nbsp;</TableCell>
                                    <TableCell align="left">Subtotal</TableCell>
                                    <TableCell colSpan="2" align="right">
                                      £{Number(extendData.total).toFixed(2)}
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell align="left">&nbsp;</TableCell>
                                    <TableCell align="left">
                                      VAT @ {vat}%
                                    </TableCell>
                                    <TableCell colSpan="2" align="right">
                                      £
                                      {Number(
                                        extendData.totalWithVat -
                                          extendData.total,
                                      ).toFixed(2)}
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell align="left">&nbsp;</TableCell>
                                    <TableCell align="left">
                                      Room Total
                                    </TableCell>
                                    <TableCell colSpan="2" align="right">
                                      £
                                      {Number(extendData.totalWithVat).toFixed(
                                        2,
                                      )}
                                    </TableCell>
                                  </TableRow>
                                </TableBody>
                              </Table>
                            </TableContainer>
                            <div className="orderDateInvoice">
                              <div>
                                Booking made on{' '}
                                <span>
                                  {moment(extendData?.createdDate)
                                    .tz(bookingTimeType.zone)
                                    .format('DD/MM/YYYY hh:mm a')}
                                </span>
                              </div>
                              <div className="roomCoctsUnderline">
                                {(extendData?.extensionInvoice && (
                                  <a
                                    rel="noreferrer"
                                    href={extendData?.extensionInvoice}
                                    target="_blank"
                                  >
                                    Extend Meeting room invoice
                                  </a>
                                )) ||
                                  ''}
                              </div>
                            </div>
                          </>
                        );
                      }
                      return <></>;
                    })}
                  </Table>
                </TableContainer>
              </div>
            )) ||
              ''}
            {mrBookingStatus === bookingStatus.cancel && (
              <div className="customerDtls">
                <p>
                  Cancelled On:{' '}
                  {moment
                    .tz(cancelledOn, timeZone.london)
                    .format('DD/MM/YYYY hh:mm a')}
                </p>
                <p>Cancellation Reason: {cancellationReason}</p>
              </div>
            )}

            <div className="totalDtls">
              <h3> Total</h3>
              {(allExtends?.length && (
                <h5>
                  £
                  {listData
                    ? (
                        (cancellCateringStatus === bookingStatus.cancel
                          ? 0
                          : listData && listData.totalWithVat) +
                        Number(extendTotalWithVat + totalAmountWithVat)
                      ).toFixed(2)
                    : (
                        Number(allExtends?.[0]?.totalWithVat || 0) +
                        Number(allExtends?.[1]?.totalWithVat || 0) +
                        Number(totalAmountWithVat || 0)
                      ).toFixed(2)}
                </h5>
              )) || (
                <h5>
                  £
                  {listData
                    ? (
                        (cancellCateringStatus === bookingStatus.cancel
                          ? 0
                          : listData && listData.totalWithVat) +
                        Number(totalAmountWithVat)
                      ).toFixed(2)
                    : Number(totalAmountWithVat).toFixed(2)}
                </h5>
              )}
            </div>

            <Dialog
              open={cancellPopup}
              onClose={cancellPopupClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              className="cancelDialogWidth"
            >
              <div
                className={`${classes.cancelDialog} ${classes.pd_30}  ${classes.pb_0}  ${classes.alignLeft}`}
              >
                <p>Cancellation Reason*</p>
                <textarea
                  rows={5}
                  className={classes.wd_100}
                  placeholder="Enter reason for cancel"
                  value={cancellationReason}
                  onChange={handleCancellationReasonChange}
                />
                {wordCountError && (
                  <p style={{ color: 'red' }}>{wordCountError}</p>
                )}
              </div>
              <DialogActions
                className={`${classes.formButton}  ${classes.cancellPopupButtons}  ${classes.popupFormButton} popupFormButton`}
              >
                <Button
                  onClick={() => {
                    cancellPopupClose();
                  }}
                  color="primary"
                >
                  Close
                </Button>

                <Button
                  className="popupFormButtonLast"
                  onClick={() => stausCall(bookingStatus.cancel)}
                  disabled={Number(cancellationReason?.length) < 2}
                  autoFocus
                >
                  Ok
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        )}
      </div>
    </div>
  );
};
export default ViewBookedMeeting;
