import React from 'react';
import {
  Button,
  Checkbox,
  FormControlLabel,
  List,
  ListItem,
  Popover,
} from '@material-ui/core';
import useFilterStyles from '../../components/Styles/useFilterStyles';
import minus from '../../images/minus.svg';
import plus from '../../images/plus.svg';
import close from '../../images/close.svg';

const MobileAccessFilter = ({ values }) => {
  const classes = useFilterStyles();
  const {
    id,
    anchorEl,
    setAnchorEl,
    handleClose,
    selectedStatus,
    statuses,
    setStatuses,
    statusSearch,
    setStatusSearch,
    onCheckStatusButton,
    setOnCheckStatusButton,
  } = values;

  const newStatusOptions = (selected, key) => {
    const statusnew =
      statuses &&
      statuses.map(status => {
        if (status && status.key === key) {
          status.selected = selected;
        }
        return status;
      });
    setStatuses(statusnew);
  };

  const clearStatusIcon = () => {
    setStatuses([
      { label: 'Yes', key: true, selected: false },
      { label: 'No', key: false, selected: false },
    ]);
  };

  return (
    <>
      <li
        className={
          onCheckStatusButton === true ||
          (selectedStatus && selectedStatus.length > 0)
            ? `${classes.active} ${
                selectedStatus && selectedStatus.length > 0
                  ? classes.valueSelected
                  : ''
              }`
            : ''
        }
      >
        <Button
          aria-describedby={id}
          variant="contained"
          onClick={event => {
            setStatusSearch(true);
            setAnchorEl(event.currentTarget);
            setOnCheckStatusButton(true);
          }}
        >
          Mobile Access Site
          <small>
            <img src={plus} alt="plus" />
            <img src={minus} alt="minus" />
          </small>
        </Button>
        <i
          className="closeIconBtn"
          role="presentation"
          onClick={clearStatusIcon}
        >
          <img src={close} alt="close" />
        </i>
        <Popover
          id="status"
          open={statusSearch}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <div className="filterDropdown">
            <div className="filterDropdownin">
              <List className={classes.moreList}>
                <h4>Select Mobile Access Site</h4>
                {statuses &&
                  statuses.map(status => (
                    <ListItem key={status.key}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            name={status.label}
                            checked={status.selected}
                            onChange={e => {
                              newStatusOptions(e.target.checked, status.key);
                            }}
                          />
                        }
                        label={status.label}
                        value={status.label}
                      />
                    </ListItem>
                  ))}
              </List>
            </div>
          </div>
        </Popover>
      </li>
    </>
  );
};
export default MobileAccessFilter;
