// import * as React from 'react';
import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import {
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import { isMobile, isIOS } from 'react-device-detect';
import {
  SquarePaymentsForm,
  CreditCardInput,
  ApplePay,
  GooglePay,
} from 'react-square-web-payments-sdk';
import './FailedCard.css';
import axios from 'axios';
import apiEndPoints from '../../helpers/apiEndPoints';
import PaymentError from './PaymentError';
import { PaymentGlobalContext } from '../../context/PaymentGlobalContext';
import { SQUARE_APPLICATION_ID, SQUARE_LOCATION_ID } from '../../utils/config';
import countries from '../../utils/countries';
import DojoCardComponent from '../../components/DojoCardComponent/DojoCardComponent';
import { discount } from '../../utils/constants';

const Payments = () => {
  const [meetingRoomData, setMeetingRoomData] = useState([]);
  const [eventsBookingData, setEventsBookingData] = useState();
  const [details, setCardDetails] = useState([]);
  const [paymentDetails, setPaymentDetails] = useState('');
  const [meetingRoomName, setMeetingRoomName] = useState('');
  const [cateringOrder, setCateringOrder] = useState('');
  const [voucherDiscount, setVoucherDiscount] = useState('');
  const [roomDiscount, setRoomDiscount] = useState('');
  const [roomDiscountLable, setRoomDiscountLable] = useState(discount);
  const [loading, setLoading] = useState(true);
  const [customerDetails, setCustomerDetails] = useState('');
  const [verificationToken, setVerificationToken] = useState('');
  const [billingAddress, setBillingAddress] = useState('');
  const [billingAddress1, setBillingAddress1] = useState('');
  const [billingAddress2, setBillingAddress2] = useState('');
  const [city, setCity] = useState('');
  const [country, setCountry] = useState('GB');
  const {
    setSuccessMessage,
    failedPaymentError,
    setFailedPaymentError,
    paymentServiceProvider,
  } = useContext(PaymentGlobalContext);
  const history = useHistory();
  const fetchData = () => {
    const id = window.location.pathname.split('/')[2];
    const token = window.location.pathname.split('/')[3];
    if (window.location.pathname.split('/')[1] === 'payments') {
      axios
        .get(`${apiEndPoints.failedPayments}/${id}/${token}`)
        .then(data => {
          const resData = data && data.data && data.data.data;
          setLoading(false);
          setMeetingRoomData(data && data.data && data.data.data);
          setMeetingRoomName(resData.meetingRoom.name);
          setPaymentDetails(resData.payment);
          setRoomDiscount(resData.payment && resData.payment.roomDiscount);
          setRoomDiscountLable(resData?.payment?.roomDiscountLable || discount);
          setVoucherDiscount(resData.discount && resData.discount.amount);
          setCateringOrder(resData.cateringOrder);
          setCustomerDetails(resData.customer);
        })
        .catch(err => {
          setFailedPaymentError(
            err &&
              err.response &&
              err.response.data &&
              err.response.data.message,
          );
          setLoading(false);
        });
    } else {
      axios
        .get(`${apiEndPoints.getFailedEventDetail}/${id}/${token}`)
        .then(data => {
          const resData = data && data.data && data.data.data;
          setEventsBookingData(resData);
          setMeetingRoomName(resData.eventName);
          setPaymentDetails(resData.payment);
          setCustomerDetails({
            firstName: resData.firstName,
            Lastname: resData.lastName,
          });
          setFailedPaymentError('');
          setLoading(false);
        })
        .catch(err => {
          setFailedPaymentError(
            err &&
              err.response &&
              err.response.data &&
              err.response.data.message,
          );
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const Discount =
    (roomDiscount === 0 && roomDiscount + voucherDiscount) || roomDiscount;

  useEffect(() => {
    const id = window.location.pathname.split('/')[2];
    const token = window.location.pathname.split('/')[3];
    if (verificationToken) {
      setLoading(true);
      if (window.location.pathname.split('/')[1] === 'payments') {
        axios
          .post(`${apiEndPoints.failedPayments}/${id}/${token}`, {
            sourceId: details.token,
            verificationToken: verificationToken,
          })
          .then(data => {
            setSuccessMessage(data && data.data && data.data.message);
            setLoading(false);
            history.push('/payments/success');
          });
      } else {
        axios
          .post(`${apiEndPoints.failedEventsPayments}/${id}/${token}`, {
            sourceId: details.token,
            verificationToken: verificationToken,
          })
          .then(data => {
            setSuccessMessage(data && data.data && data.data.message);
            setLoading(false);
            history.push('/payments/success');
          });
      }
    }
  }, [verificationToken]);

  return (
    <div
      className={`${
        details && details.token
          ? 'failedPaymentBody cardBlur'
          : 'failedPaymentBody'
      }`}
    >
      {loading ? (
        <CircularProgress color="inherit" />
      ) : (
        !failedPaymentError && (
          <div className="failedPaymentCardBox">
            <div>
              <div className="orderMainTable FailedPTable">
                <h6>Order Summary</h6>
                <div className="failedOrderSumary">
                  <ul>
                    <li>
                      <span>{meetingRoomName}</span>
                      <small>
                        £
                        {Number(
                          paymentDetails?.roomTotal || paymentDetails?.total,
                        ).toFixed(2)}
                      </small>
                    </li>
                    <li>
                      <span>Vat @ {paymentDetails?.vatRate}%</span>
                      <small>
                        £
                        {Number(
                          paymentDetails?.totalWithVat - paymentDetails?.total,
                        ).toFixed(2)}
                      </small>
                    </li>
                    {Discount && Discount !== 0 ? (
                      <li>
                        <span>{roomDiscountLable}</span>
                        <small>
                          {roomDiscountLable === discount
                            ? `-£${Number(
                                Math.min(paymentDetails.roomTotal, Discount),
                              ).toFixed(2)}`
                            : `+£${Number(Discount).toFixed(2)}`}
                        </small>
                      </li>
                    ) : (
                      ''
                    )}
                  </ul>
                  {cateringOrder && cateringOrder.orderMenu.length > 0 && (
                    <ul>
                      <li>
                        <span>Food & Drinks</span>
                        <small>
                          £{Number(cateringOrder.totalPrice).toFixed(2)}
                        </small>
                      </li>
                      <li>
                        <span>Vat @ {cateringOrder.vat}%</span>
                        <small>
                          £
                          {Number(
                            cateringOrder.totalWithVat -
                              cateringOrder.totalPrice,
                          ).toFixed(2)}
                        </small>
                      </li>
                    </ul>
                  )}
                  <ul>
                    <li>
                      <span>Total</span>
                      <small>
                        {' '}
                        £
                        {Number(
                          meetingRoomData?.bookingCost ||
                            paymentDetails?.totalWithVat,
                        ).toFixed(2)}
                      </small>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="cardonlyPayment">
                <h6>Payment</h6>
                {/** based on payment service provider it will choose square or dojo card component */}
                {paymentServiceProvider ? (
                  <DojoCardComponent />
                ) : (
                  <>
                    <div className="billingAddress">
                      <ul>
                        <li>
                          <TextField
                            id="filled-basic"
                            label="Billing Address Line 1"
                            variant="filled"
                            value={billingAddress}
                            onChange={e => {
                              setBillingAddress(e.target.value);
                            }}
                          />
                        </li>
                        <li>
                          <TextField
                            id="filled-basic"
                            label="Billing Address Line 2"
                            variant="filled"
                            value={billingAddress1}
                            onChange={e => {
                              setBillingAddress1(e.target.value);
                            }}
                          />
                        </li>
                        <li>
                          <TextField
                            id="filled-basic"
                            label="Billing Address Line 3"
                            variant="filled"
                            value={billingAddress2}
                            onChange={e => {
                              setBillingAddress2(e.target.value);
                            }}
                          />
                        </li>
                        <li>
                          <TextField
                            id="filled-basic"
                            label="City"
                            variant="filled"
                            value={city}
                            onChange={e => {
                              setCity(e.target.value);
                            }}
                          />
                        </li>
                        <li className="formSelectDropdown">
                          <FormControl variant="filled">
                            <InputLabel id="demo-simple-select-filled-label">
                              Country
                            </InputLabel>
                            <Select
                              value={country}
                              labelId="demo-simple-select-filled-label"
                              id="demo-simple-select-filled"
                              onChange={e => {
                                setCountry(e.target.value);
                              }}
                            >
                              {countries &&
                                countries.map(item => {
                                  return (
                                    <MenuItem
                                      value={item.code}
                                      name={item.code}
                                      key={item.code}
                                    >
                                      {item.name}
                                    </MenuItem>
                                  );
                                })}
                            </Select>
                          </FormControl>
                        </li>
                      </ul>
                    </div>
                    <SquarePaymentsForm
                      applicationId={SQUARE_APPLICATION_ID}
                      cardTokenizeResponseReceived={(props, buyer) => {
                        setCardDetails(props);
                        setVerificationToken(buyer?.token);
                      }}
                      createPaymentRequest={() => ({
                        countryCode: country,
                        currencyCode: 'GBP',
                        postalCode: 'false',
                        total: {
                          amount: `${
                            meetingRoomData.bookingCost ||
                            eventsBookingData?.payment?.totalWithVat
                          }`,
                          label: 'DERWENT LONDON',
                        },
                      })}
                      createVerificationDetails={() => ({
                        amount: `${
                          meetingRoomData.bookingCost ||
                          eventsBookingData.payment.totalWithVat
                        }`,
                        billingContact: {
                          addressLines: [
                            billingAddress,
                            billingAddress1,
                            billingAddress2,
                          ],
                          familyName: customerDetails.firstname,
                          givenName: customerDetails.Lastname,
                          countryCode: country,
                          city: city,
                        },
                        currencyCode: 'GBP',
                        intent: 'CHARGE',
                      })}
                      locationId={SQUARE_LOCATION_ID}
                    >
                      <div className="gPayApayBlock">
                        {isMobile && <ApplePay />}
                        {isMobile && !isIOS ? <GooglePay /> : ''}
                      </div>
                      <CreditCardInput />
                    </SquarePaymentsForm>
                  </>
                )}
              </div>
            </div>
          </div>
        )
      )}
      {failedPaymentError && (
        <div className="failedPaymentCardBox">
          <PaymentError />
        </div>
      )}
    </div>
  );
};

export default Payments;
