/* eslint-disable import/no-cycle */
import React, { useContext, useState, useEffect } from 'react';
import { Divider, CircularProgress, Tooltip } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import dowmArw from '../../images/down-arw.svg';
import { BookingContext } from '../../context/BookingContext';
import NoResultsFound from '../../components/NoResultsFound/NoResultsFound';
import { GlobalContext } from '../../context/GlobalContext';
import MrBookingFilterSearch from './MrBookingFilterSearch';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import { logOut } from '../../utils/logOut';
import SnackBar from '../../components/SnackBar/SnackBar';
import {
  bookingTimeType,
  extendBookings,
  timeZone,
} from '../../utils/constants';

let filteredColumns = [];
const columns = [
  {
    id: 'customer',
    SortOrder: 'desc',
    SortBy: 'customer',
    label: 'CUSTOMER',
  },
  {
    id: 'meetingRoom',
    SortOrder: 'desc',
    SortBy: 'meetingRoom',
    label: 'MEETING ROOM',
  },
  {
    id: 'village',
    SortOrder: 'desc',
    SortBy: 'building',
    label: 'LOCATION',
    format: value => value.toLocaleString('en-US'),
  },
  {
    id: 'startDate',
    SortOrder: 'desc',
    SortBy: 'startDate',
    label: 'START',
    format: value => value.toLocaleString('en-US'),
  },
  {
    id: 'endDate',
    SortOrder: 'desc',
    SortBy: 'endDate',
    label: 'END',
    format: value => value.toLocaleString('en-US'),
  },
  {
    id: 'turnAroundTime',
    SortOrder: 'desc',
    SortBy: 'roomLayout',
    label: 'TURN AROUND TIME (Minute)',
    align: 'right',
    format: value => value.toFixed(2),
  },
  {
    id: 'roomCost',
    SortOrder: 'desc',
    SortBy: 'roomCost',
    label: ' ROOM COST',
    align: 'right',
    format: value => value.toFixed(2),
  },
  {
    id: 'cateringCost',
    SortOrder: 'desc',
    SortBy: 'cateringCost',
    label: 'CATERING COST',
    align: 'right',
    format: value => value.toFixed(2),
  },
  {
    id: 'totalWithVat',
    SortOrder: 'desc',
    SortBy: 'bookingCost',
    label: 'TOTAL INC. VAT',
    align: 'right',
    format: value => value.toFixed(2),
  },
  {
    id: 'status',
    SortOrder: 'desc',
    SortBy: 'status',
    label: 'STATUS',
    format: value => value.toFixed(2),
  },
];

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 'calc(100vh - 240px)',
  },
  tableHeaderCell: {
    background: '#f8f8f8',
    border: 'none',
    padding: '10px 24px 10px 12px',
    color: '#444444',
    fontSize: '12px',
    fontWeight: 700,
    fontFamily: ['Varta', 'sans-serif'].join(','),
    cursor: 'pointer',
    textTransform: 'uppercase',
    '&:after': {
      position: 'absolute',
      content: '""',
      background: `url(${dowmArw}) no-repeat 95% center !important`,
      right: '10px',
      top: '50%',
      transform: 'translateY(-50%)',
      height: '10px',
      width: '10px',
    },
    '&:before': {
      position: 'absolute',
      content: '""',
      background: '#dfdfdf',
      right: '0',
      top: '50%',
      transform: 'translateY(-50%)',
      width: '1px',
      height: '40%',
    },
    '&:last-child': {
      '&:before': {
        display: 'none',
      },
      '&:after': {
        display: 'none',
      },
    },
  },
  active: {
    '&:after': {
      transform: 'translateY(-50%) rotate(180deg)',
    },
  },
  editIcon: {
    margin: '0 auto',
    cursor: 'pointer',
    position: 'relative',
    top: '5px',
  },
  accountBoxImg: {
    '& ul': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      '& li': {
        width: '40%',
        '& img': {
          margin: '0 auto',
        },
      },
    },
  },
  tableBody: {
    verticalAlign: 'top',
    '& th': {
      fontSize: '13px',
      lineHeight: '23px',
      color: '#838384',
      fontWeight: 'bold',
      fontFamily: ['Varta', 'sans-serif'].join(','),
      textTransform: 'capitalize',
      padding: '8px 12px',
    },
    '& td': {
      fontSize: '13px',
      lineHeight: '23px',
      color: '#838384',
      fontWeight: 'bold',
      fontFamily: ['Varta', 'sans-serif'].join(','),
      textTransform: 'capitalize',
      padding: '8px 12px',
    },
  },
  customerEmail: {
    fontWeight: 'normal',
    textTransform: 'capitalise',
    marginTop: '0',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  customerVillg: {
    fontWeight: 'normal',
    textTransform: 'capitalize',
    marginBottom: '0',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },

  addIcon: {
    position: 'fixed',
    zIndex: '999',
    right: '55px',
    bottom: '55px',
  },
  fab: {
    background: '#000',
    color: '#fff',
    '&:hover': {
      background: '#000',
      color: '#fff',
    },
  },
  pageListMain: {
    position: 'relative',
  },
  popupBadge: {
    background: '#000000',
    borderRadius: '11px',
    fontSize: '16px',
    color: '#fff',
    width: '50%',
    margin: '0 auto',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    textAlign: 'center',
    padding: ' 12px 0',
    fontFamily: ['Varta', 'sans-serif'].join(','),
    zIndex: 999999,
  },
  moreList: {
    '& h4': {
      fontSize: '11px',
      fontWeight: 'bold',
      color: '#838384',
      fontFamily: ['Varta', 'sans-serif'].join(','),
    },
    '& li': {
      '& label': {
        '& span': {
          '&:last-child': {
            fontSize: '14px',
            color: '#444444',
            fontFamily: ['Varta', 'sans-serif'].join(','),
            lineHeight: '19px',
            position: 'relative',
            top: '2px',
          },
        },
      },
    },
  },
  moreListHeadtwo: {
    marginTop: '25px',
  },
  moreListHead: {
    paddingLeft: '10px',
    '& li': {
      padding: 0,
      '& label': {
        '& span': {
          '&:last-child': {
            fontSize: '12px',
            color: '#444444',
            fontFamily: ['Varta', 'sans-serif'].join(','),
            lineHeight: '19px',
            position: 'relative',
            top: '2px',
            fontWeight: 'bold',
          },
        },
      },
    },
  },
  filtercheckTop: {
    paddingLeft: '10px',
    '& li': {
      padding: 0,
      '& label': {
        '& span': {
          '&:last-child': {
            fontSize: '12px',
            color: '#444444',
            fontFamily: ['Varta', 'sans-serif'].join(','),
            lineHeight: '19px',
            position: 'relative',
            top: '2px',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            boxSizing: 'border-box',
            width: '72%',
          },
        },
      },
    },
  },
  totalCount: {
    marginTop: '10px',
    padding: '5px 0',
    '& ul': {
      display: 'flex',
      alignItems: 'çenter',
      justifyContent: 'space-between',
      '& li': {
        color: '#838384',
        fontSize: '14px',
        fontFamily: ['Varta', 'sans-serif'].join(','),
      },
    },
  },
  userAddTime: {
    fontWeight: 'normal',
  },
  customerNameNew: {
    display: 'inline-block',
    verticalAlign: 'middle',
    marginLeft: '4px',
    '& img': {
      width: '30px',
    },
  },
  accountBoxImgColumn: {
    verticalAlign: 'middle',
  },
  platformIcons: {
    display: 'flex',
    width: 70,
    margin: 'auto',
  },
  embeddedPad: {
    padding: 0,
  },
});

const BookingList = props => {
  const { embedded } = props;
  const {
    bookingUsersList,
    loading,
    setSortOrder,
    sortOrder,
    setSortBy,
    sortBy,
    discountPrice,
    vat,
    fullDay,
  } = useContext(BookingContext);
  const {
    extendData,
    extendRoomTotal,
    extendTime,
    selectedName,
    newMeetingRoomsList,
    isCatering,
  } = useContext(GlobalContext);
  const [endTimeBuffer] = useState(
    moment
      .tz(extendData.endDate, 'Europe/London')
      .add(extendTime, 'minutes')
      .format('hh:mm a'),
  );
  const [endNewDate] = useState(
    moment.tz(extendData.endDate, 'Europe/London').format('hh:mm a'),
  );
  const endDateTime = new Date(
    `${moment(extendData.endDate).format('ddd MMM DD YYYY')} ${endNewDate}`,
  );
  const [fullDayPrice, setFullDayPrice] = useState('');
  const [hourlyPrice, setHourlyPrice] = useState(
    extendData && extendData.meetingRoom && extendData.meetingRoom.hourlyPrice,
  );
  const classes = useStyles();
  const history = useHistory();
  const [active, setActive] = useState(false);
  const handleClick = (columnId, sortName) => {
    setSortOrder(sortOrder === columnId ? 'asc' : 'desc');
    setSortBy(sortBy === sortName ? sortBy : sortName);
  };
  useEffect(() => {
    const roomId =
      newMeetingRoomsList &&
      newMeetingRoomsList.filter(data => data.id === selectedName);
    setFullDayPrice(roomId && roomId[0] && roomId[0].fullDayPrice);
    setHourlyPrice(roomId && roomId[0] && roomId[0].hourlyPrice);
  }, [selectedName]);

  useEffect(() => {
    filteredColumns = columns;
    if (embedded !== undefined) {
      filteredColumns = columns.filter(col => col.id !== 'customer');
    }
    if (isCatering === false && embedded === undefined) {
      filteredColumns = columns.filter(col => col.id !== 'cateringCost');
    }
  }, [embedded, isCatering]);

  const getMainBookingStartDate = booking => {
    let extendBookingLabel;
    if (booking?.extendBooking?.eventType === bookingTimeType.pastBooking)
      extendBookingLabel = extendBookings.extendBooking;
    if (booking?.extendBooking2?.eventType === bookingTimeType.pastBooking)
      extendBookingLabel = extendBookings.extendBooking2;
    return moment
      .tz(
        booking?.[extendBookingLabel]?.startDate <= booking?.startDate
          ? booking?.[extendBookingLabel]?.endDate
          : booking?.startDate,
        timeZone.london,
      )
      .format('h:mm a');
  };

  const getMainBookingEndDate = booking => {
    let extendBookingLabel;
    if (booking?.extendBooking?.eventType === bookingTimeType.futureBooking)
      extendBookingLabel = extendBookings.extendBooking;
    if (booking?.extendBooking2?.eventType === bookingTimeType.futureBooking)
      extendBookingLabel = extendBookings.extendBooking2;
    return moment
      .tz(
        booking?.[extendBookingLabel]?.startDate > booking?.startDate
          ? booking?.[extendBookingLabel]?.startDate
          : booking?.endDate,
        timeZone.london,
      )
      .format('h:mm a');
  };

  const mrVatRate = vat && vat[0] && vat[0].vatRate;
  const startTimeInLtFormat = moment(extendData && extendData.endDate);
  const endTImeInLtFormat = moment(extendData && extendData.endDate).add(
    extendTime,
    'minutes',
  );
  const duration = moment(endTImeInLtFormat.toISOString()).diff(
    startTimeInLtFormat.toISOString(),
    'minutes',
  );
  const hours = duration / 60;
  const calcPrice =
    (extendData &&
      extendData.meetingRoom &&
      extendData.meetingRoom.hourlyPrice) * hours;
  console.log(hourlyPrice, 'price');
  console.log(calcPrice);
  const mrBookingPrice =
    fullDay === true
      ? `${fullDayPrice}`
      : `${
          endDateTime && endTimeBuffer
            ? `${calcPrice > fullDayPrice ? fullDayPrice : calcPrice}`
            : ''
        }`;
  const mrSubTotal1 =
    Number(mrBookingPrice) -
    (Number(discountPrice) > Number(mrBookingPrice)
      ? 0
      : Number(discountPrice));
  const mrVat1 = (mrVatRate / 100) * mrSubTotal1;
  const roomTotal1 = mrSubTotal1 + mrVat1;
  console.log(roomTotal1, 'total');
  console.log(extendRoomTotal);

  const viewBooking = rowId => {
    history.push(`/view-booking/${rowId}`);
  };
  // eslint-disable-next-line no-unused-vars
  const viewBooking1 = rowId => {
    history.push('/order-preview/');
  };
  const breadCrumbs = {
    listBreadCrumbPath: '/',
    listBreadCrumbTitle: 'Meeting Room Bookings',
  };

  if (!(localStorage && localStorage.getItem('adal.session.state'))) {
    logOut();
  }

  const paymentMethodStatus = paymentMethod => {
    if (paymentMethod === 'PayByAccount') {
      return 'Pay to Account';
    }
    if (paymentMethod === 'Card') {
      return 'Paid by Card';
    }
    if (paymentMethod === 'Pos') {
      return 'Terminal';
    }
    return paymentMethod;
  };

  return (
    <div className={embedded ? classes.embeddedAlign : classes.pageListMain}>
      {!embedded && (
        <>
          <SnackBar />
          <div className="pageBredcrumbTimer">
            <Breadcrumb breadCrumbs={breadCrumbs} />
          </div>
          <Divider light />
          <MrBookingFilterSearch />
          <Divider light />
        </>
      )}
      <div
        className={`pageContainer tableContainer ${
          embedded && classes.embeddedPad
        }`}
      >
        <Paper className={classes.root}>
          <TableContainer className={classes.container}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {filteredColumns.map(column => (
                    <TableCell
                      className={`${classes.tableHeaderCell} noSort ${
                        active && column.SortBy === sortBy ? 'active' : ''
                      }`}
                      align={column.align}
                      id={column.label}
                      key={column.id}
                      disabled={column.SortBy === ''}
                      onClick={() => {
                        if (column.SortBy !== '' || column.SortBy === sortBy) {
                          handleClick(column.SortOrder, column.SortBy);
                          setActive(!active);
                        }
                      }}
                      style={{ minWidth: column.minWidth }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              {loading ? (
                <CircularProgress color="inherit" />
              ) : (
                <TableBody>
                  {bookingUsersList?.length
                    ? bookingUsersList.map(row => (
                        <>
                          <TableRow className={classes.tableBody} key={row.id}>
                            {!embedded && (
                              <TableCell
                                component="th"
                                scope="row"
                                align="left"
                                valign="middle"
                                style={{
                                  maxWidth: 200,
                                  minWidth: 120,
                                  width: 120,
                                  verticalAlign: 'middle',
                                }}
                                onClick={() => {
                                  viewBooking(row.id);
                                }}
                              >
                                <div className="rowEllips">
                                  <Tooltip
                                    title={`${
                                      row &&
                                      row.customer &&
                                      row.customer.firstName
                                    } ${
                                      row &&
                                      row.customer &&
                                      row.customer.lastName
                                    }`}
                                  >
                                    <div>
                                      {`${
                                        row &&
                                        row.customer &&
                                        row.customer.firstName
                                      } ${
                                        row &&
                                        row.customer &&
                                        row.customer.lastName
                                      }`}
                                    </div>
                                  </Tooltip>
                                  <Tooltip
                                    title={
                                      row &&
                                      row.customer &&
                                      row.customer.tenantName
                                    }
                                  >
                                    <div className={classes.customerEmail}>
                                      {row &&
                                        row.customer &&
                                        row.customer.tenantName}
                                    </div>
                                  </Tooltip>
                                </div>
                              </TableCell>
                            )}
                            <TableCell
                              align="left"
                              style={{
                                maxWidth: 160,
                                width: 160,
                                verticalAlign: 'top',
                              }}
                              onClick={() => {
                                viewBooking(row.id);
                              }}
                            >
                              <div className="rowEllips">
                                {row && row.meetingRoom && row.meetingRoom.name}
                              </div>
                            </TableCell>
                            <TableCell
                              align="left"
                              style={{
                                maxWidth: 180,
                                width: 180,
                                minWidth: 180,
                              }}
                              onClick={() => {
                                viewBooking(row.id);
                              }}
                            >
                              <div className="rowEllips">
                                <Tooltip
                                  title={
                                    row && row.building && row.building.name
                                  }
                                >
                                  <div>
                                    {row && row.building && row.building.name}
                                  </div>
                                </Tooltip>
                                <div className={classes.customerVillg}>
                                  {row && row.building && row.building.village}
                                </div>
                              </div>
                            </TableCell>
                            <TableCell
                              align="left"
                              style={{ maxWidth: 120, width: 120 }}
                              onClick={() => {
                                viewBooking(row.id);
                              }}
                            >
                              <div className="rowEllips">
                                <div>
                                  {moment
                                    .tz(row.startDate, 'Europe/London')
                                    .format('DD MMM YYYY')}
                                </div>
                                <div
                                  className={`${classes.customerEmail} meetingTime`}
                                >
                                  {getMainBookingStartDate(row)}
                                </div>
                              </div>
                            </TableCell>
                            <TableCell
                              align="left"
                              style={{ maxWidth: 120, width: 120 }}
                              onClick={() => {
                                viewBooking(row.id);
                              }}
                            >
                              <div className="rowEllips">
                                <div>
                                  {moment
                                    .tz(row.endDate, 'Europe/London')
                                    .format('DD MMM YYYY')}
                                </div>
                                <div
                                  className={`${classes.customerEmail} meetingTime`}
                                >
                                  {getMainBookingEndDate(row)}
                                </div>
                              </div>
                            </TableCell>
                            <TableCell
                              align="right"
                              style={{ maxWidth: 130, width: 130 }}
                            >
                              <div className="rowEllips">
                                <div className={classes.customerEmail}>
                                  {row &&
                                    row.roomLayout &&
                                    row.roomLayout.turnAroundTime}
                                </div>
                              </div>
                            </TableCell>
                            <TableCell
                              align="right"
                              style={{ maxWidth: 130, width: 130 }}
                            >
                              <div className="rowEllips">
                                {row &&
                                row.bookingInvoice &&
                                row.bookingInvoice.url ? (
                                  <div className="roomCoctsUnderline">
                                    <a
                                      rel="noreferrer"
                                      href={
                                        row &&
                                        row.bookingInvoice &&
                                        row.bookingInvoice.url
                                      }
                                      target="_blank"
                                    >
                                      {row.status === 'Pending'
                                        ? '__'
                                        : `£${
                                            row &&
                                            row.payment &&
                                            row.payment.total.toFixed(2)
                                          }`}
                                    </a>
                                  </div>
                                ) : (
                                  <div className="roomCoctsUnderline">
                                    {row.status === 'Pending'
                                      ? '__'
                                      : `£${
                                          row &&
                                          row.payment &&
                                          row.payment.total.toFixed(2)
                                        }`}
                                  </div>
                                )}
                                <div className={classes.customerEmail}>
                                  {row.status === 'Pending'
                                    ? ''
                                    : row && row.payment && row.payment.status}
                                </div>
                              </div>
                            </TableCell>
                            {isCatering ? (
                              <TableCell
                                align="right"
                                style={{ maxWidth: 150, width: 150 }}
                                onClick={() => {
                                  viewBooking(row.id);
                                }}
                              >
                                <div className="rowEllips">
                                  {row &&
                                  row.cateringInvoice &&
                                  row.cateringInvoice.url ? (
                                    <div className="roomCoctsUnderline">
                                      {row &&
                                      row.cateringOrder &&
                                      row.cateringOrder.orderMenu &&
                                      row.cateringOrder.orderMenu.length > 0 &&
                                      row.cateringOrder ? (
                                        <a
                                          rel="noreferrer"
                                          href={
                                            row &&
                                            row.cateringInvoice &&
                                            row.cateringInvoice.url
                                          }
                                          target="_blank"
                                        >
                                          £
                                          {row &&
                                            row.cateringOrder &&
                                            row.cateringOrder.totalWithVat.toFixed(
                                              2,
                                            )}
                                        </a>
                                      ) : (
                                        '__'
                                      )}
                                    </div>
                                  ) : (
                                    <div className="roomCoctsUnderline">
                                      {row &&
                                      row.cateringOrder &&
                                      row.cateringOrder.orderMenu &&
                                      row.cateringOrder.orderMenu.length > 0 &&
                                      row.cateringOrder
                                        ? `£${
                                            row &&
                                            row.cateringOrder &&
                                            row.cateringOrder.totalWithVat.toFixed(
                                              2,
                                            )
                                          }`
                                        : '__'}
                                    </div>
                                  )}
                                  <div className={classes.customerEmail}>
                                    {row &&
                                    row.cateringOrder &&
                                    row.cateringOrder.orderMenu &&
                                    row.cateringOrder.orderMenu.length > 0 &&
                                    row.cateringOrder
                                      ? row &&
                                        row.cateringOrder &&
                                        row.cateringOrder.paymentStatus
                                      : ' '}
                                  </div>
                                </div>
                              </TableCell>
                            ) : (
                              ''
                            )}
                            <TableCell
                              align="right"
                              style={{ maxWidth: 140, width: 140 }}
                              onClick={() => {
                                viewBooking(row.id);
                              }}
                              className="{row.status}"
                            >
                              <div className="rowEllips">
                                <div>
                                  {row.status === 'Pending'
                                    ? '__'
                                    : `£${Number(
                                        row &&
                                          row.payment &&
                                          row.payment.totalWithVat,
                                      ).toFixed(2)}`}
                                </div>
                                <div className={classes.customerEmail}>
                                  {row.status !== 'Pending' &&
                                  row &&
                                  row.payment &&
                                  row.payment.method
                                    ? paymentMethodStatus(row.payment.method)
                                    : ''}
                                </div>
                              </div>
                            </TableCell>
                            <TableCell
                              align="left"
                              style={{ maxWidth: 100, width: 100 }}
                              onClick={() => {
                                viewBooking(row.id);
                              }}
                            >
                              <div className={`${row.status} rowEllips`}>
                                {row.status === 'NoShow'
                                  ? 'No Show'
                                  : row.status}
                              </div>
                            </TableCell>
                          </TableRow>
                          {(row.extendBooking && (
                            <TableRow
                              className={classes.tableBody}
                              key={row.id}
                            >
                              <TableCell
                                component="th"
                                scope="row"
                                align="left"
                                valign="middle"
                                style={{
                                  maxWidth: 200,
                                  minWidth: 120,
                                  width: 120,
                                  verticalAlign: 'middle',
                                }}
                                onClick={() => {
                                  viewBooking(row.id);
                                }}
                              >
                                <div className="rowEllips">
                                  <Tooltip
                                    title={`${
                                      row &&
                                      row.customer &&
                                      row.customer.firstName
                                    } ${
                                      row &&
                                      row.customer &&
                                      row.customer.lastName
                                    }`}
                                  >
                                    <div>
                                      {`${
                                        row &&
                                        row.customer &&
                                        row.customer.firstName
                                      } ${
                                        row &&
                                        row.customer &&
                                        row.customer.lastName
                                      }`}
                                    </div>
                                  </Tooltip>
                                  <Tooltip
                                    title={
                                      row &&
                                      row.customer &&
                                      row.customer.tenantName
                                    }
                                  >
                                    <div className={classes.customerEmail}>
                                      {row &&
                                        row.customer &&
                                        row.customer.tenantName}
                                    </div>
                                  </Tooltip>
                                </div>
                              </TableCell>
                              <TableCell
                                align="left"
                                style={{
                                  maxWidth: 160,
                                  width: 160,
                                  verticalAlign: 'top',
                                }}
                                onClick={() => {
                                  viewBooking(row.id);
                                }}
                              >
                                <div className="rowEllips">
                                  {row &&
                                    row.meetingRoom &&
                                    row.meetingRoom.name}
                                </div>
                              </TableCell>
                              <TableCell
                                align="left"
                                style={{
                                  maxWidth: 180,
                                  width: 180,
                                  minWidth: 180,
                                }}
                                onClick={() => {
                                  viewBooking(row.id);
                                }}
                              >
                                <div className="rowEllips">
                                  <Tooltip
                                    title={
                                      row && row.building && row.building.name
                                    }
                                  >
                                    <div>
                                      {row && row.building && row.building.name}
                                    </div>
                                  </Tooltip>
                                  <div className={classes.customerVillg}>
                                    {row &&
                                      row.building &&
                                      row.building.village}
                                  </div>
                                </div>
                              </TableCell>
                              <TableCell
                                align="left"
                                style={{ maxWidth: 120, width: 120 }}
                                onClick={() => {
                                  viewBooking(row.id);
                                }}
                              >
                                <div className="rowEllips">
                                  <div>
                                    {moment
                                      .tz(
                                        row.extendBooking.startDate,
                                        'Europe/London',
                                      )
                                      .format('DD MMM YYYY')}
                                  </div>
                                  <div
                                    className={`${classes.customerEmail} meetingTime`}
                                  >
                                    {moment
                                      .tz(
                                        row.extendBooking.startDate,
                                        'Europe/London',
                                      )
                                      .format('h:mm a')}
                                  </div>
                                </div>
                              </TableCell>
                              <TableCell
                                align="left"
                                style={{ maxWidth: 120, width: 120 }}
                                onClick={() => {
                                  viewBooking(row.id);
                                }}
                              >
                                <div className="rowEllips">
                                  <div>
                                    {moment
                                      .tz(
                                        row.extendBooking.endDate,
                                        'Europe/London',
                                      )
                                      .format('DD MMM YYYY')}
                                  </div>
                                  <div
                                    className={`${classes.customerEmail} meetingTime`}
                                  >
                                    {moment
                                      .tz(
                                        row.extendBooking.endDate,
                                        'Europe/London',
                                      )
                                      .format('h:mm a')}
                                  </div>
                                </div>
                              </TableCell>
                              <TableCell
                                align="right"
                                style={{ maxWidth: 130, width: 130 }}
                              >
                                <div className="rowEllips">
                                  <div className={classes.customerEmail}>
                                    {row &&
                                      row.roomLayout &&
                                      row.roomLayout.turnAroundTime}
                                  </div>
                                </div>
                              </TableCell>
                              <TableCell
                                align="right"
                                style={{ maxWidth: 130, width: 130 }}
                              >
                                <div className="rowEllips">
                                  {row &&
                                  row.extendBooking &&
                                  row.extendBooking.extensionInvoice ? (
                                    <div className="roomCoctsUnderline">
                                      <a
                                        rel="noreferrer"
                                        href={
                                          row &&
                                          row.extendBooking &&
                                          row.extendBooking.extensionInvoice
                                        }
                                        target="_blank"
                                      >
                                        {row.extendBooking.paymentStatus ===
                                        'Pending'
                                          ? '__'
                                          : `£${
                                              row &&
                                              row.extendBooking &&
                                              row.extendBooking.total.toFixed(2)
                                            }`}
                                      </a>
                                    </div>
                                  ) : (
                                    <div className="roomCoctsUnderline">
                                      {row.extendBooking.paymentStatus ===
                                      'Pending'
                                        ? '__'
                                        : `£${
                                            row &&
                                            row.extendBooking &&
                                            row.extendBooking.total.toFixed(2)
                                          }`}
                                    </div>
                                  )}
                                  <div className={classes.customerEmail}>
                                    {row.extendBooking.paymentStatus ===
                                    'Pending'
                                      ? ''
                                      : row &&
                                        row.extendBooking &&
                                        row.extendBooking.paymentStatus}
                                  </div>
                                </div>
                              </TableCell>

                              <TableCell
                                align="right"
                                style={{ maxWidth: 140, width: 140 }}
                                onClick={() => {
                                  viewBooking(row.id);
                                }}
                                className="{row.status}"
                              >
                                <div className="rowEllips">
                                  <div>
                                    {row.status === 'Pending'
                                      ? '__'
                                      : `£${Number(
                                          row && row.extendBooking.totalWithVat,
                                        ).toFixed(2)}`}
                                  </div>
                                  <div className={classes.customerEmail}>
                                    {row.status !== 'Pending' &&
                                    row &&
                                    row.extendBooking &&
                                    row.extendBooking.paymentMethod
                                      ? paymentMethodStatus(
                                          row.extendBooking.paymentMethod,
                                        )
                                      : ''}
                                  </div>
                                </div>
                              </TableCell>
                              <TableCell
                                align="left"
                                style={{ maxWidth: 100, width: 100 }}
                                onClick={() => {
                                  viewBooking(row.id);
                                }}
                              >
                                <div
                                  className={`${row.status} ${row.extendBooking.paymentStatus} rowEllips`}
                                >
                                  {row.status === 'NoShow'
                                    ? 'No Show'
                                    : (row.extendBooking.paymentStatus ===
                                        'Pending' &&
                                        'Extend Pending') ||
                                      row.status}
                                </div>
                              </TableCell>
                            </TableRow>
                          )) ||
                            ''}
                          {(row.extendBooking2 && (
                            <TableRow
                              className={classes.tableBody}
                              key={row.id}
                            >
                              <TableCell
                                component="th"
                                scope="row"
                                align="left"
                                valign="middle"
                                style={{
                                  maxWidth: 200,
                                  minWidth: 120,
                                  width: 120,
                                  verticalAlign: 'middle',
                                }}
                                onClick={() => {
                                  viewBooking(row.id);
                                }}
                              >
                                <div className="rowEllips">
                                  <Tooltip
                                    title={`${row?.customer?.firstName} ${row?.customer?.lastName}`}
                                  >
                                    <div>
                                      {`${row?.customer?.firstName} ${row?.customer?.lastName}`}
                                    </div>
                                  </Tooltip>
                                  <Tooltip title={row?.customer?.tenantName}>
                                    <div className={classes.customerEmail}>
                                      {row?.customer?.tenantName}
                                    </div>
                                  </Tooltip>
                                </div>
                              </TableCell>
                              <TableCell
                                align="left"
                                style={{
                                  maxWidth: 160,
                                  width: 160,
                                  verticalAlign: 'top',
                                }}
                                onClick={() => {
                                  viewBooking(row.id);
                                }}
                              >
                                <div className="rowEllips">
                                  {row?.meetingRoom?.name}
                                </div>
                              </TableCell>
                              <TableCell
                                align="left"
                                style={{
                                  maxWidth: 180,
                                  width: 180,
                                  minWidth: 180,
                                }}
                                onClick={() => {
                                  viewBooking(row.id);
                                }}
                              >
                                <div className="rowEllips">
                                  <Tooltip title={row?.building?.name}>
                                    <div>{row?.building?.name}</div>
                                  </Tooltip>
                                  <div className={classes.customerVillg}>
                                    {row?.building?.village}
                                  </div>
                                </div>
                              </TableCell>
                              <TableCell
                                align="left"
                                style={{ maxWidth: 120, width: 120 }}
                                onClick={() => {
                                  viewBooking(row.id);
                                }}
                              >
                                <div className="rowEllips">
                                  <div>
                                    {moment
                                      .tz(
                                        row?.extendBooking2?.startDate,
                                        timeZone.london,
                                      )
                                      .format('DD MMM YYYY')}
                                  </div>
                                  <div
                                    className={`${classes.customerEmail} meetingTime`}
                                  >
                                    {moment
                                      .tz(
                                        row?.extendBooking2?.startDate,
                                        timeZone.london,
                                      )
                                      .format('h:mm a')}
                                  </div>
                                </div>
                              </TableCell>
                              <TableCell
                                align="left"
                                style={{ maxWidth: 120, width: 120 }}
                                onClick={() => {
                                  viewBooking(row.id);
                                }}
                              >
                                <div className="rowEllips">
                                  <div>
                                    {moment
                                      .tz(
                                        row?.extendBooking2?.endDate,
                                        timeZone.london,
                                      )
                                      .format('DD MMM YYYY')}
                                  </div>
                                  <div
                                    className={`${classes.customerEmail} meetingTime`}
                                  >
                                    {moment
                                      .tz(
                                        row?.extendBooking2?.endDate,
                                        timeZone.london,
                                      )
                                      .format('h:mm a')}
                                  </div>
                                </div>
                              </TableCell>
                              <TableCell
                                align="right"
                                style={{ maxWidth: 130, width: 130 }}
                              >
                                <div className="rowEllips">
                                  <div className={classes.customerEmail}>
                                    {row?.roomLayout?.turnAroundTime}
                                  </div>
                                </div>
                              </TableCell>
                              <TableCell
                                align="right"
                                style={{ maxWidth: 130, width: 130 }}
                              >
                                <div className="rowEllips">
                                  {row?.extendBooking2?.extensionInvoice ? (
                                    <div className="roomCoctsUnderline">
                                      <a
                                        rel="noreferrer"
                                        href={
                                          row?.extendBooking2?.extensionInvoice
                                        }
                                        target="_blank"
                                      >
                                        {row?.extendBooking2?.paymentStatus ===
                                        'Pending'
                                          ? '__'
                                          : `£${row?.extendBooking2?.total.toFixed(
                                              2,
                                            )}`}
                                      </a>
                                    </div>
                                  ) : (
                                    <div className="roomCoctsUnderline">
                                      {row?.extendBooking2?.paymentStatus ===
                                      'Pending'
                                        ? '__'
                                        : `£${row?.extendBooking2?.total.toFixed(
                                            2,
                                          )}`}
                                    </div>
                                  )}
                                  <div className={classes.customerEmail}>
                                    {row?.extendBooking2?.paymentStatus ===
                                    'Pending'
                                      ? ''
                                      : row?.extendBooking2?.paymentStatus}
                                  </div>
                                </div>
                              </TableCell>

                              <TableCell
                                align="right"
                                style={{ maxWidth: 140, width: 140 }}
                                onClick={() => {
                                  viewBooking(row.id);
                                }}
                                className="{row.status}"
                              >
                                <div className="rowEllips">
                                  <div>
                                    {row?.status === 'Pending'
                                      ? '__'
                                      : `£${Number(
                                          row?.extendBooking2?.totalWithVat,
                                        ).toFixed(2)}`}
                                  </div>
                                  <div className={classes.customerEmail}>
                                    {row?.status !== 'Pending' &&
                                    row?.extendBooking2?.paymentMethod
                                      ? paymentMethodStatus(
                                          row.extendBooking2.paymentMethod,
                                        )
                                      : ''}
                                  </div>
                                </div>
                              </TableCell>
                              <TableCell
                                align="left"
                                style={{ maxWidth: 100, width: 100 }}
                                onClick={() => {
                                  viewBooking(row.id);
                                }}
                              >
                                <div
                                  className={`${row?.status} ${row?.extendBooking2?.paymentStatus} rowEllips`}
                                >
                                  {row?.status === 'NoShow'
                                    ? 'No Show'
                                    : (row?.extendBooking2?.paymentStatus ===
                                        'Pending' &&
                                        'Extend Pending') ||
                                      row?.status}
                                </div>
                              </TableCell>
                            </TableRow>
                          )) ||
                            ''}
                        </>
                      ))
                    : !embedded && (
                        <>
                          <NoResultsFound />
                        </>
                      )}
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </Paper>
      </div>
    </div>
  );
};
export default BookingList;
