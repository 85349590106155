// Cloudinary media folders for the each category of derwent admin portal.
export const spaces_media_folder = 'Spaces_Dev';
export const buildings_media_folder = 'Buildings_Dev';
export const newsArticles_media_folder = 'News_Dev';
export const offers_media_folder = 'Offers_Dev';
export const events_media_folder = 'Events_Dev';
export const catering_menu_media_folder = 'Catering_Menu_Dev';
export const meetingroom_media_folder = 'MeetingRoom_Dev';
export const news_description_folder = 'News_Desc_Dev';
export const offers_description_folder = 'Offers_Desc_Dev';
export const loyalty_card_folder = 'Loyalty_Card_Dev';

// Cropping image label names(tagName) for different sizes
export const image_title_1 = 'Full Size';
export const image_title_2 = 'Card';
export const image_title_3 = 'Card-1';
export const image_title_4 = 'Card-2';
export const image_title_5 = 'Carousel';
export const image_title_6 = 'Catering Menu';

// Cloudinary upload api base url
export const cloudinary_upload_api_base_url = 'https://api.cloudinary.com/v1_1';

// space or unit image size formats
export const space_img_344x352 = {
  width: 344,
  height: 352,
};
export const space_img_375x368 = {
  width: 375,
  height: 368,
};
export const space_previewImg_344x200 = {
  width: 344,
  height: 200,
};

// Building image size formats.
export const building_img_344x352 = {
  width: 344,
  height: 352,
};
export const building_img_375x368 = {
  width: 375,
  height: 368,
};

// Event image size formats.
export const event_img_312x296 = {
  width: 312,
  height: 296,
};
export const event_img_344x352 = {
  width: 344,
  height: 352,
};
export const event_img_375x368 = {
  width: 375,
  height: 368,
};

// Offer image size formats.
export const offer_img_312x296 = {
  width: 312,
  height: 296,
};
export const offer_img_344x352 = {
  width: 344,
  height: 352,
};
export const offer_img_375x368 = {
  width: 375,
  height: 368,
};

// Meeting Room image size formats.
export const mr_img_312x296 = {
  width: 312,
  height: 296,
};
export const mr_img_344x352 = {
  width: 344,
  height: 352,
};
export const mr_img_375x368 = {
  width: 375,
  height: 368,
};

// Announcement image size formats.
export const announcement_img_312x296 = {
  width: 312,
  height: 296,
};
export const announcement_img_344x352 = {
  width: 344,
  height: 352,
};
export const announcement_img_375x368 = {
  width: 375,
  height: 368,
};

// News image size formats.
export const news_img_312x296 = {
  width: 312,
  height: 296,
};
export const news_img_344x352 = {
  width: 344,
  height: 352,
};
export const news_img_375x368 = {
  width: 375,
  height: 368,
};

export const catering_menu_300x352 = {
  width: 300,
  height: 352,
};

export const loyalty_card_36x150 = {
   width: 250,
   height: 36,
}