/* eslint-disable import/no-cycle */
import React, { useContext, useEffect, useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Dialog,
  DialogActions,
  Divider,
  Step,
  StepLabel,
  Stepper,
  Typography,
  CircularProgress,
  Backdrop,
  Input,
  FormHelperText,
} from '@material-ui/core';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import './mrbooking.css';
import { sendLinkSchema } from '../../utils/formValidate';
import MeetingRoom from './MeetingRoom';
import CustomerDetails from './CustomerDetails';
import OrderPreview from './OrderPreview';
import { GlobalContext } from '../../context/GlobalContext';
import CateringBooking from './CateringBooking';
import ApiClient from '../../helpers/ApiClient';
import apiEndPoints from '../../helpers/apiEndPoints';
import { MrBookingContext } from '../../context/MRBookingContext';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import { logOut } from '../../utils/logOut';
import {
  InvalidTerminalId,
  cardType,
  hour,
  meetingRoomBookingTimer,
  messages,
  timeZone,
} from '../../utils/constants';
import popup_close from '../../images/popup_close.svg';
import cardLogo1 from '../../images/ic_cardVisa.svg';
import cardLogo2 from '../../images/ic_cardMasterCard.svg';
import cardLogo3 from '../../images/ic_cardAmex.svg';
import cardLogo4 from '../../images/ic_cardApplePay.svg';
import cardLogo5 from '../../images/ic_gpay.svg';

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 'calc(100vh - 240px)',
  },
  pageListMain: {},
  formButton: {
    marginTop: '0',
    width: '100% !important',
    display: 'flex',
    justifyContent: 'flex-end',
    '& Button': {
      height: '47px',
      lineHeight: '47px',
      border: '2px solid #000',
      outline: 'none',
      background: '#000000',
      fontSize: '16px',
      fontWeight: 'bold',
      width: 'auto',
      padding: '0 45px',
      color: '#fff',
      marginLeft: '30px',
      cursor: 'pointer',
      fontFamily: ['Varta', 'sans-serif'].join(','),
      minWidth: '33%',
      '&:hover': {
        background: '#000000',
      },
      '&:first-child': {
        background: 'transparent',
        color: '#000',
        border: 'none',
      },
    },
  },
  cancelDialog: {
    textAlign: 'center',
    '& h2': {
      fontSize: '19px',
      fontWeight: 'bold',
      color: '#444444',
      marginBottom: '15px',
      fontFamily: ['Lato', 'sans-serif'].join(','),
    },
    '& p': {
      fontSize: '16px',
      fontWeight: 'normal',
      color: '#444444',
      marginBottom: '15px',
      fontFamily: ['Lato', 'sans-serif'].join(','),
    },
  },
  cardPopupFormButton: {
    justifyContent: 'center',
    '& button': {
      margin: '0 auto !important',
      textTransform: 'initial',
      '& small': {
        marginLeft: '7px',
      },
    },
  },
  extendPopupFormButton: {
    '& button': {
      '&:first-child': {
        background: '#000',
        color: '#fff !important',
        border: '2px solid #000',
        padding: '0 32px',
        marginTop: 20,
      },
    },
  },
  errorColor: {
    color: '#d60303 !important',
  },
  bookingTimeLeft: {
    fontSize: '16px',
    lineHeight: '22px',
    color: '#838384',
    zIndex: '999999',
    position: 'absolute',
    right: '30px',
    top: '50%',
    transform: 'translateY(-50%)',
  },
  bookingTimeWarning: {
    color: '#cb0000',
  },
  InputSection: {
    '& li': {
      '& span': {
        display: 'block',
        fontSize: '16px',
        fontWeight: 'normal',
        color: '#838384',
        fontFamily: ['Varta', 'sans-serif'].join(','),
      },
      '& input': {
        height: '45px',
        lineHeight: '45px',
        padding: '0 15px !important',
        fontSize: '16px',
        color: '#444444',
        borderRadius: '3px',
        border: 'solid 0.5px #979797',
        marginTop: '10px',
        width: '100%',
        background: 'transparent',
        fontFamily: ['Varta', 'sans-serif'].join(','),
      },
      '& > div': {
        width: '100%',
        '&:before': {
          display: 'none',
        },
        '&:after': {
          display: 'none',
        },
      },
    },
  },
  flexNone: {
    display: 'block !important',
  },
  mb_20: {
    marginBottom: '20px !important',
  },
});
function getSteps() {
  return ['Meeting Room', 'Catering', 'Customer Details', 'Order Preview'];
}

function getStepContent(stepIndex) {
  switch (stepIndex) {
    case 0:
      return <MeetingRoom />;
    case 1:
      return <CateringBooking />;
    case 2:
      return <CustomerDetails />;
    case 3:
      return <OrderPreview />;
    default:
      return 'Unknown stepIndex';
  }
}

export const handleClickOpenTerminals = (
  paymentServiceProvider,
  setOpenTerminalDialog,
  setMrPaymentPos,
  setTerminals,
) => {
  if (paymentServiceProvider) {
    getTerminals(setTerminals);
    setOpenTerminalDialog(true);
  } else {
    setMrPaymentPos(true);
  }
};

export const TerminalDialog = ({
  classes,
  openTerminalDialog,
  handleTerminalDialogClose,
  terminals,
  setTerminalId,
  mrPaymentPos,
  terminalId,
  setMrPaymentPos,
  total,
}) => {
  return (
    <Dialog
      open={openTerminalDialog}
      onClose={handleTerminalDialogClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="cancelDialogOpend savedCardPopupOpend"
    >
      <Button className="popupCloseBtn" onClick={handleTerminalDialogClose}>
        <img src={popup_close} alt="popup_close.svg" />
      </Button>
      <h4>Terminals</h4>
      <div className="allCardList">
        <ul className={classes.cardList}>
          {terminals?.length
            ? terminals.map(terminal => (
                <>
                  <li
                    key={terminal?.tid}
                    className={`${
                      terminalId === terminal?.tid ? 'cardActive' : ''
                    }`}
                  >
                    <Button
                      onClick={() => {
                        setTerminalId(terminal?.tid);
                      }}
                    >
                      <p>{terminal?.name || InvalidTerminalId}</p>
                    </Button>
                  </li>
                </>
              ))
            : 'No Terminals Found'}
        </ul>
      </div>
      <DialogActions
        className={`${classes.formButton} ${classes.popupFormButton} ${classes.extendPopupFormButton} ${classes.cardPopupFormButton}`}
      >
        <Button
          disabled={
            Number(total) === 0 ? false : terminalId === '' || mrPaymentPos
          }
          onClick={() => {
            setMrPaymentPos(true);
          }}
          autoFocus
        >
          Pay <small> £{Number(total).toFixed(2)}</small>
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export const getTerminals = setTerminals => {
  // * get all dojo terminals
  ApiClient.apiGet(apiEndPoints.getTerminals)
    .then(data => {
      setTerminals(data?.data?.data);
    })
    .catch(error => {
      alert(error?.response?.data?.message);
    });
};

const MeetingRoomBooking = () => {
  const classes = useStyles();
  const history = useHistory();
  const {
    newMeetingRoomsList,
    setSuccessMessage,
    buidlingLocalStorage,
    isCatering,
    paymentServiceProvider,
  } = useContext(GlobalContext); // setExtendVat
  const {
    bookingDate,
    endNewDate,
    mrRoomId,
    mrRoomName,
    setMrRoomName,
    setCreateMeetingRoom,
    disableButton,
    setBuildingId,
    village,
    setVillage,
    buildingName,
    setBuildingName,
    mrCapacity,
    setMrCapacity,
    startTime,
    customerType,
    tenant,
    firstName,
    lastName,
    phoneNumber,
    email,
    setCustomerDetailsToggle,
    setFullDayPrice,
    setHourlyPrice,
    activeStep,
    setActiveStep,
    mrBookingId,
    setMinimumBookingTime,
    setMrPayment,
    mrPaymentPos,
    setMrPaymentPos,
    mrPaymentPosMessage,
    mrCardPayment,
    setCardPayment,
    mrBookingPrice,
    cartItems,
    setCreateCateringToggle,
    vat,
    setVat,
    cateringSubTotal,
    subTotal,
    total,
    selectedMenuItems,
    selectedTenantUser,
    discountPrice,
    discountPriceLable,
    bookingType,
    squarePayments,
    setCircularLoading,
    circularLoading,
    terminalId,
    setTerminalId,
    terminals,
    setTerminals,
    loaderNotification,
  } = useContext(MrBookingContext);

  const steps = getSteps();
  const [mrBooikingStatus, setMrBooikingStatus] = useState(false);
  const [warningPopUp, setWarningPopUp] = useState(false);
  const [minutes, setMinutes] = useState('00');
  const [seconds, setSeconds] = useState('00');
  const [timeOut, setTimeOut] = useState(false);
  const [isExpanded, setIsExpanded] = useState('');
  const payToAccountCheck = localStorage.getItem('payToAccount');
  const [cardDetails, setCardDetails] = useState([]);
  const [selectedCard, setSelectedCard] = useState('');
  const [savedCardId, setCardId] = useState('');
  const [open, setOpen] = React.useState(false);
  const [openTerminalDialog, setOpenTerminalDialog] = useState();
  const [openSendLinkDialog, setOpenSendLinkDialog] = useState(false);
  const [mrPaymentLink, setMrPaymentLink] = useState(false);
  const [userEmail, setUserEmail] = useState('');
  const [userConfirmEmail, setUserConfirmEmail] = useState('');
  const [errorMsgs, setErrorMsgs] = useState(null);

  const handleChange = panel => (event, expanded) => {
    setIsExpanded(expanded ? panel : false);
  };
  const handleDialogClose = () => {
    setOpen(false);
    setCardId('');
  };
  const handleTerminalDialogClose = () => {
    setOpenTerminalDialog(false);
    setTerminalId('');
  };
  const endDateTime = new Date(
    `${moment(bookingDate).format('ddd MMM DD YYYY')} ${endNewDate}`,
  );
  const startDateTime = new Date(
    `${moment(bookingDate).format('ddd MMM DD YYYY')} ${startTime}`,
  );
  const errorType = errorMsgs?.type;
  const errorFieldName = errorMsgs?.path;
  const londonHours = moment(new Date()).tz(timeZone.london).format('HH');
  const londonMinutes = moment(new Date()).tz(timeZone.london).format('MM');
  const currentIso = moment(new Date()).set({hours:londonHours,minutes:londonMinutes})

  const startDate = moment(startDateTime);
  const timeDiff = moment.duration(startDate.diff(currentIso)).asHours();
  const popUpOpen = () => {
    setWarningPopUp(true);
  };

  const popUpClose = () => {
    setWarningPopUp(false);
  };
  const handleSendLinkDialogClose = () => {
    setOpenSendLinkDialog(false);
  };
  let interval;
  const currentTime = meetingRoomBookingTimer;
  let countDownTime = currentTime * 60;

  const startTimer = () => {
    interval = setInterval(() => {
      const timeInMinutes = Math.floor(countDownTime / 60);
      const timeInSeconds = Math.floor(countDownTime % 60);
      countDownTime--;
      if (timeInMinutes < 0) {
        clearInterval(interval.current);
        setTimeOut(true);
        popUpOpen();
      } else {
        setMinutes(timeInMinutes);
        setSeconds(timeInSeconds);
      }
    }, 1000);
  };

  useEffect(() => {
    if (mrBookingId) {
      startTimer();
    }
    if (!mrRoomId) {
      if (buidlingLocalStorage) history.push('./');
    }
  }, [mrBookingId, !mrRoomId]);

  useEffect(() => {
    const meetingRoomData =
      newMeetingRoomsList && newMeetingRoomsList.filter(x => x.id === mrRoomId);
    if (newMeetingRoomsList && newMeetingRoomsList.length > 0) {
      const buildingObj = meetingRoomData && meetingRoomData[0].building;
      setBuildingId(buildingObj.id);
      setBuildingName(buildingObj.name);
      setVillage(buildingObj.village);
    }
  }, [mrRoomId]);

  useEffect(() => {
    const roomId =
      newMeetingRoomsList &&
      newMeetingRoomsList.filter(data => data.id === mrRoomId);
    setFullDayPrice(roomId && roomId[0] && roomId[0].fullDayPrice);
    setMrCapacity(roomId && roomId[0] && roomId[0].maxCapacity);
    setHourlyPrice(roomId && roomId[0] && roomId[0].hourlyPrice);
    setMinimumBookingTime(roomId && roomId[0] && roomId[0].minimumBookingTime);
    setMrRoomName(roomId && roomId[0] && roomId[0].name);
  }, [mrRoomId]);

  useEffect(() => {
    if (
      mrBookingPrice.length !== 0 &&
      mrBookingPrice !== 'NaN' &&
      vat.length === 0
    ) {
      ApiClient.apiGet(
        `${apiEndPoints.getVat}${moment(bookingDate).format('YYYY-MM-DD')}`,
      )
        .then(data => {
          setVat(data && data.data && data.data.data);
        })
        .catch(error => {
          console.log(error, 'error');
        });
    }
    if (mrBooikingStatus) {
      setCircularLoading(true);
      const payload = {
        status: 'Abandon',
      };
      ApiClient.apiPut(
        `${apiEndPoints.cancelMrBooking}${mrBookingId}/status`,
        payload,
      )
        .then(data => {
          setSuccessMessage(data && data.data.message);
          setCircularLoading(false);
          setMrPayment(false);
          if (buidlingLocalStorage) history.push('./');
          localStorage.removeItem('payToAccount');
        })
        .catch(error => {
          setMrBooikingStatus(false);
          setCircularLoading(false);
          setMrPayment(false);
          alert(error);
        });
    }
  }, [mrBooikingStatus, bookingDate, mrBookingPrice, vat]);

  // * Send payment link api call
  useEffect(() => {
    if (!mrPaymentLink) return;
    setErrorMsgs('');
    setCircularLoading(true);
    sendLinkSchema
      .validate({
        userEmail,
        userConfirmEmail,
      })
      .then(() => {
        ApiClient.apiPut(`${apiEndPoints.payLink}/${mrBookingId}`, {
          email: userEmail,
          roomDiscount: Number(discountPrice) || 0,
          roomDiscountLable:discountPriceLable,
        })
          .then(data => {
            setCircularLoading(false);
            setSuccessMessage(data?.data?.message);
            history.push('/bookinglist');
          })
          .catch(error => {
            setCircularLoading(false);
            setMrPaymentLink(false);
            alert(error?.response?.data?.message || messages.notExtended);
          });
      })
      .catch(err => {
        // * validation errors set
        setErrorMsgs(err);
        setCircularLoading(false);
        setMrPaymentLink(false);
      });
  }, [mrPaymentLink]);

  const handleNext = () => {
    setCreateMeetingRoom(true);
    if (mrBookingId && activeStep === steps.length - 2) {
      setCustomerDetailsToggle(true);
    }
    if (mrBookingId) setCreateCateringToggle(true);
    if (
      activeStep === steps.length - 2 &&
      customerType !== 'Guest' &&
      selectedTenantUser
    ) {
      ApiClient.apiGet(`${apiEndPoints.extendSavedCards}/${selectedTenantUser}`)
        .then(data => {
          setCardDetails(data && data.data && data.data.data);
        })
        .catch(error => {
          alert(error);
        });
    }
  };
  const handleConfirmBlock = () => {
    setCreateMeetingRoom(true);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const getTokenForCard = async (card, amount) => {
    const verificationDetails = {
      amount: `${amount}`,
      billingContact: {
        familyName: lastName,
        givenName: firstName,
        email: email,
        country: 'GB',
        phone: phoneNumber,
        postalCode:
          card && card.billingAddress && card.billingAddress.postalCode,
      },
      currencyCode: 'GBP',
      intent: 'CHARGE',
    };
    setOpen(false);
    const token = await squarePayments.verifyBuyer(
      card.id,
      verificationDetails,
    );
    return token.token;
  };

  const handleClickOpen = () => {
    setOpen(true);
    // onSavedCards();
  };

  const OnCompletePayment = async () => {
    setCircularLoading(true);
    let payload;
    if (!paymentServiceProvider) {
      const token = await getTokenForCard(selectedCard, total);
      payload = {
        roomDiscount: Number(discountPrice),
        cardId: savedCardId,
        roomDiscountLable:discountPriceLable,
        token: token,
      };
    } else {
      payload = {
        roomDiscount: Number(discountPrice),
        roomDiscountLable:discountPriceLable,
        setupIntentId: savedCardId,
      };
    }
    ApiClient.apiPut(
      `${apiEndPoints.createMrBooking}/${mrBookingId}/complete/cardAdmin`,
      payload,
    )
      .then(data => {
        setCircularLoading(false);
        setSuccessMessage(data && data.data.message);
        history.push('/bookinglist');
      })
      .catch(err => {
        setCardPayment(false);
        setCardId('');  
        setCircularLoading(false);
        alert(
          err && err.response && err.response.data && err.response.data.message,
        );
      });
  };

  const getCardsDetails = () => {
    // * SQUARE PAYMENT Cards
    if (!paymentServiceProvider) {
      return (
        cardDetails &&
        cardDetails.map(card => (
          <>
            {card && card.cardBrand === cardType.visa && (
              <li
                key={card.id}
                className={`${savedCardId === card.id ? 'cardActive' : ''}`}
              >
                <Button
                  onClick={() => {
                    setCardId(card.id);
                    setSelectedCard(card);
                  }}
                >
                  <p>
                    <img src={cardLogo1} alt="cardLogo1" />
                    Visa •••• <span>{card.last4}</span>
                  </p>
                  <small>
                    Expires {card.expMonth}/{card.expYear}
                  </small>
                </Button>
              </li>
            )}
            {card && card.cardBrand === cardType.mastercard && (
              <li
                key={card.id}
                className={`${savedCardId === card.id ? 'cardActive' : ''}`}
              >
                <Button
                  onClick={() => {
                    setCardId(card.id);
                    setSelectedCard(card);
                  }}
                >
                  <p>
                    <img src={cardLogo2} alt="cardLogo2" />
                    Mastercard •••• <span>{card.last4}</span>
                  </p>
                  <small>
                    Expires {card.expMonth}/{card.expYear}
                  </small>
                </Button>
              </li>
            )}
            {card && card.cardBrand === cardType.americanExpress && (
              <li
                key={card.id}
                className={`${savedCardId === card.id ? 'cardActive' : ''}`}
              >
                <Button
                  onClick={() => {
                    setCardId(card.id);
                    setSelectedCard(card);
                  }}
                >
                  <p>
                    <img src={cardLogo3} alt="cardLogo4" />
                    Amex •••• <span>{card.last4}</span>
                  </p>
                  <small>
                    Expires {card.expMonth}/{card.expYear}
                  </small>
                </Button>
              </li>
            )}
            {card && card.cardBrand === cardType.applePay && (
              <li
                key={card.id}
                className={`${savedCardId === card.id ? 'cardActive' : ''}`}
              >
                <Button
                  onClick={() => {
                    setCardId(card.id);
                    setSelectedCard(card);
                  }}
                >
                  <p>
                    <img src={cardLogo4} alt="cardLogo4" />
                    ApplePay •••• <span>{card.last4}</span>
                  </p>
                  <small>
                    Expires {card.expMonth}/{card.expYear}
                  </small>
                </Button>
              </li>
            )}
            {card && card.cardBrand === cardType.googlePay && (
              <li
                key={card.id}
                className={`${savedCardId === card.id ? 'cardActive' : ''}`}
              >
                <Button
                  onClick={() => {
                    setCardId(card.id);
                    setSelectedCard(card);
                  }}
                >
                  <img src={cardLogo5} alt="cardLogo4" />
                  <p>
                    GooglePay •••• <span>{card.last4}</span>
                  </p>
                </Button>
              </li>
            )}
          </>
        ))
      );
    }
    // * DOJO PAYMENT Cards
    return (
      cardDetails &&
      cardDetails.map(card => (
        <>
          {card && card.cardType === cardType.visa && (
            <li
              key={card.setupIntentId}
              className={`${
                savedCardId === card.setupIntentId ? 'cardActive' : ''
              }`}
            >
              <Button
                onClick={() => {
                  setCardId(card.setupIntentId);
                }}
              >
                <p>
                  <img src={cardLogo1} alt="cardLogo1" />
                  Visa •••• <span>{card.cardNumber.slice(-4)}</span>
                </p>
                <small>
                  Expires {new Date(card.expiryDate).getMonth()}/
                  {new Date(card.expiryDate).getFullYear()}
                </small>
              </Button>
            </li>
          )}
          {card && card.cardType === cardType.mastercard && (
            <li
              key={card.setupIntentId}
              className={`${
                savedCardId === card.setupIntentId ? 'cardActive' : ''
              }`}
            >
              <Button
                onClick={() => {
                  setCardId(card.setupIntentId);
                }}
              >
                <p>
                  <img src={cardLogo2} alt="cardLogo2" />
                  Mastercard •••• <span>{card.cardNumber.slice(-4)}</span>
                </p>
                <small>
                  Expires {new Date(card.expiryDate).getMonth()}/
                  {new Date(card.expiryDate).getFullYear()}
                </small>
              </Button>
            </li>
          )}
          {card && card.cardType === cardType.americanExpress && (
            <li
              key={card.setupIntentId}
              className={`${
                savedCardId === card.setupIntentId ? 'cardActive' : ''
              }`}
            >
              <Button
                onClick={() => {
                  setCardId(card.setupIntentId);
                }}
              >
                <p>
                  <img src={cardLogo3} alt="cardLogo4" />
                  Amex •••• <span>{card.cardNumber.slice(-4)}</span>
                </p>
                <small>
                  Expires {new Date(card.expiryDate).getMonth()}/
                  {new Date(card.expiryDate).getFullYear()}
                </small>
              </Button>
            </li>
          )}
          {card && card.cardType === cardType.applePay && (
            <li
              key={card.setupIntentId}
              className={`${
                savedCardId === card.setupIntentId ? 'cardActive' : ''
              }`}
            >
              <Button
                onClick={() => {
                  setCardId(card.setupIntentId);
                }}
              >
                <p>
                  <img src={cardLogo4} alt="cardLogo4" />
                  ApplePay •••• <span>{card.cardNumber.slice(-4)}</span>
                </p>
                <small>
                  Expires {new Date(card.expiryDate).getMonth()}/
                  {new Date(card.expiryDate).getFullYear()}
                </small>
              </Button>
            </li>
          )}
          {card && card.cardType === cardType.googlePay && (
            <li
              key={card.setupIntentId}
              className={`${
                savedCardId === card.setupIntentId ? 'cardActive' : ''
              }`}
            >
              <Button
                onClick={() => {
                  setCardId(card.setupIntentId);
                }}
              >
                <img src={cardLogo5} alt="cardLogo4" />
                <p>
                  GooglePay •••• <span>{card.cardNumber.slice(-4)}</span>
                </p>
              </Button>
            </li>
          )}
        </>
      ))
    );
  };
  const breadCrumbs = {
    listBreadCrumbPath: '/',
    listBreadCrumbTitle: 'Meeting Room Bookings',
    addBreadCrumbPath: '/',
    addBreadCrumbTitle: 'New Booking',
  };

  if (!(localStorage && localStorage.getItem('adal.session.state'))) {
    logOut();
  }
  const timeValue = '00:00';

  return (
    <>
      <Backdrop className="loaderZindex" open={circularLoading}>
        <CircularProgress color="inherit" />
        {mrPaymentPos ? (
          <Typography variant="body2" style={{ marginTop: '7vw' }}>
            {loaderNotification
              .toLowerCase()
              .split('_')
              .map(word => word.charAt(0).toUpperCase() + word.slice(1))
              .join(' ')}
          </Typography>
        ) : (
          ''
        )}
      </Backdrop>
      <div className={classes.pageListMain}>
        <div className="pageBredcrumbTimer">
          <Breadcrumb breadCrumbs={breadCrumbs} />
          {activeStep !== steps.length - 4 && (
            <div
              className={`${classes.bookingTimeLeft} ${
                minutes < 2 &&
                minutes !== '00' &&
                seconds !== '00' &&
                classes.bookingTimeWarning
              }`}
            >
              {!mrPaymentPos
                ? `Booking has ${minutes < 10 ? `0${minutes}` : minutes}:${
                    seconds < 10 ? `0${seconds}` : seconds
                  } minutes left`
                : `Booking has ${timeValue} minutes left`}
            </div>
          )}
        </div>
        <Divider light />
        <div className="pageContainer mrBookingPage">
          <div className="mrBookingPageIn">
            <div className="mrBookingLft">
              <div className="mrBookingLftIn">
                <>
                  {bookingType === 'Standard' ? (
                    <Stepper
                      className="mrBooking"
                      activeStep={
                        isCatering || activeStep === 0
                          ? activeStep
                          : activeStep - 1
                      }
                      alternativeLabel
                    >
                      {!isCatering
                        ? steps
                            .filter(label => label !== 'Catering')
                            .map(label => (
                              <Step
                                key={label}
                                className="mrBookingStepsWithoutCatering"
                              >
                                <StepLabel className="mrBookingStepsLabel">
                                  {label}
                                </StepLabel>
                              </Step>
                            ))
                        : steps.map(label => (
                            <Step key={label} className="mrBookingSteps">
                              <StepLabel className="mrBookingStepsLabel">
                                {label}
                              </StepLabel>
                            </Step>
                          ))}
                    </Stepper>
                  ) : (
                    <Stepper
                      className="mrBooking"
                      activeStep={activeStep}
                      alternativeLabel
                    >
                      <Step key={1} className="mrBookingSteps">
                        <StepLabel className="mrBookingStepsLabel">
                          Meeting Room
                        </StepLabel>
                      </Step>
                    </Stepper>
                  )}
                </>
                <div>
                  {activeStep === steps.length ? (
                    <div>
                      <Typography className={classes.instructions}>
                        All steps completed
                      </Typography>
                      <Button onClick={handleReset}>Reset</Button>
                    </div>
                  ) : (
                    <div className="BookingStepAll">
                      {getStepContent(activeStep)}
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="mrBookingRgt">
              <div className="mrBookingRgtSticky">
                <div className="mrBookingAccordion">
                  {bookingType === 'Standard' ? (
                    <>
                      <Accordion
                        expanded={
                          isExpanded === 'panel1' ||
                          activeStep === steps.length - 4
                        }
                        onChange={handleChange('panel1')}
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography className={classes.heading}>
                            Meeting Room
                          </Typography>
                          {mrBookingPrice && mrBookingPrice.length !== 0 && (
                            <div className="headingPrice">
                              <span>
                                {mrBookingPrice &&
                                  `£${Number(mrBookingPrice).toFixed(2)}`}
                              </span>
                            </div>
                          )}
                        </AccordionSummary>
                        <AccordionDetails>
                          <ul>
                            <li>
                              {mrRoomName}
                              <span>({mrCapacity})</span>{' '}
                            </li>
                            <li>{`${buildingName}, ${village}`}</li>
                            <li>
                              {moment(startDateTime).format(
                                'dddd Do MMMM, YYYY',
                              ) === 'Invalid date'
                                ? ''
                                : moment(startDateTime).format(
                                    'dddd Do MMMM, YYYY',
                                  )}
                            </li>
                            <li>
                              {`${
                                moment(startDateTime).format(
                                  'dddd Do MMMM, YYYY',
                                ) === 'Invalid date'
                                  ? ''
                                  : moment(startDateTime).format('h:mm a')
                              }${
                                moment(endDateTime).format('h:mm a') ===
                                'Invalid date'
                                  ? ''
                                  : ` - ${moment(endDateTime).format('h:mm a')}`
                              }`}{' '}
                            </li>
                            {selectedMenuItems &&
                              selectedMenuItems.map(menuItem => (
                                <>
                                  {menuItem && menuItem.capacity > 0 && (
                                    <li key={menuItem.categoryId}>
                                      {`x${menuItem.capacity} ${
                                        menuItem &&
                                        menuItem.menu &&
                                        menuItem.menu
                                          .map(menu => menu.itemName)
                                          .toString()
                                          .split(',')
                                          .join(', ')
                                      }`}
                                    </li>
                                  )}
                                </>
                              ))}
                          </ul>
                        </AccordionDetails>
                      </Accordion>
                      {isCatering ? (
                        <>
                          <Accordion
                            disabled={activeStep === steps.length - 4}
                            expanded={
                              isExpanded === 'panel2' ||
                              activeStep === steps.length - 3
                            }
                            onChange={handleChange('panel2')}
                          >
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls="panel2a-content"
                              id="panel2a-header"
                            >
                              <Typography className={classes.heading}>
                                Catering
                              </Typography>
                              {cartItems && cartItems.length !== 0 && (
                                <div className="headingPrice">
                                  <span>£{cateringSubTotal.toFixed(2)}</span>
                                </div>
                              )}
                            </AccordionSummary>
                            <AccordionDetails>
                              <ul className="CateringMenuCheckout">
                                <li className="noOrdered">
                                  {cartItems &&
                                    cartItems.length === 0 &&
                                    'Nothing ordered yet.'}
                                </li>
                                {cartItems &&
                                  cartItems.map(cartItem => (
                                    <li>
                                      <h4>
                                        {`x${cartItem.quantity} ${cartItem.itemName}`}
                                      </h4>
                                      <h5>
                                        £
                                        {(
                                          cartItem.price * cartItem.quantity
                                        ).toFixed(2)}
                                      </h5>
                                    </li>
                                  ))}
                              </ul>
                            </AccordionDetails>
                          </Accordion>
                        </>
                      ) : (
                        ''
                      )}
                      <Accordion
                        disabled={
                          activeStep === steps.length - 4 ||
                          activeStep === steps.length - 3
                        }
                        expanded={
                          isExpanded === 'panel3' ||
                          activeStep === steps.length - 2
                        }
                        onChange={handleChange('panel3')}
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel3a-content"
                          id="panel3a-header"
                        >
                          <Typography className={classes.heading}>
                            Customer Details
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <ul>
                            <li>{`${firstName} ${lastName} ${
                              tenant ||
                              (firstName &&
                                lastName &&
                                customerType === 'Guest')
                                ? '-'
                                : ''
                            } ${
                              firstName && lastName && customerType === 'Guest'
                                ? 'Guest'
                                : tenant
                            }`}</li>
                            <li>{email}</li>
                            <li>{phoneNumber}</li>
                          </ul>
                        </AccordionDetails>
                      </Accordion>
                    </>
                  ) : (
                    <Accordion expanded={true || true}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography className={classes.heading}>
                          Meeting Room
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <ul>
                          <li>
                            {mrRoomName}
                            <span>({mrCapacity})</span>{' '}
                          </li>
                          <li>{`${buildingName}, ${village}`}</li>
                          <li>
                            {moment(startDateTime).format(
                              'dddd Do MMMM, YYYY',
                            ) === 'Invalid date'
                              ? ''
                              : moment(startDateTime).format(
                                  'dddd Do MMMM, YYYY',
                                )}
                          </li>
                          <li>
                            {`${
                              moment(startDateTime).format(
                                'dddd Do MMMM, YYYY',
                              ) === 'Invalid date'
                                ? ''
                                : moment(startDateTime).format('h:mm a')
                            }${
                              moment(endDateTime).format('h:mm a') ===
                              'Invalid date'
                                ? ''
                                : ` - ${moment(endDateTime).format('h:mm a')}`
                            }`}{' '}
                          </li>
                          {selectedMenuItems &&
                            selectedMenuItems.map(menuItem => (
                              <>
                                {menuItem && menuItem.capacity > 0 && (
                                  <li key={menuItem.categoryId}>
                                    {`x${menuItem.capacity} ${
                                      menuItem &&
                                      menuItem.menu &&
                                      menuItem.menu
                                        .map(menu => menu.itemName)
                                        .toString()
                                        .split(',')
                                        .join(', ')
                                    }`}
                                  </li>
                                )}
                              </>
                            ))}
                        </ul>
                      </AccordionDetails>
                    </Accordion>
                  )}
                  <div className="bookingToatalBtm">
                    <div className={`${classes.formButton} booingButton`}>
                      <Button
                        onClick={popUpOpen}
                        className={classes.backButton}
                        disabled={disableButton}
                      >
                        Cancel Block
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        disabled={disableButton}
                        onClick={() => {
                          handleConfirmBlock();
                        }}
                      >
                        Confirm block
                      </Button>

                      <Dialog
                        open={warningPopUp}
                        onClose={popUpClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                        className="cancelDialogOpend"
                      >
                        <div className={classes.cancelDialog}>
                          <h2>Are you sure you want to cancel block?</h2>
                          <p className={classes.errorColor}>
                            This will delete all records created and the time
                            slot
                          </p>
                          <p className={classes.errorColor}>
                            will be made available again.
                          </p>
                        </div>
                        <Divider className="formDivider popupDivider" light />
                        <DialogActions
                          className={`${classes.formButton} popupFormButton`}
                        >
                          {!timeOut && (
                            <Button
                              onClick={() => setWarningPopUp(false)}
                              color="primary"
                            >
                              Continue Block
                            </Button>
                          )}
                          <Button
                            className="popupFormButtonLast"
                            onClick={() => history.push('./')}
                            autoFocus
                          >
                            Yes
                          </Button>
                        </DialogActions>
                      </Dialog>
                    </div>
                  </div>
                </div>

                {bookingType === 'Standard' ? (
                  <div className="bookingToatalBtm">
                    <div className="bookingToatal">
                      {subTotal > 0 && (
                        <ul>
                          <li>Subtotal</li>
                          <li>
                            <span>£{Number(subTotal).toFixed(2)}</span>
                          </li>
                        </ul>
                      )}
                      <Divider className="formDivider fullWidth" light />
                      {subTotal > 0 && total > 0 && (
                        <ul>
                          <li>VAT</li>
                          <li>
                            <span>£{Number(total - subTotal).toFixed(2)}</span>
                          </li>
                        </ul>
                      )}
                      <Divider className="formDivider fullWidth" light />
                      {total > 0 && (
                        <ul>
                          <li>Total</li>
                          <li>
                            <span>£{Number(total).toFixed(2)}</span>
                          </li>
                        </ul>
                      )}
                    </div>
                    {mrPaymentPosMessage && (
                      <div className="bookingToatal">
                        <Divider className="formDivider fullWidth" light />
                        <ul>
                          <li>Status</li>
                          <li>
                            <span>{mrPaymentPosMessage}</span>
                          </li>
                        </ul>
                      </div>
                    )}
                    <Divider className="formDivider fullWidth" light />
                    <div className={`${classes.formButton} booingButton`}>
                      {activeStep !== steps.length - 1 && (
                        <>
                          <Button
                            onClick={popUpOpen}
                            className={classes.backButton}
                          >
                            Cancel
                          </Button>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() => {
                              handleNext();
                            }}
                          >
                            {(activeStep === steps.length - 3 &&
                              cartItems.length === 0 &&
                              'Skip') ||
                              'Next'}
                          </Button>
                        </>
                      )}
                      {activeStep === steps.length - 1 && (
                        <div className="orderPreviewBtn">
                          <Button
                            disabled={mrPaymentPos || circularLoading}
                            onClick={() => {
                              handleClickOpenTerminals(
                                paymentServiceProvider,
                                setOpenTerminalDialog,
                                setMrPaymentPos,
                                setTerminals,
                              );
                            }}
                          >
                            Terminal
                          </Button>
                          {
                            timeDiff < hour.ninetyDays && (
                              <Button
                                className="savedCardBtn"
                                variant="contained"
                                color="primary"
                                disabled={
                                  timeDiff > hour.ninetyDays
                                }
                                onClick={() => setOpenSendLinkDialog(true)}
                              >
                                Send Pay Link
                              </Button>
                            )}

                          <Button
                            className="savedCardBtn"
                            variant="contained"
                            disabled={
                              (payToAccountCheck !== null &&
                                payToAccountCheck.toString() === 'false') ||
                              customerType === 'Guest' ||
                              mrPaymentPos ||
                              circularLoading
                            }
                            color="primary"
                            onClick={() => {
                              setMrPayment(true);
                            }}
                          >
                            Pay to Account
                          </Button>
                          <Button
                            className="savedCardBtn"
                            variant="contained"
                            color="primary"
                            disabled={
                              (cardDetails && cardDetails.length <= 0) ||
                              circularLoading ||
                              mrPaymentPos
                            }
                            onClick={handleClickOpen}
                          >
                            Saved Cards
                          </Button>
                          <Button
                            className="cancelPos"
                            variant="contained"
                            color="primary"
                            onClick={popUpOpen}
                            disabled={circularLoading || mrPaymentPos}
                          >
                            Cancel Booking
                          </Button>
                        </div>
                      )}
                      <div className="extendPopup savedCardsPopup">
                        <Dialog
                          open={open}
                          onClose={handleDialogClose}
                          aria-labelledby="alert-dialog-title"
                          aria-describedby="alert-dialog-description"
                          className="cancelDialogOpend savedCardPopupOpend"
                        >
                          <Button
                            className="popupCloseBtn"
                            onClick={handleDialogClose}
                          >
                            <img src={popup_close} alt="popup_close.svg" />
                          </Button>
                          <h4>Saved Cards</h4>
                          <div className="allCardList">
                            <ul className={classes.cardList}>
                              {getCardsDetails()}
                            </ul>
                          </div>
                          <DialogActions
                            className={`${classes.formButton} ${classes.popupFormButton} ${classes.extendPopupFormButton} ${classes.cardPopupFormButton}`}
                          >
                            <Button
                              // onClick={OnCompletePayment}
                              disabled={savedCardId === '' || mrCardPayment}
                              onClick={() => {
                                OnCompletePayment();
                                setCardPayment(true);
                              }}
                              autoFocus
                            >
                              Pay <small> £{Number(total).toFixed(2)}</small>
                            </Button>
                          </DialogActions>
                        </Dialog>
                      </div>
                      <Dialog
                        open={warningPopUp}
                        onClose={popUpClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                        className="cancelDialogOpend"
                      >
                        <div className={classes.cancelDialog}>
                          {!timeOut ? (
                            <>
                              <h2>Are you sure you want to cancel?</h2>
                              <p className={classes.errorColor}>
                                This will delete all records created and the
                                time slot
                              </p>
                              <p className={classes.errorColor}>
                                will be made available again.
                              </p>
                            </>
                          ) : (
                            <>
                              <h2>Booking has expired.</h2>
                              <p>
                                You will have to start your booking process
                                again.
                              </p>
                            </>
                          )}
                        </div>
                        <Divider className="formDivider popupDivider" light />
                        <DialogActions
                          className={`${classes.formButton} popupFormButton`}
                        >
                          {!timeOut && (
                            <Button
                              onClick={() => setWarningPopUp(false)}
                              color="primary"
                            >
                              Continue Booking
                            </Button>
                          )}
                          <Button
                            className="popupFormButtonLast"
                            onClick={() =>
                              activeStep === steps.length - 4 || timeOut
                                ? buidlingLocalStorage && history.push('./')
                                : setMrBooikingStatus(true)
                            }
                            autoFocus
                          >
                            {!timeOut ? 'Yes' : 'OK'}
                          </Button>
                        </DialogActions>
                      </Dialog>
                      <div className="extendPopup savedCardsPopup">
                        <TerminalDialog
                          classes={classes}
                          openTerminalDialog={openTerminalDialog}
                          handleTerminalDialogClose={handleTerminalDialogClose}
                          terminals={terminals}
                          setTerminalId={setTerminalId}
                          mrPaymentPos={mrPaymentPos}
                          terminalId={terminalId}
                          setMrPaymentPos={setMrPaymentPos}
                          total={total}
                        />
                      </div>
                      {/* send payment link popup */}
                      <div className="extendPopup savedCardsPopup">
                        <Dialog
                          open={openSendLinkDialog}
                          onClose={handleSendLinkDialogClose}
                          aria-labelledby="alert-dialog-title"
                          aria-describedby="alert-dialog-description"
                          className="cancelDialogOpend savedCardPopupOpend"
                        >
                          <Button
                            className="popupCloseBtn"
                            onClick={handleSendLinkDialogClose}
                          >
                            <img src={popup_close} alt="popup_close.svg" />
                          </Button>
                          <h4 className={`${classes.mb_20}`}>
                            Send Payment Link
                          </h4>
                          <ul className={`${classes.InputSection} `}>
                            <li
                              className={`${classes.flexNone} ${classes.mb_20}`}
                            >
                              <span>Email*</span>
                              <Input
                                type="text"
                                inputProps={{ 'aria-label': 'description' }}
                                value={userEmail}
                                onChange={e => {
                                  setErrorMsgs('');
                                  setUserEmail(e.target.value);
                                }}
                              />
                              {errorType === 'email' &&
                                errorFieldName === 'userEmail' && (
                                  <FormHelperText
                                    className={classes.errorMsg}
                                    component="div"
                                    error={messages.valueEmail}
                                  >
                                    {messages.valueEmail}
                                  </FormHelperText>
                                )}
                            </li>
                            <li
                              className={`${classes.flexNone} ${classes.mb_20} `}
                            >
                              <span>Confirm Email*</span>
                              <Input
                                type="text"
                                inputProps={{ 'aria-label': 'description' }}
                                value={userConfirmEmail}
                                onChange={e => {
                                  setErrorMsgs('');
                                  setUserConfirmEmail(e.target.value);
                                }}
                              />
                              {errorType === 'email' &&
                                errorFieldName === 'userConfirmEmail' && (
                                  <FormHelperText
                                    className={classes.errorMsg}
                                    component="div"
                                    error={messages.valueEmail}
                                  >
                                    {messages.valueEmail}
                                  </FormHelperText>
                                )}
                              {/* email ids should match */}
                              {errorType === 'oneOf' &&
                                errorFieldName === 'userConfirmEmail' && (
                                  <FormHelperText
                                    className={classes.errorMsg}
                                    component="div"
                                    error={messages.emailsMatch}
                                  >
                                    {messages.emailsMatch}
                                  </FormHelperText>
                                )}
                            </li>
                          </ul>
                          <DialogActions
                            className={`${classes.formButton} ${classes.popupFormButton} ${classes.extendPopupFormButton} ${classes.cardPopupFormButton}`}
                          >
                            <Button
                              disabled={
                                userEmail === '' || userConfirmEmail === ''
                              }
                              onClick={() => setMrPaymentLink(true)}
                              autoFocus
                            >
                              Send
                            </Button>
                          </DialogActions>
                        </Dialog>
                      </div>
                    </div>
                  </div>
                ) : (
                  ''
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default MeetingRoomBooking;
