/* eslint-disable import/no-cycle */
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import moment from 'moment';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  Input,
} from '@material-ui/core';
import editIcon from '../../images/edit-icon.svg';
import ApiClient from '../../helpers/ApiClient';
import apiEndPoints from '../../helpers/apiEndPoints';
import { GlobalContext } from '../../context/GlobalContext';
import { MrBookingContext } from '../../context/MRBookingContext';
import Ping from '../../components/Ping/Ping';
import { logOut } from '../../utils/logOut';
import {
  message,
  paymentStatus,
  verificationStatus,
  dummyTerminalId,
  discount,
} from '../../utils/constants';
import popup_close from '../../images/popup_close.svg';

function Status({
  mrPaymentPos,
  mrBookingId,
  setMrPaymentPosMessage,
  setMrPaymentPos,
  setSuccessMessage,
  history,
  paymentServiceProvider,
}) {
  Ping(() => {
    if (mrPaymentPos === true && !paymentServiceProvider) {
      ApiClient.apiGet(`${apiEndPoints.createMrBooking}/${mrBookingId}`)
        .then(data => {
          setMrPaymentPosMessage(data.data.data.payment.status);
          if (data.data.data.payment.status === 'Paid') {
            setMrPaymentPos(false);
            setSuccessMessage(message.createdSuccessfully);
            history.push('/');
          }
        })
        .catch(err => {
          console.log(err);
          setMrPaymentPos(false);
          setMrPaymentPosMessage('Unable to complete payment.');
        });
    }
  }, 5000);
  return null;
}

const useStyles = makeStyles({
  table: {
    maxWidth: '100%',
  },
  BookingStepForm: {
    margin: '0 auto',
    padding: '40px 0',
    '& ul': {
      display: 'flex',
      justifyContent: 'space-between',
      flexWrap: 'wrap',
      alignItems: 'flex-end',
      '& li': {
        listStyle: 'none',
        width: '46%',
        marginBottom: '22px',
      },
    },
  },
  extendPopupFormButton: {
    '& button': {
      background: '#000',
      color: '#fff !important',
      border: '2px solid #000',
      padding: '0 32px',
      marginTop: 20,
      textTransform: 'capitalize !important',
      '&:hover': {
        background: '#000',
        color: '#fff !important',
      },
    },
  },
  popupHeading: {
    color: '#444444',
    fontSize: '19px',
    fontFamily: 'Varta, sans-serif',
    fontWeight: 'bold',
    marginBottom: '15px',
    lineHeight: '20px',
  },
  popupText: {
    color: '#838384',
    display: 'block',
    fontSize: '16px',
    fontFamily: 'Varta,sans-serif',
    fontWeight: 'normal',
    marginTop: '-6px',
  },
});

const OrderPreview = () => {
  const classes = useStyles();
  const {
    mrRoomName,
    buildingName,
    layoutName,
    bookingDate,
    endNewDate,
    firstName,
    lastName,
    tenant,
    email,
    phoneNumber,
    notes,
    mrBookingPrice,
    mrBookingId,
    mrPayment,
    setMrPayment,
    startTime,
    mrPaymentPos,
    setMrPaymentPos,
    setMrPaymentPosMessage,
    setActiveStep,
    discountPrice,
    setDiscountPrice,
    discountPriceLable,
    setDiscountPriceLable,
    cartItems,
    setCreateCateringToggle,
    mrVatRate,
    cateringVatRate,
    mrSubTotal,
    mrVat,
    roomTotal,
    cateringSubTotal,
    cateringVat,
    cateringSubTotalWithVat,
    total,
    selectedMenuItems,
    customerType,
    circularLoading,
    setCircularLoading,
    terminalId,
    setLoaderNotification,
  } = useContext(MrBookingContext);
  const [timeoutPopup, setTimeoutPopup] = useState(false);
  const [signatureAlert, setSignatureAlert] = useState(false);
  const [posRequestId, setRequestId] = useState('');
  const {
    setSuccessMessage,
    buidlingLocalStorage,
    isCatering,
    paymentServiceProvider,
  } = useContext(GlobalContext);
  const history = useHistory();
  const [discountToggle, setDiscountToggle] = useState(false);

  useEffect(() => {
    if (mrPayment) {
      setCircularLoading(true);
      const payload = {
        paymentType: 'PayByAccount',
        roomDiscount: Number(discountPrice),
        roomDiscountLable: discountPriceLable,
      };
      ApiClient.apiPut(
        `${apiEndPoints.createMrBooking}/${mrBookingId}/complete`,
        payload,
      )
        .then(data => {
          setSuccessMessage(data && data.data.message);
          if (buidlingLocalStorage) {
            // ? history.push(`./calender-bookings/${buidlingLocalStorage.toString()}`)
            history.push('/');
          }
          localStorage.removeItem('payToAccount');
          setCircularLoading(false);
          setMrPaymentPos(false);
        })
        .catch(err => {
          setMrPayment(false);
          setCircularLoading(false);
          setMrPaymentPos(false);
          alert(
            err &&
              err.response &&
              err.response.data &&
              err.response.data.message,
          );
        });
    }
  }, [mrPayment]);

  const posPaymentStatus = requestId => {
    setCircularLoading(true);
    const intervalId = setInterval(() => {
      ApiClient.apiPost(apiEndPoints.getPosPaymentStatus, {
        terminalId,
        requestId,
      })
        .then(data => {
          const notifications = data?.data?.data?.notifications[0];
          setRequestId(requestId);
          setLoaderNotification(data?.data?.data?.notifications[0]);
          const transactionResult = data?.data?.data?.transactionResult;
          if (
            transactionResult &&
            transactionResult.toLowerCase() === verificationStatus.successful
          ) {
            clearInterval(intervalId);
            handleSuccessfulTransaction(requestId);
          } else if (
            transactionResult &&
            transactionResult.toLowerCase() === verificationStatus.timed_out
          ) {
            clearInterval(intervalId);
            handleTimeOutTransaction(data);
          } else if (transactionResult) {
            clearInterval(intervalId);
            setMrPaymentPosMessage(transactionResult);
            setMrPaymentPos(false);
            setCircularLoading(false);
            alert(transactionResult);
          } else if (
            notifications === verificationStatus.signatureVerification ||
            notifications === verificationStatus.signatureProgress
          ) {
            clearInterval(intervalId);
            setCircularLoading(false);
            setSignatureAlert(true);
          }
        })
        .catch(err => {
          clearInterval(intervalId);
          handleError(err);
        });
    }, 2000);

    const handleTimeOutTransaction = data => {
      console.log(data);
      setTimeoutPopup(true);
      setCircularLoading(false);
    };

    const handleError = err => {
      setMrPaymentPosMessage(
        err?.response?.data?.message || paymentStatus.failed,
      );
      setMrPaymentPos(false);
      setCircularLoading(false);
      alert(err?.response?.data?.message);
    };
  };

  const handleSuccessfulTransaction = requestId => {
    ApiClient.apiPut(
      `${apiEndPoints.createMrBooking}/${mrBookingId}/complete/pos`,
      {
        roomDiscount: Number(discountPrice) || 0,
        roomDiscountLable: discountPriceLable,
        terminalId: terminalId,
        requestId,
      },
    )
      .then(() => {
        fullyDiscountedBooking();
      })
      .catch(err => {
        setMrPaymentPosMessage(
          err?.response?.data?.message || paymentStatus.failed,
        );
        setMrPaymentPos(false);
        setCircularLoading(false);
        alert(err?.response?.data?.message);
      });
  };

  const fullyDiscountedBooking = () => {
    setSuccessMessage(message.createdSuccessfully);
    setCircularLoading(false);
    alert('Payment Successful');
    history.push('/bookinglist');
  };

  const handleTerminalDialogClose = () => {
    setTimeoutPopup(false);
    setMrPaymentPos(false);
  };

  const verifySignatureCall = accepted => {
    setSignatureAlert(false);
    setCircularLoading(true);

    ApiClient.apiPut(`${apiEndPoints.verifyPosSignature}`, {
      accepted,
      terminalId: terminalId,
      requestId: posRequestId,
    })
      .then(() => {
        posPaymentStatus(posRequestId);
      })
      .catch(() => {
        posPaymentStatus(posRequestId);
      });
  };

  useEffect(() => {
    if (!mrPaymentPos) {
      return;
    }
    if (!paymentServiceProvider) {
      ApiClient.apiPut(
        `${apiEndPoints.createMrBooking}/${mrBookingId}/complete/pos`,
        {
          roomDiscount: Number(discountPrice) || 0,
          roomDiscountLable: discountPriceLable,
        },
      )
        .then(data => {
          setMrPaymentPosMessage(
            data && data.data && data.data.data && data.data.data.status,
          );
          setCircularLoading(false);
        })
        .catch(err => {
          setMrPaymentPosMessage(
            err?.response?.data?.message || paymentStatus.failed,
          );
          setMrPaymentPos(false);
          setCircularLoading(false);
          alert(err?.response?.data?.message);
        });
    } else {
      setCircularLoading(true);
      setLoaderNotification('PROCESSING');
      ApiClient.apiPut(
        `${apiEndPoints.createMrBooking}/${mrBookingId}/complete/pos`,
        {
          roomDiscount: Number(discountPrice) || 0,
          roomDiscountLable:discountPriceLable,
          terminalId: terminalId || dummyTerminalId,
        },
      )
        .then(data => {
          if (data.status === 200) fullyDiscountedBooking();
          else if (data.status === 202)
            posPaymentStatus(data?.data?.data?.requestId);
        })
        .catch(err => {
          setMrPaymentPosMessage(
            err?.response?.data?.message || paymentStatus.failed,
          );
          setMrPaymentPos(false);
          setCircularLoading(false);
          alert(err?.response?.data?.message);
        });
    }
  }, [mrPaymentPos]);

  function createRow(desc, qty, price, totalPrice) {
    return { desc, qty, price, totalPrice };
  }

  const endDateTime = new Date(
    `${moment(bookingDate).format('ddd MMM DD YYYY')} ${endNewDate}`,
  );
  const startDateTime = new Date(
    `${moment(bookingDate).format('ddd MMM DD YYYY')} ${startTime}`,
  );

  const meetingRoomDetails = [
    createRow(
      `${mrRoomName} - ${layoutName} - ${buildingName} - ${moment(
        bookingDate,
      ).format('DD MMM YYYY')} - ${moment(startDateTime).format(
        'h:mm a',
      )} - ${moment(endDateTime).format('h:mm a')}`,
      '--',
      '--',
      Number(mrBookingPrice),
    ),
  ];

  const cateringDetails = [createRow('No catering ordered')];

  if (!(localStorage && localStorage.getItem('adal.session.state'))) {
    logOut();
  }

  return (
    <div className={`${classes.BookingStepForm}`}>
      {circularLoading ? (
        <CircularProgress color="inherit" />
      ) : (
        <>
          <div className="orderMainTable">
            <Status
              mrPaymentPos={mrPaymentPos}
              mrBookingId={mrBookingId}
              setMrPaymentPosMessage={setMrPaymentPosMessage}
              setMrPaymentPos={setMrPaymentPos}
              setSuccessMessage={setSuccessMessage}
              history={history}
              paymentServiceProvider={paymentServiceProvider}
            />
            <TableContainer component={Paper} className="orderTable">
              <Table className={classes.table} aria-label="spanning table">
                <TableHead>
                  <TableRow>
                    <TableCell>Meeting Room</TableCell>
                    <TableCell align="left">Qty</TableCell>
                    <TableCell align="right">Price </TableCell>
                    <TableCell align="right">Total</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody className="orderPCont">
                  {meetingRoomDetails.map(meetingRoomDetail => (
                    <TableRow key={meetingRoomDetail.desc}>
                      <TableCell>{meetingRoomDetail.desc}</TableCell>
                      <TableCell align="left">
                        {meetingRoomDetail.qty}
                      </TableCell>
                      <TableCell align="right">
                        {meetingRoomDetail.price === '--'
                          ? '--'
                          : `£${meetingRoomDetail.price.toFixed(2)}`}
                      </TableCell>
                      <TableCell align="right">
                        £{meetingRoomDetail.totalPrice.toFixed(2)}
                      </TableCell>
                    </TableRow>
                  ))}
                  {selectedMenuItems &&
                    selectedMenuItems.map(selectedMenuItem => (
                      <>
                        {selectedMenuItem && selectedMenuItem.capacity > 0 && (
                          <TableRow key={selectedMenuItem.categoryId}>
                            <TableCell>{`${selectedMenuItem.categoryName} - ${
                              selectedMenuItem &&
                              selectedMenuItem.menu &&
                              selectedMenuItem.menu
                                .map(menuItem => menuItem.itemName)
                                .toString()
                                .split(',')
                                .join(', ')
                            }`}</TableCell>
                            <TableCell align="left">
                              {selectedMenuItem.capacity}
                            </TableCell>
                            <TableCell align="right">
                              {(0).toFixed(2)}
                            </TableCell>
                            <TableCell align="right">
                              £{(0).toFixed(2)}
                            </TableCell>
                          </TableRow>
                        )}
                      </>
                    ))}
                </TableBody>

                <TableBody className="orderPBreakdown">
                  <TableRow className="orderDiscount">
                    <TableCell align="left">&nbsp;</TableCell>
                    <TableCell
                      align="left"
                      onClick={() => setDiscountToggle(true)}
                    >
                      <Input
                        type="text"
                        value={discountPriceLable}
                        onChange={e => setDiscountPriceLable(e.target.value)}
                      />
                    </TableCell>
                    <TableCell colSpan={2} align="right">
                      <div className="discountBox">
                        {discountToggle ? (
                          <>
                            <small>£</small>
                            <Input
                              type="text"
                              autoFocus
                              inputProps={{ step: '0.00' }}
                              value={`${
                               (discountPriceLable===discount) && Number(discountPrice) > Number(mrBookingPrice)
                                  ? 0
                                  : `${
                                      discountPrice !== 0
                                        ? discountPrice.replace(/^0+/, '')
                                        : ''
                                    }`
                              }`}
                              onChange={e => {
                                const re = /^[0-9]*\.?[0-9]*$/;
                                if (
                                  e.target.value === '' ||
                                  re.test(e.target.value)
                                ) {
                                  setDiscountPrice(e.target.value);
                                }
                              }}
                            />
                          </>
                        ) : (
                          `£${Number(discountPrice).toFixed(2)}`
                        )}
                      </div>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left">&nbsp;</TableCell>
                    <TableCell align="left">Subtotal</TableCell>
                    <TableCell colSpan={2} align="right">
                      £{mrSubTotal && mrSubTotal.toFixed(2)}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left">&nbsp;</TableCell>
                    <TableCell align="left">VAT @ {mrVatRate}%</TableCell>
                    <TableCell colSpan={2} align="right">
                      £{mrVat && mrVat.toFixed(2)}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left">&nbsp;</TableCell>
                    <TableCell align="left">Room Total</TableCell>
                    <TableCell colSpan={2} align="right">
                      £{Number(roomTotal).toFixed(2)}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </div>
          <div className="orderNotes">
            Notes:
            <span>{notes} </span>
          </div>
          {isCatering ? (
            <div className="cateringMainTable">
              <TableContainer component={Paper} className="orderTable">
                <Table className={classes.table} aria-label="spanning table">
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        Catering{' '}
                        <img
                          src={editIcon}
                          alt="editIcon"
                          role="presentation"
                          onClick={() => {
                            setCreateCateringToggle(false);
                            setActiveStep(prevActiveStep => prevActiveStep - 2);
                          }}
                        />
                      </TableCell>
                      {cartItems && cartItems.length > 0 && (
                        <>
                          <TableCell align="left">Qty</TableCell>
                          <TableCell align="right">Price </TableCell>
                          <TableCell align="right">Total</TableCell>
                        </>
                      )}
                    </TableRow>
                  </TableHead>
                  {cartItems &&
                    cartItems.length === 0 &&
                    cateringDetails.map(cateringDetail => (
                      <TableRow key={cateringDetail.desc}>
                        <TableCell>{cateringDetail.desc}</TableCell>
                      </TableRow>
                    ))}
                  <TableBody className="orderPCont">
                    {cartItems &&
                      cartItems.length === 0 &&
                      cartItems.map(cartItem => (
                        <TableRow key={cartItem.id}>
                          <TableCell>{cartItem.itemName}</TableCell>
                          <TableCell align="left">
                            {cartItem.quantity}
                          </TableCell>
                          <TableCell align="right">
                            £{cartItem.price.toFixed(2)}
                          </TableCell>
                          <TableCell align="right">
                            £{(cartItem.price * cartItem.quantity).toFixed(2)}
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                  {cartItems && cartItems.length > 0 && (
                    <TableBody className="orderPBreakdown">
                      <TableRow>
                        <TableCell colSpan={2} align="right">
                          Subtotal
                        </TableCell>
                        <TableCell colSpan={2} align="right">
                          £{Number(cateringSubTotal).toFixed(2)}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell colSpan={2} align="right">
                          VAT @ {cateringVatRate}%
                        </TableCell>
                        <TableCell colSpan={2} align="right">
                          £{cateringVat.toFixed(2)}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell colSpan={2} align="right">
                          Catering Total
                        </TableCell>
                        <TableCell colSpan={2} align="right">
                          £{Number(cateringSubTotalWithVat).toFixed(2)}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </div>
          ) : (
            ''
          )}
          <div className="customerDtls">
            <h2>Customer Details</h2>
            <p>{`${firstName} ${lastName} ${
              tenant || (firstName && lastName && customerType === 'Guest')
                ? '-'
                : ''
            } ${
              firstName && lastName && customerType === 'Guest'
                ? 'Guest'
                : tenant
            } - ${email} ${phoneNumber ? ` - ${phoneNumber}` : ''}`}</p>
          </div>
          <div className="totalDtls">
            <h3> Total</h3>
            <h5>£{Number(total).toFixed(2)}</h5>
          </div>

          <Dialog
            open={timeoutPopup}
            onClose={handleTerminalDialogClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className="cancelDialogOpend savedCardPopupOpend"
          >
            <Button
              className="popupCloseBtn"
              onClick={handleTerminalDialogClose}
            >
              <img src={popup_close} alt="popup_close.svg" />
            </Button>
            <h4 className={`${classes.popupHeading}`}>Transaction Status</h4>
            <div className={`${classes.popupText}`}>
              <p>
                Communication with the PDQ has failed and payment may or may not
                have been taken. Please check the receipt and record
                accordingly.
              </p>
            </div>
            <DialogActions className={`${classes.extendPopupFormButton}`}>
              <Button
                onClick={() => {
                  setMrPaymentPos(false);
                  setTimeoutPopup(false);
                }}
                autoFocus
              >
                Back
              </Button>
              <Button
                onClick={() => handleSuccessfulTransaction(posRequestId)}
                autoFocus
              >
                Complete Booking
              </Button>
            </DialogActions>
          </Dialog>

          <Dialog
            open={signatureAlert}
            onClose={() => verifySignatureCall(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className="cancelDialogOpend savedCardPopupOpend"
          >
            <Button
              className="popupCloseBtn"
              onClick={() => verifySignatureCall(false)}
            >
              <img src={popup_close} alt="popup_close.svg" />
            </Button>
            <h4 className={`${classes.popupHeading}`}>Transaction Status</h4>
            <div className={`${classes.popupText}`}>
              <p>Verify Signature</p>
            </div>
            <DialogActions className={`${classes.extendPopupFormButton}`}>
              <Button
                onClick={() => {
                  verifySignatureCall(false);
                }}
                autoFocus
              >
                Back
              </Button>
              <Button
                onClick={() => {
                  verifySignatureCall(true);
                }}
                autoFocus
              >
                Signature Verified
              </Button>
            </DialogActions>
          </Dialog>
        </>
      )}
    </div>
  );
};

export default OrderPreview;
