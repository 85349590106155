/* eslint-disable no-nested-ternary */
import React, { useContext, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  Divider,
  Input,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  FormLabel,
} from '@material-ui/core';
import {
  KeyboardDateTimePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import moment from 'moment';
import DateFnsUtils from '@date-io/date-fns';
import { useParams, useHistory } from 'react-router-dom';
import LocationFilter from '../../components/Filters/LocationFilter';
import dowmArw from '../../images/down-arw.svg';
import { notificationSchema } from '../../utils/formValidate';
import dlLogo from '../../images/dllogo.svg';
import './notification.css';
import ApiClient from '../../helpers/ApiClient';
import apiEndPoints from '../../helpers/apiEndPoints';
import { GlobalContext } from '../../context/GlobalContext';
import NotificationSearchFilter from './NotificationSearchFilter';
import SaveAndCancelButtons from '../../components/Buttons/SaveAndCancelButtons';
import SnackBar from '../../components/SnackBar/SnackBar';
import TenantsFilter from '../../components/Filters/TenantsFilter';
import {  tenantUserStatus } from '../../utils/constants';

const useStyles = makeStyles(() => ({
  pageContainer: {
    height: '100%',
    width: '100%',
    marginTop: '88px',
    padding: '0 30px',
    boxSizing: 'border-box',
  },
  addUserForm: {
    width: '58%',
    padding: '0 25px 40px 0',
    borderRight: '4px solid #F1F1F1',
    // margin: '0 auto',
    // display: 'flex',
    // justifyContent: 'space-between',
    '& ul': {
      display: 'flex',
      justifyContent: 'space-between',
      flexWrap: 'wrap',
      '& li': {
        listStyle: 'none',
        width: '46%',
        marginBottom: '22px',
      },
    },
  },
  sdlNotification: {
    width: '42%',
    padding: '0 0 0 20px',
  },
  formControl: {
    width: '85%',
    '& InputLabel': {
      position: 'relative',
    },
  },
  formLabel: {
    fontSize: '16px',
    fontWeight: 'normal',
    color: '#838384',
    transform: 'none',
    fontFamily: ['Varta', 'sans-serif'].join(','),
    position: 'static',
  },
  formSelectBox: {
    '& svg': {
      display: 'none',
    },
    '&:before': {
      display: 'none',
    },
    '&:after': {
      display: 'none',
    },
    '& > div': {
      height: '45px',
      lineHeight: '45px',
      padding: '0 15px !important',
      fontSize: '16px',
      color: '#444444',
      borderRadius: '3px !important',
      border: 'solid 0.5px #979797',
      marginTop: '0',
      background: `transparent url(${dowmArw}) no-repeat 95% center !important`,
      backgroundSize: '10px',
      appearance: 'none',
      fontFamily: ['Varta', 'sans-serif'].join(','),
    },
  },
  formSelectBoxNodown: {
    '& > div': {
      background: ' transparent !important',
    },
  },
  InputSection: {
    '& li': {
      '& span': {
        display: 'block',
        fontSize: '16px',
        fontWeight: 'normal',
        color: '#838384',
        fontFamily: ['Varta', 'sans-serif'].join(','),
      },
      '& input': {
        height: '45px',
        lineHeight: '45px',
        padding: '0 15px !important',
        fontSize: '16px',
        color: '#444444',
        borderRadius: '3px',
        border: 'solid 0.5px #979797',
        marginTop: '10px',
        width: '100%',
        background: 'transparent',
        fontFamily: ['Varta', 'sans-serif'].join(','),
      },
      '& textarea': {
        height: '92px',
        lineHeight: '22px',
        padding: '10px 15px !important',
        fontSize: '16px',
        color: '#444444',
        borderRadius: '3px',
        border: 'solid 0.5px #979797',
        marginTop: '10px',
        width: '100%',
        background: 'transparent',
        resize: 'none',
        fontFamily: ['Varta', 'sans-serif'].join(','),
        outline: 'none',
      },
      '& > div': {
        width: '100%',
        '&:before': {
          display: 'none',
        },
        '&:after': {
          display: 'none',
        },
      },
    },
  },
  InputSectionReadonly: {
    '& > div': {
      '& > div': {
        '&:before': {
          display: 'none',
        },
        '&:after': {
          display: 'none',
        },
        '& input': {
          height: '45px',
          lineHeight: '45px',
          padding: '0 15px !important',
          fontSize: '16px',
          color: '#444444',
          borderRadius: '3px',
          border: 'solid 0.5px #979797',
          marginTop: '10px',
          appearance: 'none',
          fontFamily: ['Varta', 'sans-serif'].join(','),
        },
      },
    },
  },
  radioSection: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '0',
    '& span': {
      fontFamily: ['Varta', 'sans-serif'].join(','),
      fontSize: '16px',
      lineHeight: '20px',
      color: '#838384',
      '&:hover': {
        backgroundColor: 'transparent !important',
      },
      '& svg': {
        fontSize: '16px',
        fill: '#979797',
        '&:last-child': {
          fill: '#4e4e4e',
        },
      },
    },
  },
  errorMsg: {
    fontSize: '13px',
    color: '#f90000 !important',
    marginTop: 4,
    fontFamily: ['Varta', 'sans-serif'].join(','),
  },
  cancelDialog: {
    textAlign: 'center',
    '& h2': {
      fontSize: '19px',
      fontWeight: 'bold',
      color: '#444444',
      marginBottom: '15px',
      fontFamily: ['Lato', 'sans-serif'].join(','),
    },
    '& p': {
      fontSize: '16px',
      fontWeight: 'normal',
      color: '#444444',
      marginBottom: '15px',
      fontFamily: ['Lato', 'sans-serif'].join(','),
    },
  },
  errorColor: {
    color: '#d60303 !important',
  },
  exportSerch: {
    '& ul': {
      display: 'flex',
      alignItems: 'center',
      '& li': {
        marginLeft: '20px',
      },
    },
  },
  leftFilterIn: {
    '& button': {
      color: '#444444',
      backgroundColor: '#f8f8f8',
      padding: '15px 12px 13px',
      fontWeight: 'bold',
      boxShadow: 'none',
      fontSize: '14px',
      fontFamily: ['Varta', 'sans-serif'].join(','),
      borderRadius: 5,
      '&:hover': {
        boxShadow: 'none',
        background: '#f8f8f8',
      },
      '& span': {
        lineHeight: '100%',
        alignItems: 'center',
        display: 'flex',

        '& small': {
          marginLeft: 20,
          top: '-2px',
          position: 'relative',
        },
        '& img': {
          '&:nth-child(2)': {
            display: 'none',
          },
        },
      },
    },
  },
}));

const Notification = () => {
  const classes = useStyles();
  const { buildingDataList, buildingGroupsList, setSuccessMessage } =
    useContext(GlobalContext);
  const { id } = useParams();
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [buildings, setBuildings] = useState([]);
  const [OnCheckedLocationButton, setOnCheckedLocationButton] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const [locationSearch, setLocationSearch] = useState(false);
  const [villages, setVillages] = useState([]);
  const [userJobTitle, setUserJobTitle] = useState([]);
  const [jobTitlesList, setJobTitlesList] = useState([]);
  const [userStatus, setUserStatus] = useState([]);
  const [notificationLink, setNotificationLink] = useState('');
  const [notificationList, setNotificationList] = useState([]);
  const [offersList, setOffersList] = useState([]);
  const [eventList, setEventsList] = useState([]);
  const [meetingRoomList, setMeetingRoomList] = useState([]);
  const [newsList, setNewsList] = useState([]);
  const [announcementList, setAnnouncementList] = useState([]);
  const [spaceList, setSpaceList] = useState([]);
  const [notificationToggle, setNotificationToggle] = useState(false);
  const [notificationSearch, setNotificationSearch] = useState('');
  const [notificationId, setNotificationId] = useState('');
  const [errorMsgs, setErrorMsgs] = useState('');
  const [selectedBuildingId, setSelectedBuildingId] = useState('');
  const [isReadonly, setIsReadonly] = useState(false);
  const [delNotification, setDeleteNotification] = useState(false);
  const [expiryDate, setExpiryDate] = useState('');
  const [eventStart, setEventStart] = useState('');
  const [errorMessage, setErrorMsg] = useState('');
  const [errorValue, setErrorValue] = useState(false);
  const [selectedBuildingIds, setSelectedBuildingIds] = useState([]);
  const [selectedTenants, setSelectedTenants] = useState([]);
  const [listOfTenants, setListOfTenants] = useState([]);
  const [userTenant, setUserTenant] = useState([]);
  const [selectedTenantsId, setSelectedTenantsId] = useState([]);

  // const [scheduledTime, setScheduledTime] = useState('');
  const history = useHistory();

  const placeholder = 'Type a Name';

  const buildingPayload = buildingDataList.filter(o1 =>
    buildings.some(o2 => o1.id === o2),
  );

  const buildingArray = [];
  if (buildingPayload) {
    buildingPayload.map(item => {
      if (item.id && item.village && item.name) {
        buildingArray.push({
          id: item.id,
          name: item.name,
          village: item.village,
        });
      }
      return item;
    });
  }
  const selectionRenderer = values => {
    // change the default comma separated rendering
    return <span style={{ color: '#ff4081' }}>{values.join('; ')}</span>;
  };

  const NotificationClear = () => {
    setNotificationLink('');
    setTitle('');
    setDescription('');
    setBuildings([]);
    setSelectedDate(new Date());
    setUserJobTitle([]);
    setUserStatus([]);
    setIsReadonly(false);
    setSelectedTenantsId([]);
    setErrorMsg('');
  };

  const handleClose = () => {
    setAnchorEl(null);
    setLocationSearch(false);
    setOnCheckedLocationButton(false);
  };

  useEffect(() => {
    ApiClient.apiGet(apiEndPoints.getNotifications)
      .then(data => {
        setNotificationList(data && data.data && data.data.data);
      })
      .catch(error => {
        console.log(error, 'error');
      });
    ApiClient.apiGet(apiEndPoints.getJobTitles)
      .then(data => {
        setJobTitlesList(data && data.data && data.data.data);
      })
      .catch(error => {
        console.log(error, 'error');
      });
  }, []);

  const x = selectedBuildingId.length > 0 && selectedBuildingId.map(y => y.id);

  const filteredArray =
    x.length > 0 && x.filter(value => buildings.includes(value));

  const link =
    (notificationLink === 'offers' && 'offer') ||
    (notificationLink === 'events' && 'event') ||
    (notificationLink === 'meetingrooms' && 'meetingRoom') ||
    (notificationLink === 'spaces' && 'space') ||
    (notificationLink === 'news' && 'news') ||
    (notificationLink === 'announcements' && 'announcement');

  const date =
    moment.tz(selectedDate, 'Europe/London').toISOString() > expiryDate;
  const defaultUserStatus = Object.keys(tenantUserStatus).map(
    key => tenantUserStatus[key],
  );
  useEffect(() => {
    notificationSchema
      .validate({
        notificationTitle: title,
        notificationDescription: description,
        deliveryDate:
          (notificationLink === 'offers' ||
            notificationLink === 'events' ||
            notificationLink === 'news' ||
            notificationLink === 'announcements') &&
          date === true
            ? undefined
            : false,
        locationValidation: buildings,
        tenantValidation: selectedTenants,
        jobFunction: userJobTitle,
        userStatus: userStatus,
        notificationLink: {
          type: link,
        },
      })
      .then(() => {
        if (notificationToggle) {
          const payload = {
            title: title,
            description: description,
            scheduledDate: moment
              .tz(selectedDate, 'Europe/London')
              .toISOString(),
            buildings:
              filteredArray && filteredArray.length > 0
                ? filteredArray
                : buildings,
            tenants: selectedTenantsId,
            jobTitle: userJobTitle?.length ? userJobTitle : [],
            userStatus: userStatus?.length ? userStatus : defaultUserStatus,
            link: {
              type: link,
              id: notificationId,
            },
          };
          ApiClient.apiPost(apiEndPoints.getNotifications, payload)
            .then(data => {
              const successmsg =
                data && data.data.message ? 'Notification is Scheduled' : '';
              window.location.reload();
              setSuccessMessage(successmsg);
            })
            .catch(error => {
              console.log(error, 'error');
              setNotificationToggle(false);
              setErrorValue(true);
              setErrorMsg(
                error &&
                  error.response &&
                  error.response.data &&
                  error.response.data.message,
              );
            });
        }
      })
      .catch(err => {
        console.log(err, 'error2');
        if (notificationToggle) {
          setErrorMsgs(err);
          setNotificationToggle(false);
        }
      });
  }, [
    notificationToggle,
    buildings,
    title,
    description,
    userJobTitle,
    userStatus,
    selectedDate,
    notificationLink,
    selectedTenants,
  ]);

  const futureDate = new Date();
  futureDate.setDate(futureDate.getDate() + 180);

  const futureDate1 = new Date();
  futureDate1.setDate(futureDate.getDate() + 762);

  useEffect(() => {
    if (notificationLink === 'offers') {
      const payload = {
        searchText:
          notificationSearch.length > 2 ? notificationSearch : undefined,
        buildings: buildings && buildings.length > 0 ? buildings : undefined,
        status: ['Published'],
        todayDate: new Date().toISOString(),
      };
      ApiClient.apiPost(apiEndPoints.getOffersList, payload).then(data => {
        setOffersList(data && data.data && data.data.data);
      });
    } else if (notificationLink === 'events') {
      const payload = {
        searchText:
          notificationSearch.length > 2 ? notificationSearch : undefined,
        buildings: buildings && buildings.length > 0 ? buildings : undefined,
        status: ['Published'],
        startDate: new Date().toISOString(),
        endDate: futureDate.toISOString(),
        offset: 0,
        limit: 40,
        sortOrder: 'desc',
      };
      ApiClient.apiPost(apiEndPoints.getEventsList, payload).then(data => {
        setEventsList(data && data.data && data.data.data);
      });
    } else if (notificationLink === 'meetingrooms') {
      const payload = {
        searchText:
          notificationSearch.length > 2 ? notificationSearch : undefined,
        // buildings: buildings && buildings.length > 0 ? buildings : undefined,
        status: ['Active'],
        offset: 0,
        limit: 40,
        sortOrder: 'desc',
      };
      ApiClient.apiPost(apiEndPoints.getMeetingRoomsList, payload).then(
        data => {
          setMeetingRoomList(data && data.data && data.data.data);
        },
      );
    } else if (notificationLink === 'news') {
      const payload = {
        searchText:
          notificationSearch.length > 2 ? notificationSearch : undefined,
        buildings: buildings && buildings.length > 0 ? buildings : undefined,
        type: ['News'],
        status: ['Published'],
        todayDate: new Date().toISOString(),
        // startDate: new Date().toISOString(),
        // endDate: futureDate1.toISOString(),
        offset: 0,
        limit: 40,
        sortOrder: 'desc',
      };
      ApiClient.apiPost(apiEndPoints.getNewsAnnouncementsList, payload).then(
        data => {
          setNewsList(data && data.data && data.data.data);
        },
      );
    } else if (notificationLink === 'announcements') {
      const payload = {
        searchText:
          notificationSearch.length > 2 ? notificationSearch : undefined,
        buildings: buildings && buildings.length > 0 ? buildings : undefined,
        tenants: selectedTenantsId,
        type: ['Announcement'],
        status: ['Published'],
        todayDate: new Date().toISOString(),
        // startDate: new Date().toISOString(),
        // endDate: futureDate1.toISOString(),
        offset: 0,
        limit: 40,
        sortOrder: 'desc',
      };
      ApiClient.apiPost(apiEndPoints.getNewsAnnouncementsList, payload).then(
        data => {
          setAnnouncementList(data && data.data && data.data.data);
        },
      );
    } else if (notificationLink === 'spaces') {
      const payload = {
        searchText:
          notificationSearch.length > 2 ? notificationSearch : undefined,
        // buildings: buildings && buildings.length > 0 ? buildings : undefined,
        status: ['Published'],
        offset: 0,
        limit: 40,
        sortOrder: 'desc',
      };
      ApiClient.apiPost(apiEndPoints.getSpacesList, payload).then(data => {
        setSpaceList(data && data.data && data.data.data);
      });
    }
  }, [notificationLink, buildings, notificationSearch]);

  const errorType = errorMsgs && errorMsgs.type;
  const errorFieldName = errorMsgs && errorMsgs.path;
  const errorFieldValue = errorMsgs && errorMsgs.value;

  useEffect(() => {
    if (delNotification) {
      ApiClient.apiDelete(`${apiEndPoints.getNotifications}/${notificationId}`)
        .then(() => {
          window.location.reload(true);
          history.push('/notification');
        })
        .catch(error => {
          const err = error;
          alert(err.response.data.message);
        });
    }
  }, [delNotification]);

  useEffect(() => {
    if (buildings.length > 0) {
      const tenantsPayload = {
        sortOrder: 'asc',
        sortBy: 'name',
        offset: 0,
        buildings: selectedBuildingIds,
      };

      ApiClient.apiPost(apiEndPoints.getTenants, tenantsPayload)
        .then(data => {
          setUserTenant(data && data.data && data.data.data);
          // setLoading(false);
        })
        .catch(error => {
          console.log(error, 'error');
        });
    }
  }, [buildings, id, selectedBuildingIds]);

  useEffect(() => {
    if (buildings.length <= 0) {
      setSelectedTenants([]);
    }
  }, [buildings]);

  useEffect(() => {
    if (userTenant.length !== 0 && userTenant.length < selectedTenants.length) {
      const allFields = userTenant.map(field => field.id);
      setSelectedTenants([...allFields]);
      const allFieldsId = userTenant.map(field => field.id);
      setSelectedTenantsId(allFieldsId);
    }
  }, [userTenant]);

  return (
    <div className={classes.addUserFormBody}>
      <div className={classes.addUserFormMain}>
        <div className="pageContainer notificationContainer">
          <div className="notificationWrap">
            <SnackBar />
            <div
              className={`${classes.addUserForm} notificationForm CreateNotificationForm`}
            >
              <h2>Create Notifications</h2>
              <ul className={`${classes.InputSection} notificationFullW`}>
                <li>
                  <span>Notification Title*</span>
                  <Input
                    type="text"
                    readOnly={isReadonly}
                    value={title}
                    inputProps={{ maxLength: 150 }}
                    onChange={e => {
                      setTitle(e.target.value);
                    }}
                  />
                  {errorFieldValue && errorFieldValue.notificationTitle === '' && (
                    <FormHelperText
                      className={classes.errorMsg}
                      error={title === '' && 'Notification title is required.'}
                    >
                      {title === '' && 'Notification title is required.'}
                    </FormHelperText>
                  )}
                </li>
                <li>
                  <span>Notification Description*</span>
                  <Input
                    type="text"
                    readOnly={isReadonly}
                    value={description}
                    inputProps={{ maxLength: 500 }}
                    onChange={e => {
                      setDescription(e.target.value);
                    }}
                  />
                  {errorFieldValue &&
                    errorFieldValue.notificationDescription === '' && (
                      <FormHelperText
                        className={classes.errorMsg}
                        error={description === '' && 'Description is required.'}
                      >
                        {description === '' && 'Description is required.'}
                      </FormHelperText>
                    )}
                </li>
              </ul>
              <ul
                className={`${classes.InputSection} offerDtCat notificationDtCat`}
              >
                <li>
                  <span>Delivery Date & Time*</span>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDateTimePicker
                      className="ntfDateTimePicker"
                      variant="inline"
                      readOnly={isReadonly}
                      autoOk
                      value={selectedDate}
                      onChange={setSelectedDate}
                      disablePast
                      ampm={false}
                      format="yyyy/MM/dd HH:mm"
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                    />
                  </MuiPickersUtilsProvider>
                  {errorFieldValue && errorFieldValue.deliveryDate === '' && (
                    <FormHelperText
                      className={classes.errorMsg}
                      error={
                        selectedDate === '' && 'Scheduled date is required.'
                      }
                    >
                      {selectedDate === '' && 'Scheduled date is required.'}
                    </FormHelperText>
                  )}
                  {errorFieldValue && (
                    <FormHelperText
                      className={classes.errorMsg}
                      error={
                        ((notificationLink === 'offers' ||
                          notificationLink === 'events' ||
                          notificationLink === 'news' ||
                          notificationLink === 'announcements') &&
                          moment
                            .tz(selectedDate, 'Europe/London')
                            .toISOString() > expiryDate) ||
                        (moment
                          .tz(selectedDate, 'Europe/London')
                          .toISOString() > eventStart &&
                          'Scheduled Time should be before Expiry Date')
                      }
                    >
                      {(notificationLink === 'offers' ||
                        notificationLink === 'events' ||
                        notificationLink === 'news' ||
                        notificationLink === 'announcements') &&
                        moment.tz(selectedDate, 'Europe/London').toISOString() >
                          expiryDate &&
                        'Scheduled Time should be before Expiry Date'}
                    </FormHelperText>
                  )}
                  {errorValue && errorMessage.includes('15') && (
                    <FormHelperText
                      className={classes.errorMsg}
                      error={errorValue && 'Job function is required.'}
                    >
                      {errorValue &&
                        'Scheduled Time Should Be Multiple Of 15 On Request'}
                    </FormHelperText>
                  )}
                </li>
              </ul>
              <ul className="dietrySelectBox notificationSelectBox">
                <li>
                  <span>Locations*</span>
                  {(isReadonly && (
                    <div className="offerLocationBtn">
                      <Button
                        aria-describedby={id}
                        variant="contained"
                        readOnly
                      >
                        {buildings.length > 1
                          ? `${buildings.length} Buildings selected`
                          : `${buildings.length} Building selected`}
                      </Button>
                    </div>
                  )) || (
                    <ul className={`${classes.leftFilterIn}`}>
                      <LocationFilter
                        selectAll
                        errorType={errorType}
                        errorFieldName={errorFieldName}
                        errorFieldValue={errorFieldValue}
                        locationSearch={locationSearch}
                        anchorEl={anchorEl}
                        setAnchorEl={setAnchorEl}
                        setLocationSearch={setLocationSearch}
                        handleClose={handleClose}
                        setOnCheckedLocationButton={setOnCheckedLocationButton}
                        OnCheckedLocationButton={OnCheckedLocationButton}
                        id={id}
                        buildingGroupsList={buildingGroupsList}
                        buildings={buildings}
                        villages={villages}
                        setVillages={setVillages}
                        setBuildings={setBuildings}
                        AddOffersToggle
                        selectedBuildingId={selectedBuildingIds}
                        setSelectedBuildingId={setSelectedBuildingIds}
                        setSelectedTenants={setSelectedTenants}
                      />
                    </ul>
                  )}
                  {errorValue && errorMessage.includes('building') && (
                    <FormHelperText
                      className={`${classes.errorMsg} buildingErrorMsg`}
                      error={errorValue && 'Job function is required.'}
                    >
                      {errorValue && errorMessage}
                    </FormHelperText>
                  )}
                </li>
                {(userTenant?.length || isReadonly) && (
                  <>
                    <li>
                      <FormLabel
                        className="locationFilterLabel mb-19"
                        component="legend"
                      >
                        Tenants*
                      </FormLabel>
                      {(isReadonly && (
                        <div className="offerLocationBtn">
                          <Button
                            aria-describedby={id}
                            variant="contained"
                            readOnly
                          >
                            {selectedTenants?.length > 1
                              ? `${selectedTenants?.length} Tenants selected`
                              : `${
                                  selectedTenants?.length || 0
                                } Tenant selected`}
                          </Button>
                        </div>
                      )) || (
                        <TenantsFilter
                          dropDownFields={userTenant}
                          selectAll
                          setSelectedTenants={setSelectedTenants}
                          selectedTenants={selectedTenants}
                          listOfTenants={listOfTenants}
                          setListOfTenants={setListOfTenants}
                          errorType={errorType}
                          errorFieldName={errorFieldName}
                          errorFieldValue={errorFieldValue}
                          setSelectedTenantsId={setSelectedTenantsId}
                          selectedTenantsId={selectedTenantsId}
                        />
                      )}
                    </li>
                  </>
                )}
              </ul>
              <ul className="dietrySelectBox notificationSelectBox">
                <li>
                  <FormControl className={classes.formControl}>
                    <InputLabel
                      shrink
                      id="demo-simple-select-placeholder-label-label"
                      className={classes.formLabel}
                    >
                      Job Function*
                    </InputLabel>
                    {(isReadonly && (
                      <div className="offerLocationBtn ntfLocationBtndisb">
                        <Button
                          className={classes.formSelectBox}
                          variant="contained"
                          readOnly
                        >
                          {userJobTitle.length > 1
                            ? `${userJobTitle.length} JobTitles selected`
                            : `${userJobTitle.length} Jobtitle selected`}
                        </Button>
                      </div>
                    )) || (
                      <Select
                        multiple
                        value={userJobTitle}
                        MenuProps={{
                          anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'left',
                          },
                          getContentAnchorEl: null,
                        }}
                        className={classes.formSelectBox}
                        onChange={e => {
                          setUserJobTitle(e.target.value);
                        }}
                        selectionRenderer={selectionRenderer}
                      >
                        {jobTitlesList &&
                          jobTitlesList.map(item => {
                            return (
                              <MenuItem
                                className="multiSelectMenu"
                                value={item.jobTitle}
                                checked={item && item.jobTitle}
                                insetChildren
                                key={item.id}
                                primaryText={item.jobTitle}
                              >
                                {item.jobTitle}
                              </MenuItem>
                            );
                          })}
                      </Select>
                    )}
                  </FormControl>
                  {errorFieldValue && errorFieldValue.jobFunction.length === 0 && (
                    <FormHelperText
                      className={classes.errorMsg}
                      error={
                        userJobTitle.length === 0 && 'Job function is required.'
                      }
                    >
                      {userJobTitle.length === 0 && 'Job function is required.'}
                    </FormHelperText>
                  )}
                </li>
                <li>
                  <FormControl className={classes.formControl}>
                    <InputLabel
                      shrink
                      id="demo-simple-select-placeholder-label-label"
                      className={classes.formLabel}
                    >
                      User Status
                    </InputLabel>
                    {(isReadonly && (
                      <div className="offerLocationBtn ntfLocationBtndisb">
                        <Button
                          className={classes.formSelectBox}
                          variant="contained"
                          readOnly
                        >
                          {userStatus.length > 1
                            ? `${userStatus?.length} User status selected`
                            : `${userStatus?.length || 0} User status selected`}
                        </Button>
                      </div>
                    )) || (
                      <Select
                        multiple
                        value={userStatus}
                        MenuProps={{
                          anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'left',
                          },
                          getContentAnchorEl: null,
                        }}
                        className={classes.formSelectBox}
                        onChange={e => {
                          setUserStatus(e.target.value);
                        }}
                        selectionRenderer={selectionRenderer}
                      >
                        {defaultUserStatus?.map(status => {
                          return (
                            <MenuItem
                              className="multiSelectMenu"
                              value={status}
                              checked={status}
                              insetChildren
                              primaryText={status}
                            >
                              {status}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    )}
                  </FormControl>
                </li>
              </ul>
              <Divider className="formDivider" light />
              <div className="notificationCardBlock">
                <ul className="dietrySelectBox notificationSelectBox">
                  <li>
                    <FormControl className={classes.formControl}>
                      <InputLabel
                        shrink
                        id="demo-simple-select-placeholder-label-label"
                        className={classes.formLabel}
                      >
                        Notification Link*
                      </InputLabel>
                      {(isReadonly && (
                        <div className="offerLocationBtn ntfLocationBtndisb">
                          <Button
                            className={classes.formSelectBox}
                            variant="contained"
                            readOnly
                          >
                            {notificationLink}
                          </Button>
                        </div>
                      )) || (
                        <Select
                          value={notificationLink}
                          MenuProps={{
                            anchorOrigin: {
                              vertical: 'bottom',
                              horizontal: 'left',
                            },
                            getContentAnchorEl: null,
                          }}
                          className={classes.formSelectBox}
                          onChange={e => {
                            setNotificationLink(e.target.value);
                          }}
                        >
                          <MenuItem value="offers">Offers</MenuItem>
                          <MenuItem value="events">Events</MenuItem>
                          <MenuItem value="meetingrooms">
                            Meeting Rooms
                          </MenuItem>
                          <MenuItem value="news">News</MenuItem>
                          <MenuItem value="announcements">
                            Announcements
                          </MenuItem>
                          <MenuItem value="spaces">Spaces</MenuItem>
                        </Select>
                      )}
                    </FormControl>
                    {errorFieldValue &&
                      errorFieldValue.notificationLink.type === 'false' && (
                        <FormHelperText
                          className={classes.errorMsg}
                          error={
                            notificationLink === '' &&
                            'Notification Link is required.'
                          }
                        >
                          {notificationLink === '' &&
                            'Notification Link is required.'}
                        </FormHelperText>
                      )}
                  </li>
                  {/* <h5>Offers</h5> */}
                  <li>
                    <div className={`${classes.exportSerch} exportSerch`}>
                      <ul>
                        {(isReadonly && (
                          <div className="notificationDisable">
                            <NotificationSearchFilter
                              customerSearch={notificationSearch}
                              setCustomerSearch={setNotificationSearch}
                              placeholder={placeholder}
                              disabled={isReadonly}
                            />
                          </div>
                        )) || (
                          <NotificationSearchFilter
                            customerSearch={notificationSearch}
                            setCustomerSearch={setNotificationSearch}
                            placeholder={placeholder}
                            disabled={isReadonly}
                          />
                        )}
                      </ul>
                    </div>
                  </li>
                </ul>
                <div className="selectedOfferBlock">
                  <ul className="offerCardBoxAdded">
                    {notificationLink === 'offers' &&
                      offersList &&
                      offersList.length > 0 &&
                      offersList.map(offer => (
                        <li
                          className={`${
                            offer.id === notificationId ? 'active' : ''
                          }`}
                          key={offer.id}
                          onClick={() => {
                            setNotificationId(offer.id);
                            setSelectedBuildingId(offer.buildings);
                            setExpiryDate(offer.endDate);
                          }}
                          aria-hidden="true"
                        >
                          <div className="allOffercard offerCardBox">
                            <div className="offerLogoBlock">
                              <img
                                src={offer.offerImage[2].imageUrl}
                                alt="offerLogo"
                              />
                            </div>
                            <div className="offerDetails">
                              <h3>{offer.offerName}</h3>
                              <h3>DL/</h3>
                              <div className="publishDateMain">
                                <div className="publishDate">
                                  <span>Expires: </span>{' '}
                                  {moment(offer.endDate).format('DD.MM.YYYY')}
                                </div>
                                <div className="publishType">
                                  {offer.offerCategory}
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                      ))}
                    {notificationLink === 'events' &&
                      eventList &&
                      eventList.length > 0 &&
                      eventList.map(event => (
                        <li
                          className={`${
                            event.id === notificationId ? 'active' : ''
                          }`}
                          key={event.id}
                          onClick={() => {
                            setNotificationId(event.id);
                            setExpiryDate(event.endTime);
                            setEventStart(event.eventDate);
                            // setSelectedBuildingId(event.buildings);
                          }}
                          aria-hidden="true"
                        >
                          <div className="allOffercard offerCardBox">
                            <div className="offerLogoBlock">
                              <img
                                src={event.image[2].imageUrl}
                                alt="offerLogo"
                              />
                            </div>
                            <div className="offerDetails">
                              <h3>{event.name}</h3>
                              <h3>DL/</h3>
                              <div className="publishDateMain">
                                <div className="publishDate">
                                  <span>Expires: </span>{' '}
                                  {moment(event.eventDate).format('DD.MM.YYYY')}
                                </div>
                                {/* <div className="publishType">
                                  {offer.offerCategory}
                                </div> */}
                              </div>
                            </div>
                          </div>
                        </li>
                      ))}
                    {notificationLink === 'meetingrooms' &&
                      meetingRoomList &&
                      meetingRoomList.length > 0 &&
                      meetingRoomList.map(room => (
                        <li
                          className={`${
                            room.id === notificationId ? 'active' : ''
                          }`}
                          key={room.id}
                          onClick={() => {
                            setNotificationId(room.id);
                            setSelectedBuildingId(room.building);
                          }}
                          aria-hidden="true"
                        >
                          <div className="allOffercard offerCardBox">
                            <div className="offerLogoBlock">
                              <img
                                src={room.images[0].image[0].imageUrl}
                                alt="offerLogo"
                              />
                            </div>
                            <div className="offerDetails">
                              <h3>{room.name}</h3>
                              {/* <h3>DL/</h3> */}
                              {/* <div className="publishDateMain">
                                <div className="publishDate">
                                  <span>Derwent London</span>{' '}
                                </div>
                              </div> */}
                            </div>
                          </div>
                        </li>
                      ))}
                    {notificationLink === 'news' &&
                      newsList &&
                      newsList.length > 0 &&
                      newsList.map(news => (
                        <li
                          className={`${
                            news.id === notificationId ? 'active' : ''
                          }`}
                          key={news.id}
                          onClick={() => {
                            setNotificationId(news.id);
                            setExpiryDate(news.endDate);
                            // setSelectedBuildingId(news.buildings)
                          }}
                          aria-hidden="true"
                        >
                          <div className="allOffercard offerCardBox">
                            <div className="offerLogoBlock">
                              <img
                                src={
                                  news.mediaType === 'video' ||
                                  news.mediaType === 'featured'
                                    ? news.videoPreviewImage &&
                                      news.videoPreviewImage[2].imageUrl
                                    : news.newsImage &&
                                      news.newsImage[2].imageUrl
                                }
                                alt="offerLogo"
                              />
                            </div>
                            <div className="offerDetails">
                              <h3>{news.title}</h3>
                              {/* <h3>DL/</h3> */}
                              <div className="publishDateMain">
                                <div className="publishDate">
                                  <span>Expires: </span>{' '}
                                  {moment(news.endDate).format('DD.MM.YYYY')}
                                </div>
                                <div className="publishType">
                                  {news.category.name}
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                      ))}
                    {notificationLink === 'announcements' &&
                      announcementList &&
                      announcementList.length > 0 &&
                      announcementList.map(news => (
                        <li
                          className={`${
                            news.id === notificationId ? 'active' : ''
                          }`}
                          key={news.id}
                          onClick={() => {
                            setNotificationId(news.id);
                            setExpiryDate(news.endDate);
                            // setSelectedBuildingId(news.buildings);
                          }}
                          aria-hidden="true"
                        >
                          <div className="allOffercard offerCardBox">
                            <div className="offerLogoBlock">
                              <img
                                src={
                                  news.mediaType === 'video' ||
                                  news.mediaType === 'featured'
                                    ? news.videoPreviewImage &&
                                      news.videoPreviewImage[2].imageUrl
                                    : news.newsImage &&
                                      news.newsImage[2].imageUrl
                                }
                                alt="offerLogo"
                              />
                            </div>
                            <div className="offerDetails">
                              <h3>{news.title}</h3>
                              {/* <h3>DL/</h3> */}
                              <div className="publishDateMain">
                                <div className="publishDate">
                                  <span>Expires: </span>{' '}
                                  {moment(news.endDate).format('DD.MM.YYYY')}
                                </div>
                                <div className="publishType">
                                  {news.category.name}
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                      ))}
                    {notificationLink === 'spaces' &&
                      spaceList &&
                      spaceList.length > 0 &&
                      spaceList.map(space => (
                        <li
                          className={`${
                            space.id === notificationId ? 'active' : ''
                          }`}
                          key={space.id}
                          onClick={() => {
                            setNotificationId(space.id);
                            setSelectedBuildingId(space.building);
                          }}
                          aria-hidden="true"
                        >
                          <div className="allOffercard offerCardBox">
                            <div className="offerLogoBlock">
                              <img
                                src={space.images[0].image[0].imageUrl}
                                alt="offerLogo"
                              />
                            </div>
                            <div className="offerDetails">
                              <h3>{space.unit}</h3>
                            </div>
                          </div>
                        </li>
                      ))}
                  </ul>
                </div>
              </div>
              <div className="notificationSvBtn">
                <div className="offerCardBtn">
                  <SaveAndCancelButtons
                    setToggle={setNotificationToggle}
                    disabled={isReadonly}
                    clearData={NotificationClear}
                    route="/notification"
                  />
                </div>
              </div>
            </div>
            <div className={`${classes.sdlNotification} notificationForm`}>
              <h2>Scheduled Notifications</h2>
              <div className="scdlNtfBlock">
                <ul>
                  {notificationList.map(
                    notification =>
                      (notification.link.type === 'news' && (
                        <li
                          key={notification.id}
                          onClick={() => {
                            setNotificationId(notification.id);
                            setTitle(notification.title);
                            setDescription(notification.description);
                            setSelectedDate(notification.scheduledDate);
                            setBuildings(notification.buildings);
                            setUserJobTitle(notification.jobTitle);
                            setUserStatus(notification?.userStatus);
                            setNotificationLink('News');
                            setSelectedTenants(notification.tenants);
                            setIsReadonly(true);
                          }}
                          aria-hidden="true"
                        >
                          <div className="notificationCardBox">
                            <div className="offerLogoBlock">
                              <img
                                src={
                                  notification.data.mediaType === 'video' ||
                                  notification.data.mediaType === 'featured'
                                    ? notification.data.videoPreviewImage[2]
                                        .imageUrl
                                    : notification.data.newsImage[2].imageUrl
                                }
                                alt="offerLogo"
                              />
                            </div>
                            <div className="offerDetails">
                              <div className="publishDateMain">
                                <div className="publishDate">
                                  <h3>{notification.data.title}</h3>
                                  <span>Expires: </span>{' '}
                                  {moment(notification.data.endDate).format(
                                    'DD.MM.YYYY',
                                  )}
                                </div>
                                <div className="publishType">
                                  {notification.data.category.name}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="sdlNtfDetails">
                            <div className="sdlNtfDetailsGreyBox">
                              <div className="sdlNtfDetailsHead">
                                <div className="sdlNtfLogo">
                                  <img src={dlLogo} alt="dlLogo" />
                                  <span>DERWENT LONDON</span>
                                </div>
                                <div className="sdlNtfDate">
                                  {moment
                                    .tz(
                                      notification.scheduledDate,
                                      'Europe/London',
                                    )
                                    .format('DD MMMM YYYY HH:mm')}
                                </div>
                              </div>
                              <h3>{notification.title}</h3>
                              <p>{notification.description}</p>
                            </div>
                            <ol>
                              <li>
                                {notification.buildings.length > 1
                                  ? `${notification.buildings.length} Buildings selected`
                                  : `${notification.buildings.length} Building selected`}
                              </li>
                              <li>
                                {notification.jobTitle.length > 1
                                  ? `${notification.jobTitle.length} Functions selected`
                                  : `${notification.jobTitle.length} Function selected`}
                              </li>
                              <li>
                                {notification.tenants.length > 1
                                  ? `${notification.tenants.length} Tenants selected`
                                  : `${notification.tenants.length} Tenant selected`}
                              </li>
                            </ol>
                            <div className="cancelSdlNtf">
                              <Button
                                onClick={() => {
                                  setNotificationId(notification.id);
                                  setDeleteNotification(true);
                                }}
                              >
                                Cancel Notification
                              </Button>
                            </div>
                          </div>
                        </li>
                      )) ||
                      (notification.link.type === 'announcement' && (
                        <li
                          key={notification.id}
                          onClick={() => {
                            setNotificationId(notification.id);
                            setTitle(notification.title);
                            setDescription(notification.description);
                            setSelectedDate(notification.scheduledDate);
                            setBuildings(notification.buildings);
                            setUserJobTitle(notification.jobTitle);
                            setUserStatus(notification?.userStatus);
                            setSelectedTenants(notification.tenants);
                            setNotificationLink('News');
                            setIsReadonly(true);
                          }}
                          aria-hidden="true"
                        >
                          <div className="notificationCardBox">
                            <div className="offerLogoBlock">
                              <img
                                src={
                                  notification.data.mediaType === 'video' ||
                                  notification.data.mediaType === 'featured'
                                    ? notification.data.videoPreviewImage[2]
                                        .imageUrl
                                    : notification.data.newsImage[2].imageUrl
                                }
                                alt="offerLogo"
                              />
                            </div>
                            <div className="offerDetails">
                              <div className="publishDateMain">
                                <div className="publishDate">
                                  <h3>{notification.data.title}</h3>
                                  <span>Expires: </span>{' '}
                                  {moment(notification.data.endDate).format(
                                    'DD.MM.YYYY',
                                  )}
                                </div>
                                <div className="publishType">
                                  {notification.data.category.name}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="sdlNtfDetails">
                            <div className="sdlNtfDetailsGreyBox">
                              <div className="sdlNtfDetailsHead">
                                <div className="sdlNtfLogo">
                                  <img src={dlLogo} alt="dlLogo" />
                                  <span>DERWENT LONDON</span>
                                </div>
                                <div className="sdlNtfDate">
                                  {moment
                                    .tz(
                                      notification.scheduledDate,
                                      'Europe/London',
                                    )
                                    .format('DD MMMM YYYY HH:mm')}
                                </div>
                              </div>
                              <h3>{notification.title}</h3>
                              <p>{notification.description}</p>
                            </div>
                            <ol>
                              <li>
                                {notification.buildings.length > 1
                                  ? `${notification.buildings.length} Buildings selected`
                                  : `${notification.buildings.length} Building selected`}
                              </li>
                              <li>
                                {notification.jobTitle.length > 1
                                  ? `${notification.jobTitle.length} Functions selected`
                                  : `${notification.jobTitle.length} Function selected`}
                              </li>
                              <li>
                                {notification.tenants.length > 1
                                  ? `${notification.tenants.length} Tenants selected`
                                  : `${notification.tenants.length} Tenant selected`}
                              </li>
                            </ol>
                            <div className="cancelSdlNtf">
                              <Button
                                onClick={() => {
                                  setNotificationId(notification.id);
                                  setDeleteNotification(true);
                                }}
                              >
                                Cancel Notification
                              </Button>
                            </div>
                          </div>
                        </li>
                      )) ||
                      (notification.link.type === 'offer' && (
                        <li
                          key={notification.id}
                          onClick={() => {
                            setNotificationId(notification.id);
                            setTitle(notification.title);
                            setDescription(notification.description);
                            setSelectedDate(notification.scheduledDate);
                            setBuildings(notification.buildings);
                            setUserJobTitle(notification.jobTitle);
                            setUserStatus(notification?.userStatus);
                            setSelectedTenants(notification.tenants);
                            setNotificationLink('Offers');
                            setIsReadonly(true);
                          }}
                          aria-hidden="true"
                        >
                          <div className="notificationCardBox">
                            <div className="offerLogoBlock">
                              <img
                                src={notification.data.offerImage[2].imageUrl}
                                alt="offerLogo"
                              />
                            </div>
                            <div className="offerDetails">
                              <div className="publishDateMain">
                                <div className="publishDate">
                                  <h3>{notification.data.offerName}</h3>
                                  <span>Expires: </span>{' '}
                                  {moment(notification.data.endDate).format(
                                    'DD.MM.YYYY',
                                  )}
                                </div>
                                <div className="publishType">
                                  {notification.data.offerCategory}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="sdlNtfDetails">
                            <div className="sdlNtfDetailsGreyBox">
                              <div className="sdlNtfDetailsHead">
                                <div className="sdlNtfLogo">
                                  <img src={dlLogo} alt="dlLogo" />
                                  <span>DERWENT LONDON</span>
                                </div>
                                <div className="sdlNtfDate">
                                  {moment
                                    .tz(
                                      notification.scheduledDate,
                                      'Europe/London',
                                    )
                                    .format('DD MMMM YYYY HH:mm')}
                                </div>
                              </div>
                              <h3>{notification.title}</h3>
                              <p>{notification.description}</p>
                            </div>
                            <ol>
                              <li>
                                {notification.buildings.length > 1
                                  ? `${notification.buildings.length} Buildings selected`
                                  : `${notification.buildings.length} Building selected`}
                              </li>
                              <li>
                                {notification.jobTitle.length > 1
                                  ? `${notification.jobTitle.length} Functions selected`
                                  : `${notification.jobTitle.length} Function selected`}
                              </li>
                              <li>
                                {notification.tenants.length > 1
                                  ? `${notification.tenants.length} Tenants selected`
                                  : `${notification.tenants.length} Tenant selected`}
                              </li>
                            </ol>
                            <div className="cancelSdlNtf">
                              <Button
                                onClick={() => {
                                  setNotificationId(notification.link.id);
                                  setDeleteNotification(true);
                                }}
                              >
                                Cancel Notification
                              </Button>
                            </div>
                          </div>
                        </li>
                      )) ||
                      (notification.link.type === 'event' && (
                        <li
                          key={notification.id}
                          onClick={() => {
                            setNotificationId(notification.id);
                            setTitle(notification.title);
                            setDescription(notification.description);
                            setSelectedDate(notification.scheduledDate);
                            setBuildings(notification.buildings);
                            setUserJobTitle(notification.jobTitle);
                            setUserStatus(notification?.userStatus);
                            setSelectedTenants(notification.tenants);
                            setNotificationLink('Events');
                            setIsReadonly(true);
                          }}
                          aria-hidden="true"
                        >
                          <div className="notificationCardBox">
                            <div className="offerLogoBlock">
                              <img
                                src={notification.data.image[2].imageUrl}
                                alt="offerLogo"
                              />
                            </div>
                            <div className="offerDetails">
                              <div className="publishDateMain">
                                <div className="publishDate">
                                  <h3>{notification.data.name}</h3>
                                  <span>Expires: </span>{' '}
                                  {moment(notification.data.eventDate).format(
                                    'DD.MM.YYYY',
                                  )}
                                </div>
                                {/* <div className="publishType">

                                </div> */}
                              </div>
                            </div>
                          </div>
                          <div className="sdlNtfDetails">
                            <div className="sdlNtfDetailsGreyBox">
                              <div className="sdlNtfDetailsHead">
                                <div className="sdlNtfLogo">
                                  <img src={dlLogo} alt="dlLogo" />
                                  <span>DERWENT LONDON</span>
                                </div>
                                <div className="sdlNtfDate">
                                  {moment
                                    .tz(
                                      notification.scheduledDate,
                                      'Europe/London',
                                    )
                                    .format('DD MMMM YYYY HH:mm')}
                                </div>
                              </div>
                              <h3>{notification.title}</h3>
                              <p>{notification.description}</p>
                            </div>
                            <ol>
                              <li>
                                {notification.buildings.length > 1
                                  ? `${notification.buildings.length} Buildings selected`
                                  : `${notification.buildings.length} Building selected`}
                              </li>
                              <li>
                                {notification.jobTitle.length > 1
                                  ? `${notification.jobTitle.length} Functions selected`
                                  : `${notification.jobTitle.length} Function selected`}
                              </li>
                              <li>
                                {notification.tenants.length > 1
                                  ? `${notification.tenants.length} Tenants selected`
                                  : `${notification.tenants.length} Tenant selected`}
                              </li>
                            </ol>
                            <div className="cancelSdlNtf">
                              <Button
                                onClick={() => {
                                  setNotificationId(notification.link.id);
                                  setDeleteNotification(true);
                                }}
                              >
                                Cancel Notification
                              </Button>
                            </div>
                          </div>
                        </li>
                      )) ||
                      (notification.link.type === 'meetingRoom' && (
                        <li
                          key={notification.id}
                          onClick={() => {
                            setNotificationId(notification.id);
                            setTitle(notification.title);
                            setDescription(notification.description);
                            setSelectedDate(notification.scheduledDate);
                            setBuildings(notification.buildings);
                            setUserJobTitle(notification.jobTitle);
                            setUserStatus(notification?.userStatus);
                            setSelectedTenants(notification.tenants);
                            setNotificationLink('MeetingRoom');
                            setIsReadonly(true);
                          }}
                          aria-hidden="true"
                        >
                          <div className="notificationCardBox">
                            <div className="offerLogoBlock">
                              <img
                                src={
                                  notification.data.images[0].image[2].imageUrl
                                }
                                alt="offerLogo"
                              />
                            </div>
                            <div className="offerDetails">
                              <div className="publishDateMain">
                                <div className="publishDate">
                                  <h3>{notification.data.name}</h3>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="sdlNtfDetails">
                            <div className="sdlNtfDetailsGreyBox">
                              <div className="sdlNtfDetailsHead">
                                <div className="sdlNtfLogo">
                                  <img src={dlLogo} alt="dlLogo" />
                                  <span>DERWENT LONDON</span>
                                </div>
                                <div className="sdlNtfDate">
                                  {moment
                                    .tz(
                                      notification.scheduledDate,
                                      'Europe/London',
                                    )
                                    .format('DD MMMM YYYY HH:mm')}
                                </div>
                              </div>
                              <h3>{notification.title}</h3>
                              <p>{notification.description}</p>
                            </div>
                            <ol>
                              <li>
                                {notification.buildings.length > 1
                                  ? `${notification.buildings.length} Buildings selected`
                                  : `${notification.buildings.length} Building selected`}
                              </li>
                              <li>
                                {notification.jobTitle.length > 1
                                  ? `${notification.jobTitle.length} Functions selected`
                                  : `${notification.jobTitle.length} Function selected`}
                              </li>
                              <li>
                                {notification.tenants.length > 1
                                  ? `${notification.tenants.length} Tenants selected`
                                  : `${notification.tenants.length} Tenant selected`}
                              </li>
                            </ol>
                            <div className="cancelSdlNtf">
                              <Button
                                onClick={() => {
                                  setNotificationId(notification.id);
                                  setDeleteNotification(true);
                                }}
                              >
                                Cancel Notification
                              </Button>
                            </div>
                          </div>
                        </li>
                      )) ||
                      (notification.link.type === 'space' && (
                        <li
                          key={notification.id}
                          onClick={() => {
                            setNotificationId(notification.id);
                            setTitle(notification.title);
                            setDescription(notification.description);
                            setSelectedDate(notification.scheduledDate);
                            setBuildings(notification.buildings);
                            setUserJobTitle(notification.jobTitle);
                            setUserStatus(notification?.userStatus);
                            setSelectedTenants(notification.tenants);
                            setNotificationLink('Spaces');
                            setIsReadonly(true);
                          }}
                          aria-hidden="true"
                        >
                          <div className="notificationCardBox">
                            <div className="offerLogoBlock">
                              <img
                                src={
                                  notification.data.images[0].image[2].imageUrl
                                }
                                alt="offerLogo"
                              />
                            </div>
                            <div className="offerDetails">
                              <div className="publishDateMain">
                                <div className="publishDate">
                                  <h3>{notification.data.unit}</h3>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="sdlNtfDetails">
                            <div className="sdlNtfDetailsGreyBox">
                              <div className="sdlNtfDetailsHead">
                                <div className="sdlNtfLogo">
                                  <img src={dlLogo} alt="dlLogo" />
                                  <span>DERWENT LONDON</span>
                                </div>
                                <div className="sdlNtfDate">
                                  {moment
                                    .tz(
                                      notification.scheduledDate,
                                      'Europe/London',
                                    )
                                    .format('DD MMMM YYYY HH:mm')}
                                </div>
                              </div>
                              <h3>{notification.title}</h3>
                              <p>{notification.description}</p>
                            </div>
                            <ol>
                              <li>
                                {notification.buildings.length > 1
                                  ? `${notification.buildings.length} Buildings selected`
                                  : `${notification.buildings.length} Building selected`}
                              </li>
                              <li>
                                {notification.jobTitle.length > 1
                                  ? `${notification.jobTitle.length} Functions selected`
                                  : `${notification.jobTitle.length} Function selected`}
                              </li>
                              <li>
                                {notification.tenants.length > 1
                                  ? `${notification.tenants.length} Tenants selected`
                                  : `${notification.tenants.length} Tenant selected`}
                              </li>
                            </ol>
                            <div className="cancelSdlNtf">
                              <Button
                                onClick={() => {
                                  setNotificationId(notification.id);
                                  setDeleteNotification(true);
                                }}
                              >
                                Cancel Notification
                              </Button>
                            </div>
                          </div>
                        </li>
                      )),
                  )}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Notification;
