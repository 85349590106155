/* eslint-disable import/no-cycle */
import React, { useContext, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import './mrbooking.css';
import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  Input,
  InputLabel,
  Radio,
  RadioGroup,
  TextField,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import dowmArw from '../../images/down-arw.svg';
import ApiClient from '../../helpers/ApiClient';
import apiEndPoints from '../../helpers/apiEndPoints';
import { customerDetails } from '../../utils/formValidate';
import { MrBookingContext } from '../../context/MRBookingContext';
import { logOut } from '../../utils/logOut';

const useStyles = makeStyles({
  BookingStepForm: {
    margin: '0 auto',
    padding: '40px 0',
    '& > ul': {
      display: 'flex',
      justifyContent: 'space-between',
      flexWrap: 'wrap',
      alignItems: 'flex-start',
      '& > li': {
        listStyle: 'none',
        width: '46%',
        marginBottom: '22px',
        '& > div': {
          '& > div': {
            '& > div': {
              '& > div': {
                boxSizing: 'border-box',
              },
            },
          },
        },
      },
    },
  },
  formControl: {
    width: '100%',
    '& InputLabel': {
      position: 'relative',
    },
  },
  formLabel: {
    fontSize: '16px',
    fontWeight: 'normal',
    color: '#838384',
    position: 'static',
    transform: 'none',
    fontFamily: ['Varta', 'sans-serif'].join(','),
  },
  formSelectBox: {
    '& svg': {
      display: 'none',
    },
    '&:before': {
      display: 'none',
    },
    '&:after': {
      display: 'none',
    },
    '& > div': {
      height: '45px',
      lineHeight: '45px',
      padding: '0 15px !important',
      fontSize: '16px',
      color: '#444444',
      borderRadius: '3px !important',
      border: 'solid 0.5px #979797',
      marginTop: '0',
      background: `transparent url(${dowmArw}) no-repeat right 15px center !important`,
      backgroundSize: '10px',
      appearance: 'none',
      fontFamily: ['Varta', 'sans-serif'].join(','),
    },
  },
  formSelectBoxNodown: {
    '& > div': {
      background: ' transparent !important',
    },
  },
  InputSection: {
    '& li': {
      position: 'relative',
      '& span': {
        display: 'block',
        fontSize: '16px',
        fontWeight: 'normal',
        color: '#838384',
        fontFamily: ['Varta', 'sans-serif'].join(','),
      },
      '& input': {
        height: '45px',
        lineHeight: '45px',
        padding: '0 15px !important',
        fontSize: '16px',
        color: '#444444',
        borderRadius: '3px',
        border: 'solid 0.5px #979797',
        marginTop: '16px',
        width: '100%',
        background: 'transparent',
        fontFamily: ['Varta', 'sans-serif'].join(','),
        boxSizing: 'border-box',
      },
      '& textarea': {
        minHeight: '140px',
        lineHeight: '22px',
        padding: '10px 15px !important',
        fontSize: '16px',
        color: '#444444',
        borderRadius: '3px',
        border: 'solid 0.5px #979797',
        marginTop: '10px',
        width: '100%',
        background: 'transparent',
        resize: 'none',
        fontFamily: ['Varta', 'sans-serif'].join(','),
        outline: 'none',
      },
      '& > div': {
        '& > div': {
          '&:before': {
            display: 'none',
          },
          '&:after': {
            display: 'none',
          },
        },
        width: '100%',
        '&:before': {
          display: 'none',
        },
        '&:after': {
          display: 'none',
        },
      },
    },
  },
  InputSectionReadonly: {
    '& > div': {
      '& > div': {
        '&:before': {
          display: 'none',
        },
        '&:after': {
          display: 'none',
        },
        '& input': {
          height: '45px',
          lineHeight: '45px',
          padding: '0 15px !important',
          fontSize: '16px',
          color: '#444444',
          borderRadius: '3px',
          border: 'solid 0.5px #979797',
          marginTop: '10px',
          appearance: 'none',
          fontFamily: ['Varta', 'sans-serif'].join(','),
        },
      },
    },
  },
  radioSection: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '12px',
    '& span': {
      fontFamily: ['Varta', 'sans-serif'].join(','),
      fontSize: '16px',
      lineHeight: '20px',
      color: '#838384',
      '&:hover': {
        backgroundColor: 'transparent !important',
      },
      '& svg': {
        fontSize: '16px',
        fill: '#979797',
        '&:last-child': {
          fill: '#4e4e4e',
        },
      },
    },
  },
  formButton: {
    marginTop: '40px',
    width: '100% !important',
    display: 'flex',
    justifyContent: 'flex-end',
    '& Button': {
      height: '47px',
      lineHeight: '47px',
      border: '1px solid #000',
      outline: 'none',
      background: '#000000',
      fontSize: '16px',
      fontWeight: 'bold',
      width: 'auto',
      padding: '0 45px',
      color: '#fff',
      marginLeft: '30px',
      cursor: 'pointer',
      fontFamily: ['Varta', 'sans-serif'].join(','),
      '&:hover': {
        background: '#000000',
      },
      '&:first-child': {
        background: 'transparent',
        color: '#444444',
        border: 'none',
      },
    },
  },
  lineformButton: {
    marginBottom: '0px !important',
    marginTop: '20px !important',
    width: '100% !important',
    '& hr': {
      margin: '24px auto',
    },
  },
  errorMsg: {
    fontSize: '13px',
    color: '#f90000 !important',
    marginTop: 4,
    fontFamily: ['Varta', 'sans-serif'].join(','),
  },
});

const CustomerDetails = () => {
  const classes = useStyles();
  const {
    customerType,
    setCustomerType,
    tenant,
    setTenant,
    firstName,
    setFirstName,
    lastName,
    setLastName,
    phoneNumber,
    setPhoneNumber,
    email,
    setEmail,
    customerDetailsToggle,
    setCustomerDetailsToggle,
    mrBookingId,
    setActiveStep,
    iscustomerDetailsExist,
    setIsCustomerDetailsExist,
    selectedTenantUser,
    setSelectedTenantUser,
  } = useContext(MrBookingContext);
  const [tenanList, setTenanList] = useState([]);
  const [userSelectedTenant, setUserSelectedTenant] = useState('');
  const [tenantsUsersList, setTenantsUsersList] = useState([]);
  const [tenantUserSearch, setTenantUserSearch] = useState('');
  const [errorMsgs, setErrorMsgs] = useState('');
  const guest = 'Guest';

  useEffect(() => {
    if (
      (userSelectedTenant.length > 0 && tenantUserSearch === '') ||
      (userSelectedTenant.length > 0 &&
        tenantUserSearch &&
        tenantUserSearch.length > 2)
    ) {
      const payload = {
        searchText: tenantUserSearch,
        tenantId: userSelectedTenant || undefined,
        sortOrder: 'asc',
        sortBy: 'firstName',
        status: ['Active'],
        offset: 0,
        limit: 100,
      };
      ApiClient.apiPost(apiEndPoints.getTenantsUsersList, payload)
        .then(data => {
          setTenantsUsersList(data && data.data && data.data.data);
        })
        .catch(error => {
          console.log(error, 'error');
        });
    }
  }, [userSelectedTenant, tenantUserSearch]);

  useEffect(() => {
    const tenantsPayload = {
      searchText: '',
      sortOrder: 'asc',
      sortBy: 'name',
      offset: 0,
      limit: 500,
    };
    ApiClient.apiPost(apiEndPoints.getTenants, tenantsPayload)
      .then(data => {
        setTenanList(data && data.data && data.data.data);
      })
      .catch(error => {
        console.log(error, 'error');
      });
  }, []);

  useEffect(() => {
    customerDetails
      .validate({
        customerType,
        firstName,
        lastName,
        phoneNumber,
        email,
        tenant: (customerType === 'Guest' && guest) || tenant,
      })
      .then(() => {
        if (
          customerDetailsToggle &&
          phoneNumber.length   &&
          !iscustomerDetailsExist
        ) {
          const payload = {
            type: customerType,
            firstName,
            lastName,
            phone: phoneNumber,
            email,
            tenantId: userSelectedTenant || undefined,
            tenantName: (customerType === 'Guest' && guest) || tenant,
            tenantUserId: selectedTenantUser || undefined,
          };
          ApiClient.apiPut(
            `${apiEndPoints.createMrBooking}/${mrBookingId}`,
            payload,
          )
            .then(() => {
              setIsCustomerDetailsExist(true);
              setActiveStep(prevActiveStep => prevActiveStep + 1);
              setCustomerDetailsToggle(false);
            })
            .catch(error => {
              alert(error);
              setCustomerDetailsToggle(false);
            });
        }
      })
      .catch(error => {
        console.log(error, 'error');
        if (customerDetailsToggle) {
          setErrorMsgs(error);
          setCustomerDetailsToggle(false);
        }
      });
  }, [
    customerType,
    firstName,
    lastName,
    phoneNumber,
    email,
    userSelectedTenant,
    tenant,
    guest,
    selectedTenantUser,
    customerDetailsToggle,
  ]);

  const handleClick = userId => {
    const userData =
      tenantsUsersList &&
      tenantsUsersList.filter(tenantsUsers => tenantsUsers.id === userId);
    setEmail(userData && userData[0].email);
    localStorage.setItem('payToAccount', userData && userData[0].payToAccount);
  };

  const errorType = errorMsgs && errorMsgs.type;
  const errorFieldName = errorMsgs && errorMsgs.path;
  const errorFieldValue = errorMsgs && errorMsgs.value;

  const handleClear = () => {
    setTenant('');
    setFirstName('');
    setLastName('');
    setEmail('');
    setPhoneNumber('');
    setUserSelectedTenant('');
    setSelectedTenantUser('');
  };

  if (!(localStorage && localStorage.getItem('adal.session.state'))) {
    logOut();
  }

  return (
    <div className={classes.BookingStepForm}>
      <ul className={`${classes.InputSection} BookingStepFormTop`}>
        <li>
          <FormControl component="fieldset">
            <span>Customer type*</span>
            <RadioGroup aria-label="Active" name="Available">
              <div className={`${classes.radioSection} radioSection`}>
                <FormControlLabel
                  control={<Radio />}
                  label="Tenant"
                  checked={customerType === 'Tenant'}
                  onChange={() => {
                    setCustomerType('Tenant');
                    handleClear();
                  }}
                />
                <FormControlLabel
                  control={<Radio />}
                  label="Guest"
                  checked={customerType === 'Guest'}
                  onChange={() => {
                    setCustomerType('Guest');
                    handleClear();
                  }}
                />
              </div>
            </RadioGroup>
          </FormControl>
        </li>
      </ul>
      <ul>
        <li>
          <FormControl className={classes.formControl}>
            <InputLabel shrink value="Adobe" className={classes.formLabel}>
              {customerType === 'Tenant' ? 'Tenant*' : 'First Name*'}
            </InputLabel>
            {customerType === 'Tenant' ? (
              <Autocomplete
                id="combo-box-demo1"
                className="comboBoxSerch"
                options={tenanList}
                getOptionLabel={option => option.name}
                style={{ width: '100%' }}
                renderInput={params => (
                  <>
                    {!userSelectedTenant ? (
                      <TextField
                        {...params}
                        type="text"
                        value={userSelectedTenant}
                      />
                    ) : (
                      <TextField
                        {...params}
                        type="text"
                        value={userSelectedTenant}
                        inputProps={{
                          ...params.inputProps,
                          value: tenant,
                        }}
                      />
                    )}
                  </>
                )}
                onChange={(event, newValue) => {
                  const selectedValue =
                    newValue && newValue.id !== null ? newValue.id : '';
                  if (selectedValue && selectedValue.length > 0) {
                    setUserSelectedTenant(selectedValue);
                    setTenant('');
                    setFirstName('');
                    setLastName('');
                    setEmail('');
                    setTenant(newValue && newValue.name);
                  } else {
                    setUserSelectedTenant('');
                    setTenantUserSearch('');
                    setTenantsUsersList([]);
                    setTenant('');
                    setFirstName('');
                    setLastName('');
                    setEmail('');
                  }
                }}
              />
            ) : (
              <ul className={`${classes.InputSection} `}>
                <li>
                  <Input
                    type="text"
                    value={firstName}
                    onChange={e => {
                      setFirstName(e.target.value);
                    }}
                  />
                </li>
              </ul>
            )}
            {((errorType === 'required' && errorFieldName === 'tenant') ||
              (errorType === 'required' && errorFieldName === 'firstName') ||
              (errorFieldValue && errorFieldValue.firstName === '') ||
              (errorFieldValue && errorFieldValue.tenant === '')) && (
              <FormHelperText
                className={classes.errorMsg}
                component="div"
                error={
                  customerType === 'Tenant'
                    ? tenant === '' && 'Tenant is required.'
                    : firstName === '' && 'FirstName is required.'
                }
              >
                {customerType === 'Tenant'
                  ? tenant === '' && 'Tenant is required.'
                  : firstName === '' && 'FirstName is required.'}
              </FormHelperText>
            )}
          </FormControl>
        </li>
        <li>
          <FormControl className={classes.formControl}>
            <InputLabel shrink value="MIke Jones" className={classes.formLabel}>
              {customerType === 'Tenant' ? 'Tenant user*' : 'Last Name*'}
            </InputLabel>
            {customerType === 'Tenant' ? (
              <Autocomplete
                id="combo-box-demo2"
                className="comboBoxSerch"
                options={tenantsUsersList}
                onInputChange={event => {
                  setTenantUserSearch(event.target.value);
                }}
                getOptionLabel={option =>
                  `${option.firstName} ${option.lastName}`
                }
                style={{ width: '100%' }}
                renderInput={params => (
                  <>
                    {!selectedTenantUser ? (
                      <TextField
                        {...params}
                        type="text"
                        value={selectedTenantUser}
                      />
                    ) : (
                      <TextField
                        {...params}
                        type="text"
                        value={selectedTenantUser}
                        inputProps={{
                          ...params.inputProps,
                          value: `${firstName} ${lastName}`,
                        }}
                      />
                    )}
                  </>
                )}
                onChange={(event, newValue) => {
                  const selectedData =
                    newValue && newValue.id !== null ? newValue.id : '';
                  if (selectedData && selectedData.length > 0) {
                    handleClick(selectedData);
                    setSelectedTenantUser(selectedData);
                    setFirstName(newValue && newValue.firstName);
                    setLastName(newValue && newValue.lastName);
                  } else {
                    setSelectedTenantUser('');
                    setFirstName('');
                    setLastName('');
                    setEmail('');
                  }
                }}
              />
            ) : (
              <ul className={`${classes.InputSection} `}>
                <li>
                  <Input
                    type="text"
                    value={lastName}
                    onChange={e => {
                      setLastName(e.target.value);
                    }}
                  />
                </li>
              </ul>
            )}
            {((errorType === 'required' && errorFieldName === 'lastName') ||
              (errorFieldValue && errorFieldValue.lastName === '')) && (
              <FormHelperText
                className={classes.errorMsg}
                component="div"
                error={
                  customerType === 'Tenant'
                    ? lastName === '' && 'Tenant user is required.'
                    : lastName === '' && 'Last name is required.'
                }
              >
                {customerType === 'Tenant'
                  ? lastName === '' && 'Tenant user is required.'
                  : lastName === '' && 'Last name is required.'}
              </FormHelperText>
            )}
          </FormControl>
        </li>
      </ul>
      <ul className={`${classes.InputSection} `}>
        <li>
          <span>Phone Number* </span>
          <Input
            type="text"
            value={phoneNumber}
            onChange={e => {
             const re= /^[0-9+]+$/;
              if (e.target.value === '' || re.test(e.target.value)) {
                setPhoneNumber(e.target.value);
              }
            }}
          />
          

          {((errorType === 'required' && errorFieldName === 'phone') ||
            (errorFieldValue && errorFieldValue.phoneNumber === '')) && (
            <FormHelperText
              className={classes.errorMsg}
              component="div"
              error={phoneNumber.length === 0 && 'Phone number is required'}
            >
              {phoneNumber.length === 0 && 'Phone number is required'}
            </FormHelperText>
          )}
        </li>
        <li>
          <span>Email Address*</span>
          <Input
            type="text"
            value={email}
            onChange={e => {
              if (customerType !== 'Tenant') {
                setEmail(e.target.value);
              }
            }}
          />
          {((errorType === 'required' && errorFieldName === 'email') ||
            (errorFieldValue && errorFieldValue.email === '')) && (
            <FormHelperText
              className={classes.errorMsg}
              component="div"
              error={email === '' && 'Email address is required.'}
            >
              {email === '' && 'Email address is required.'}
            </FormHelperText>
          )}
        </li>
      </ul>
    </div>
  );
};
export default CustomerDetails;
