/* eslint-disable import/no-cycle */
import React, { useContext, useState, useEffect, useRef } from 'react';
import { Divider, CircularProgress, Button, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import ApiClient from '../../helpers/ApiClient';
import apiEndPoints from '../../helpers/apiEndPoints';
import dowmArw from '../../images/down-arw.svg';
// import accountBoxImg3 from '../../images/accountBoxImg3.svg';
import editIcon from '../../images/edit-icon.svg';
import NoResultsFound from '../../components/NoResultsFound/NoResultsFound';
import { ManageTenentContext } from '../../context/ManageTenentUsersContext';
import ManageTenentFilter from './ManageTenentsFilter';
import { GlobalContext } from '../../context/GlobalContext';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import './ManageTenants.css';
import SnackBar from '../../components/SnackBar/SnackBar';

const columns = [
  {
    id: 'tenantName',
    SortOrder: 'desc',
    SortBy: 'name',
    label: 'TENANTS',
    minWidth: 220,
    width: 220,
  },
  {
    id: 'category',
    SortOrder: 'desc',
    SortBy: 'categoryName',
    label: 'DOMAIN',
    minWidth: 240,
    width: 240,
  },
  {
    id: 'homeBuilding',
    SortOrder: 'desc',
    SortBy: 'categoryName',
    label: 'BUILDING',
    minWidth: 240,
    width: 240,
  },
  {
    id: 'tenancy',
    SortOrder: 'desc',
    SortBy: 'termEnd',
    label: 'LATEST TENANCY END DATE',
    align: 'left',
    format: value => value.toLocaleString('en-US'),
  },
  {
    id: 'payToAccount',
    SortOrder: 'desc',
    SortBy: 'payToAccount',
    label: 'PAY TO ACCOUNT',
    align: 'left',
    format: value => value.toLocaleString('en-US'),
  },
  {
    id: 'maxUsers',
    SortOrder: 'desc',
    SortBy: 'maxUsers',
    label: 'MAX USERS',
    align: 'left',
    format: value => value.toLocaleString('en-US'),
  },
  {
    id: 'edit',
    SortOrder: '',
    SortBy: '',
    label: '',
    minWidth: 50,
    maxWidth: 50,
    format: value => value.toFixed(2),
  },
];

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 'calc(100vh - 240px)',
  },
  tableHeaderCell: {
    background: '#f8f8f8',
    border: 'none',
    padding: '10px 15px',
    color: '#444444',
    fontSize: '13px',
    fontWeight: 700,
    fontFamily: ['Varta', 'sans-serif'].join(','),
    cursor: 'pointer',
    textTransform: 'uppercase',
    '&:after': {
      position: 'absolute',
      content: '""',
      background: `url(${dowmArw}) no-repeat 95% center !important`,
      right: '10px',
      top: '50%',
      transform: 'translateY(-50%)',
      height: '10px',
      width: '10px',
    },
    '&:before': {
      position: 'absolute',
      content: '""',
      background: '#dfdfdf',
      right: '0',
      top: '50%',
      transform: 'translateY(-50%)',
      width: '1px',
      height: '40%',
    },
    '&:last-child': {
      '&:before': {
        display: 'none',
      },
      '&:after': {
        display: 'none',
      },
    },
    '&:nth-child(2)': {
      cursor: 'default',
      pointerevents: 'none',
      '&:after': {
        display: 'none',
      },
    },
    '&:nth-child(3)': {
      cursor: 'default',
      pointerevents: 'none',
      '&:after': {
        display: 'none',
      },
    },
  },
  active: {
    '&:after': {
      transform: 'translateY(-50%) rotate(180deg)',
    },
  },
  editIcon: {
    margin: '0 auto',
    cursor: 'pointer',
    position: 'relative',
    top: '5px',
  },
  accountBoxImg: {
    display: 'flex',
    justifyContent: 'center',
    '& img': {
      margin: '0 10px',
    },
  },
  tableBody: {
    verticalAlign: 'middle',
    '& th': {
      fontSize: '14px',
      color: '#838384',
      fontWeight: 'normal',
      fontFamily: ['Varta', 'sans-serif'].join(','),
      textTransform: 'capitalize',
      padding: '8px 16px',
      cursor: 'default !important',
      verticalAlign: 'middle',
    },
    '& td': {
      fontSize: '14px',
      color: '#838384',
      fontWeight: 'normal',
      fontFamily: ['Varta', 'sans-serif'].join(','),
      textTransform: 'capitalize',
      padding: '8px 16px',
      cursor: 'default !important',
    },
  },
  customerEmail: {
    fontWeight: 'normal',
    textTransform: 'lowercase',
    marginTop: '4px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  customerVillg: {
    fontWeight: 'normal',
    textTransform: 'capitalize',
    marginBottom: '4px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },

  addIcon: {
    position: 'fixed',
    zIndex: '999',
    right: '55px',
    bottom: '55px',
  },
  fab: {
    background: '#000',
    color: '#fff',
    '&:hover': {
      background: '#000',
      color: '#fff',
    },
  },
  pageListMain: {
    position: 'relative',
  },
  moreList: {
    '& h4': {
      fontSize: '11px',
      fontWeight: 'bold',
      color: '#838384',
      fontFamily: ['Varta', 'sans-serif'].join(','),
    },
    '& li': {
      '& label': {
        '& span': {
          '&:last-child': {
            fontSize: '14px',
            color: '#444444',
            fontFamily: ['Varta', 'sans-serif'].join(','),
            lineHeight: '19px',
            position: 'relative',
            top: '2px',
          },
        },
      },
    },
  },
  moreListHeadtwo: {
    marginTop: '25px',
  },
  moreListHead: {
    paddingLeft: '10px',
    '& li': {
      padding: 0,
      '& label': {
        '& span': {
          '&:last-child': {
            fontSize: '12px',
            color: '#444444',
            fontFamily: ['Varta', 'sans-serif'].join(','),
            lineHeight: '19px',
            position: 'relative',
            top: '2px',
            fontWeight: 'bold',
          },
        },
      },
    },
  },
  filtercheckTop: {
    paddingLeft: '10px',
    '& li': {
      padding: 0,
      '& label': {
        '& span': {
          '&:last-child': {
            fontSize: '12px',
            color: '#444444',
            fontFamily: ['Varta', 'sans-serif'].join(','),
            lineHeight: '19px',
            position: 'relative',
            top: '2px',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            boxSizing: 'border-box',
            width: '72%',
          },
        },
      },
    },
  },
  totalCount: {
    marginTop: '10px',
    padding: '5px 0',
    '& ul': {
      display: 'flex',
      alignItems: 'çenter',
      justifyContent: 'space-between',
      '& li': {
        color: '#838384',
        fontSize: '14px',
        fontFamily: ['Varta', 'sans-serif'].join(','),
      },
    },
  },
  userAddTime: {
    fontWeight: 'normal',
  },
  customerNameNew: {
    display: 'inline-block',
    verticalAlign: 'middle',
    marginLeft: '4px',
    '& img': {
      width: '30px',
    },
  },
  accountBoxImgColumn: {
    verticalAlign: 'middle',
  },
  platformIcons: {
    display: 'flex',
    width: 70,
    margin: 'auto',
  },
  InputSection: {
    '& li': {
      '& input': {
        height: '35px',
        lineHeight: '35px',
        padding: '0 15px !important',
        fontSize: '16px',
        color: '#444444',
        borderRadius: '3px',
        border: 'solid 0.5px #979797',
        marginTop: '0',
        width: '100%',
        background: 'transparent',
        fontFamily: ['Varta', 'sans-serif'].join(','),
      },
      '& > div': {
        width: '100%',
        '&:before': {
          display: 'none',
        },
        '&:after': {
          display: 'none',
        },
        '& > div': {
          '& > div': {
            '&:before': {
              display: 'none',
            },
            '&:after': {
              display: 'none',
            },
          },
        },
      },
    },
  },
});

const ManageTenants = () => {
  const {
    manageTenantsUsersList,
    setManageTenantsUsersList,
    setSortOrder,
    sortOrder,
    setSortBy,
    sortBy,
    loading,
    setLoading,
    payAccountOptions,
    setSelectedStatus,
    setPayAccountSelectede,
    setCustomerSearch,
  } = useContext(ManageTenentContext);
  const { setSuccessMessage, rolePermission } = useContext(GlobalContext);
  const classes = useStyles();
  const [editChecked, setEditChecked] = useState(false);
  const [selected] = useState(false);
  const [attributesToggle, setAttributesToggle] = useState(false);
  const [selectedId, setSelecetedId] = useState('');
  const [active, setActive] = useState(false);
  const tanantInputValueRef = useRef(null);
  const maxUsersRef = useRef(null);
  const handleClick = (columnId, sortName) => {
    setSortOrder(sortOrder === columnId ? 'asc' : 'desc');
    setSortBy(sortBy === sortName ? sortBy : sortName);
  };

  const breadCrumbs = {
    listBreadCrumbPath: '/managetenants',
    listBreadCrumbTitle: 'Tenants',
  };

  const getTenantsManageList = tenantsPayload => {
    // get tenant users api call.
    ApiClient.apiPost(apiEndPoints.getTenants, tenantsPayload)
      .then(data => {
        setManageTenantsUsersList(data && data.data && data.data.data);
        setLoading(false);
      })
      .catch(error => {
        console.log(error, 'error');
      });
  };
  useEffect(() => {
    if (attributesToggle) {
      const tanantInputValue=tanantInputValueRef?.current?.value
      const maxUsers=maxUsersRef?.current?.value
      let payload = {
        payToAccount:
          tanantInputValue !== '' ? !selected : selected,
        gpDebtorId:
          tanantInputValue !== ''
            ? tanantInputValue
            : undefined,
      };
      if (maxUsers) payload = { ...payload, maxUsers: Number(maxUsers) };
      ApiClient.apiPut(`${apiEndPoints.editTenent}/${selectedId}`, payload)
        .then(data => {
          setCustomerSearch('');
          const updateMsg =
            data && data.data.message
              ? `Pay To Account has been updated successfully!`
              : '';
          setSuccessMessage(updateMsg);
          setAttributesToggle(false);
          setSelecetedId('');
          setLoading(false);

          // this is for filter and updating list after saving it
          const payToAccountUpdate = [];
          payAccountOptions.filter(account => {
            if (account.selected) {
              payToAccountUpdate.push(account.key);
            }
            return account.selected !== false;
          });
          setSelectedStatus(payToAccountUpdate);
          setPayAccountSelectede(payToAccountUpdate);
          getTenantsManageList({
            searchText: undefined,
            sortOrder: 'asc',
            sortBy: 'name',
            payToAccount:
              payToAccountUpdate && payToAccountUpdate.length === 2
                ? undefined
                : payToAccountUpdate && payToAccountUpdate[0],
            offset: 0,
            limit: 40,
          });
        })
        .catch(error => {
          alert(error?.response?.data?.errors[0].message);
          setAttributesToggle(false);
          setLoading(false);
        });
    }
  }, [attributesToggle]);

  const buttonRef = useRef({
    focusHandler: () => {
      setEditChecked(false);
    },
  });

  const handleRowClick = () => {
    setEditChecked(false);
  };

  return (
    <div className={classes.pageListMain}>
      <SnackBar />
      <Breadcrumb breadCrumbs={breadCrumbs} />
      <Divider light />
      <ManageTenentFilter ref={buttonRef} />
      <Divider light />
      <div className="pageContainer tableContainer offerListTable">
        <Paper className={classes.root}>
          <TableContainer className={classes.container}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map(column => (
                    <TableCell
                      className={`${classes.tableHeaderCell} noSort ${
                        active && column.SortBy === sortBy ? 'active' : ''
                      }`}
                      align={column.align}
                      id={column.label}
                      key={column.id}
                      disabled={
                        column.SortBy === 'categoryName' && column.SortBy === ''
                      }
                      onClick={() => {
                        if (
                          (column.SortBy !== 'categoryName' &&
                            column.SortBy !== '') ||
                          column.SortBy === sortBy
                        ) {
                          handleClick(column.SortOrder, column.SortBy);
                          setActive(!active);
                        }
                      }}
                      style={{ minWidth: column.minWidth }}
                    >
                      <p>{column.label}</p>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              {loading ? (
                <CircularProgress color="inherit" />
              ) : (
                <TableBody>
                  {manageTenantsUsersList?.length ? (
                    manageTenantsUsersList.map(data => (
                      <TableRow className={classes.tableBody} key={data.id}>
                        <TableCell
                          component="th"
                          scope="row"
                          align="left"
                          valign="middle"
                          style={{
                            maxWidth: 220,
                            width: 220,
                          }}
                          onClick={handleRowClick}
                        >
                          <Tooltip title={data.name}>
                            <div className="rowEllips">{data.name}</div>
                          </Tooltip>
                        </TableCell>
                        <TableCell
                          style={{ maxWidth: 240, width: 240 }}
                          onClick={handleRowClick}
                        >
                          <Tooltip title={data.domains.join(',  ')}>
                            <div className="rowEllips">
                              {data.domains.join(',  ')}
                            </div>
                          </Tooltip>
                        </TableCell>
                        <TableCell
                          style={{ maxWidth: 240, width: 240 }}
                          onClick={handleRowClick}
                        >
                          {data.buildings.map(building =>
                            building.blueBoxReference === data.homeBuilding ? (
                              <Tooltip title={building.buildingName}>
                                <div className="rowEllips">
                                  {building.buildingName}
                                </div>
                              </Tooltip>
                            ) : null,
                          )}
                        </TableCell>
                        <TableCell
                          className="toLowercase"
                          align="left"
                          style={{ maxWidth: 180, width: 180 }}
                          onClick={handleRowClick}
                        >
                          <div className="twoRowEllips textCapitalize">
                            {moment(data.termEnd).format('DD MMM YYYY')
                              ? moment(data.termEnd).format('DD MMM YYYY')
                              : '__'}
                          </div>
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{
                            maxWidth: 150,
                            width: 150,
                            verticalAlign: 'middle',
                          }}
                        >
                          {editChecked && data.id === selectedId ? (
                            <div
                              className="ptaCkeckbox"
                              // onChange={e =>
                              //   setTanantInputValue(e.target.value)
                              // }
                              value={data.gpDebtorId}
                            >
                              <ul className={`${classes.InputSection}`}>
                                <li>
                                  <input
                                    ref={tanantInputValueRef}
                                    defaultValue={data.gpDebtorId}
                                  />
                                </li>
                              </ul>
                            </div>
                          ) : (
                            data.payToAccount && (
                              <div>
                                <span>{data.gpDebtorId}</span>
                              </div>
                            )
                          )}
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{
                            maxWidth: 150,
                            width: 150,
                            verticalAlign: 'middle',
                          }}
                        >
                          {editChecked && data.id === selectedId ? (
                            <div
                              className="ptaCkeckbox"
                              value={data?.maxUsers}
                            >
                              <ul className={`${classes.InputSection}`}>
                                <li>
                                  <input
                                    type="number"
                                    className="no-spinner" 
                                    onChange={e => {
                                      const { value } = e.target;
                                      const intValue = parseInt(value, 10);
                                      if (intValue >= 0 || value === '') {
                                        maxUsersRef.current.value =
                                          Number(value);
                                      }
                                    }}
                                    defaultValue={data?.maxUsers}
                                    ref={maxUsersRef}
                                  />
                                </li>
                              </ul>
                            </div>
                          ) : (
                            <div>
                              <span> {data.maxUsers>=0 ? data.maxUsers : 'No Limit'}</span>
                            </div>
                          )}
                        </TableCell>
                        <TableCell
                          className="toLowercase"
                          align="right"
                          style={{ maxWidth: 150, width: 50 }}
                        >
                          {editChecked && data.id === selectedId ? (
                            <div className="formButton">
                              <Button
                                onClick={() => {
                                  setAttributesToggle(true);
                                  setSelecetedId(data.id);
                                }}
                              >
                                Save
                              </Button>
                            </div>
                          ) : (
                            rolePermission &&
                            rolePermission.editTenant && (
                              <img
                                src={editIcon}
                                className={classes.editIcon}
                                alt="editIcon"
                                role="presentation"
                                onClick={() => {
                                  setSelecetedId(data.id);
                                  setEditChecked(true);
                                }}
                              />
                            )
                          )}
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <NoResultsFound />
                  )}
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </Paper>
      </div>
    </div>
  );
};
export default ManageTenants;
